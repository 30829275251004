import React from 'react';
import { makeStyles, createStyles, styled } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useScrollTrigger,
  useTheme,
} from '@material-ui/core';
import AppNavigationBar from 'components/common/AppNavigationBar';
import sectionBg from 'assets/hero-top-image_02.jpg';
import { scrollToElement, triggerOpt } from 'common/helpers';

const HeroBlock = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${sectionBg})`,
  padding: theme.spacing(10, 0),
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  height: `${window.innerHeight - theme.spacing(10) + 40}px`,
  position: 'relative',
}));

const CTA = styled(Button)({
  background: '#f15236',
  color: '#fff',
  fontWeight: 600,
  fontSize: 18,
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
  padding: '12px 20px',
  borderRadius: 0,
  position: 'relative',
  marginLeft: 20,
  marginRight: 20,
  verticalAlign: 'top',
  minWidth: 180,
  '&:hover': {
    background: '#ff0f0f',
    '&::before': { background: '#ff0f0f' },
    '&::after': { background: '#ff0f0f' },
  },
  '&::before': {
    width: 40,
    content: '""',
    display: 'block',
    background: '#f15236',
    position: 'absolute',
    left: '-20px',
    top: '0',
    bottom: '0',
    right: 'auto',
    borderRadius: '50%',
    transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  '&::after': {
    width: 40,
    content: '""',
    display: 'block',
    background: '#f15236',
    position: 'absolute',
    right: '-20px',
    top: '0',
    bottom: '0',
    left: 'auto',
    borderRadius: '50%',
    transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
});

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      flexGrow: 1,
      margin: '0 auto',
    },
    grid: {
      padding: '80px 48px 24px',
      [theme.breakpoints.down('xs')]: {
        padding: 24,
      },
    },
    title: {
      color: '#fff',
      textTransform: 'uppercase',
      fontWeight: 600,
      marginBottom: 48,
      maxWidth: '500px',
      fontSize: 44,
      lineHeight: '60px',
      [theme.breakpoints.down('xs')]: {
        fontSize: 40,
        lineHeight: '50px',
      },
    },
    subtitle: {
      color: '#fff',
      display: 'inline-block',
      fontSize: 24,
      lineHeight: '30px',
      maxWidth: 550,
      [theme.breakpoints.down('xs')]: {
        fontSize: 20,
      },
    },
    box: {
      marginTop: 48,
      '& > .MuiButton-root:first-child': {
        marginBottom: 24,
        marginRight: 48,
      },
      [theme.breakpoints.up('sm')]: {
        marginTop: 80,
        display: 'flex',
        alignItems: 'flex-start',
      },
    },
  })
);

const HeroSection: React.FC = () => {
  const classes = useStyles();
  const theme: any = useTheme();
  const trigger = useScrollTrigger(triggerOpt);
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <HeroBlock component="div">
      <AppNavigationBar
        style={{
          position: trigger ? 'fixed' : 'absolute',
          top: trigger ? (smDown ? theme.AppBarHeightStickyMobile : theme.AppBarHeightSticky) : '',
        }}
      />

      <Grid container className={classes.root}>
        <Grid item xs={12} className={classes.grid}>
          <Typography variant="h2" component="h1" gutterBottom className={classes.title}>
            Work from anywhere
          </Typography>

          <Typography
            variant="subtitle1"
            component="span"
            gutterBottom
            className={classes.subtitle}>
            {`Collaborate, Communicate, Operate at anytime, anywhere, on any device with Velocity's fully integrated cloud workspace.`}
          </Typography>

          <Box className={classes.box}>
            <CTA
              variant="contained"
              disableElevation
              onClick={() => scrollToElement('VelocityRequestQuote')}>
              Request a Demo
            </CTA>
            <CTA variant="contained" disableElevation href="https://store.velocity-eu.com/">
              Velocity Store
            </CTA>
          </Box>
        </Grid>
      </Grid>
    </HeroBlock>
  );
};

export default HeroSection;
