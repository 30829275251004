import React from 'react';
import { makeStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';

const HeroBlock = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 0),
}));

const Highlight = styled('a')({
  color: '#f15236',
  fontWeight: 600,
  wordBreak: 'break-word',
  textDecoration: 'none',
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: theme.breakpoints.values['lg'],
      margin: '0 auto',
      padding: theme.spacing(0, 3),
      color: '#423f3f',
    },
    gridLine: {
      borderBottom: '3px solid #4bb1fe',
      paddingBottom: theme.spacing(1),
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
    label: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: 'normal',
      color: '#737373',
    },
    title: {
      fontSize: 28,
      fontWeight: 700,
      marginBottom: 16,
    },
    text: {
      width: '100%',
      fontSize: 18,
      marginBottom: 16,
      display: 'inline-block',
    },
    list: {
      padding: '0 0 16px',
    },
    listItem: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    listItemIcon: {
      minWidth: 32,
    },
    listItemText: {
      margin: 0,
    },
  })
);

const ResourcesBlock5: React.FC = () => {
  const classes = useStyles();

  return (
    <HeroBlock component="div">
      <Grid container className={classes.root}>
        <Grid item xs={12} className={classes.gridLine}>
          <Typography variant="h4" component="h2" className={classes.title}>
            Connect with the macOS client
          </Typography>

          <Typography variant="subtitle1" component="p" className={classes.text}>
            To get started,{' '}
            <Highlight href="https://apps.apple.com/app/microsoft-remote-desktop/id1295203466">
              download
            </Highlight>{' '}
            and install the client on your macOS device
          </Typography>

          <Typography variant="subtitle1" component="p" className={classes.text}>
            {`Subscribe to the feed your admin gave you to get the list of managed resources available to you on your macOS device.`}
          </Typography>

          <Typography variant="subtitle1" component="p" className={classes.text}>
            <ol>
              <li>
                Select <strong>Add Workspace</strong> on the main page to connect to the service and
                retrieve your resources.
              </li>
              <li>
                Enter the feed URL into the <strong>Feed URL</strong> field. The feed URL can be
                either a URL or an email address.
                <ul>
                  <li>
                    {`If you use a URL, use the one your admin gave you, normally `}
                    <Highlight href="https://rdweb.wvd.microsoft.com/api/arm/feeddiscovery">
                      https://rdweb.wvd.microsoft.com/api/arm/feeddiscovery
                    </Highlight>
                  </li>
                  <li>
                    To use email, enter your email address. This tells the client to search for a
                    URL associated with your email address if your admin configured the server that
                    way.
                  </li>
                  <li>
                    {`To connect through the US Gov portal use `}
                    <Highlight href="https://rdweb.wvd.azure.us/api/arm/feeddiscovery">
                      https://rdweb.wvd.azure.us/api/arm/feeddiscovery
                    </Highlight>
                  </li>
                </ul>
              </li>
              <li>
                Select <strong>Add</strong>.
              </li>
              <li>Sign in with your user account when prompted.</li>
            </ol>
          </Typography>

          <Typography variant="subtitle1" component="p" className={classes.text}>
            {`After you've signed in, you should see a list of available resources.`}
            <br />
            {`Once you've subscribed to a feed, the feed's content will update automatically on a regular basis. Resources may be added, changed, or removed based on changes made by your administrator.`}
          </Typography>
        </Grid>
      </Grid>
    </HeroBlock>
  );
};

export default ResourcesBlock5;
