import React from 'react';
import { makeStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';
import sectionImg1 from 'assets/velocity-use-case-img3.png';
import sectionImg2 from 'assets/cloud-desktop/cloud-desktop-premium-block1.png';

const HeroBlock = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4, 0, 8),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2, 0, 4),
  },
}));

const Highlight = styled('span')({
  color: '#f15236',
  fontWeight: 600,
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: theme.breakpoints.values['lg'],
      margin: '0 auto',
      padding: theme.spacing(0, 3),
      color: '#423f3f',
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
    label: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: 'normal',
      color: '#737373',
    },
    title: {
      fontSize: 34,
      fontWeight: 700,
    },
    text: {
      fontSize: 18,
      marginBottom: 16,
      display: 'inline-block',
    },
    imageGrid: {
      backgroundImage: `url(${sectionImg1}), url(${sectionImg2})`,
      backgroundSize: '85%, 85%',
      backgroundPosition: 'center top, left bottom',
      backgroundRepeat: 'no-repeat',

      [theme.breakpoints.down('sm')]: {
        backgroundSize: 'cover',
        marginBottom: 24,
        minHeight: 220,
      },
    },
    textGrid: {
      paddingLeft: 24,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
      },
    },
    list: {
      padding: '16px 0',
    },
    listItem: {
      paddingTop: 4,
      paddingBottom: 4,
    },
    listItemIcon: {
      minWidth: 32,
    },
    listItemText: {
      margin: 0,
      fontWeight: 600,
    },
  })
);

const UseCaseEngineeringBlock2: React.FC = () => {
  const classes = useStyles();

  return (
    <HeroBlock component="div">
      <Grid container className={classes.root}>
        <Grid item xs={12} md={6} className={classes.imageGrid}></Grid>
        <Grid item xs={12} md={6} className={classes.textGrid}>
          <Typography variant="subtitle1" component="span" className={classes.text}>
            <Highlight>Secure and uncompromised remote access</Highlight>
            <br />
            {`Protect valuable assets, intellectual property, design schematics – all your data. Velocity Cloud Desktop Premium technology encrypts and transmits across a standard IP network to WVD clients. This means information never leaves the data centre or public cloud – eliminating the risk of proprietary information stored on local devices.`}
          </Typography>

          <Typography variant="subtitle1" component="span" className={classes.text}>
            <Highlight>Simplified IT management</Highlight>
            <br />
            {`Manage and control workstations from the data centre through a single management interface – minimizing time and resources spent on OS, software updates and patches. Scale your deployment up or down only when needed, at a rapid pace across regions.`}
          </Typography>

          <Typography variant="subtitle1" component="span" className={classes.text}>
            <Highlight>Seamless collaboration and business continuity</Highlight>
            <br />
            {`Whether working at an office, an off-site meeting, or from home, Velocity Cloud Desktop Premium solutions enable secure access to applications and data anywhere because they remain in the data centre or cloud of choice. Cross-functional teams, contractors, vendor-partners around the world can easily share and collaborate on files under any network condition – enabling tight coordination of activities in all phases of a product life cycle.`}
          </Typography>

          <Typography variant="subtitle1" component="span" className={classes.text}>
            <Highlight>Unrivalled performance and user experience</Highlight>
            <br />
            {`Access mission-critical and graphics-intensive 3D applications from anywhere with high-performance interactivity. Velocity Cloud Desktop Premium supports multi-monitor 4K and advanced design applications for the best in lossless text, colour accuracy, and interactivity.`}
          </Typography>
        </Grid>
      </Grid>
    </HeroBlock>
  );
};

export default UseCaseEngineeringBlock2;
