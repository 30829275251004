import React from 'react';
import { makeStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';
import sectionImg from 'assets/UseCaseEngineeringBlock1.png';

const HeroBlock = styled(Box)({
  background: '#fff',
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: theme.breakpoints.values['lg'],
      margin: '0 auto',
      color: '#423f3f',
      padding: theme.spacing(8, 3, 2),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(4, 3, 2),
      },
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
    label: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: 'normal',
      color: '#737373',
    },
    title: {
      fontSize: 34,
      fontWeight: 700,
    },
    text: {
      fontSize: 18,
      display: 'inline-block',
      marginBottom: 16,
    },
    imgGrid: {
      backgroundImage: `url(${sectionImg})`,
      backgroundSize: 'contain',
      backgroundPosition: 'top',
      backgroundRepeat: 'no-repeat',
      backgroundColor: '#fff',
      order: 3,
      [theme.breakpoints.down('sm')]: {
        backgroundSize: 'cover',
        marginBottom: 24,
        minHeight: 220,
        order: 2,
      },
    },
    textGrid: {
      paddingRight: 24,
      order: 2,
      [theme.breakpoints.down('sm')]: {
        paddingRight: 0,
        order: 3,
      },
    },
  })
);

const UseCaseEngineeringBlock1: React.FC = () => {
  const classes = useStyles();

  return (
    <HeroBlock component="div" id="VelocitySolutions">
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Typography variant="h5" component="span" className={classes.label} gutterBottom>
            Velocity Use Case
          </Typography>
          <br />

          <Typography variant="h4" component="h2" className={classes.title} gutterBottom>
            Velocity Cloud Desktop Premium Technology Accelerates Design and Innovation
          </Typography>
          <br />
        </Grid>

        <Grid item xs={12} md={6} className={classes.textGrid}>
          <Typography variant="body1" component="span" className={classes.text}>
            {`Manufacturing and design organizations are constantly challenged by the high demands of consumers and businesses alike. Whether it be demands for best-in-breed innovations or product quality, meeting these needs require solutions that help manage product complexity, improve cost control, collaboration and communication.`}
          </Typography>
          <Typography variant="body1" component="span" className={classes.text}>
            {`Today, Azure WVD solutions are relied on by engineers, designers, project managers and more for ultra-secure access to remote workstations and graphics-intensive 3D applications. Workflows and intellectual property remain secured in a data centre or public cloud of choice and are made accessible from anywhere.`}
          </Typography>
        </Grid>

        <Grid item xs={12} md={6} className={classes.imgGrid}></Grid>
      </Grid>
    </HeroBlock>
  );
};

export default UseCaseEngineeringBlock1;
