import React from 'react';
import AppTopBar from 'components/common/AppTopBar';
import DeploymentScenariosBlock1 from 'components/DeploymentScenarios/DeploymentScenariosBlock1';
import DeploymentScenariosBlock2 from 'components/DeploymentScenarios/DeploymentScenariosBlock2';
import Footer from 'components/common/Footer';
import CopyRight from 'components/common/CopyRight';
import AppNavigationBar from 'components/common/AppNavigationBar';

const DeploymentScenarios: React.FC = () => (
  <React.Fragment>
    <AppTopBar elevation={0} style={{ zIndex: 1201 }} />
    <AppNavigationBar />
    <DeploymentScenariosBlock1 />
    <DeploymentScenariosBlock2 />
    <Footer />
    <CopyRight />
  </React.Fragment>
);

export default DeploymentScenarios;
