import React, { useState } from 'react';
import { makeStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  FormControl,
  FilledInput,
  InputAdornment,
  FormHelperText,
} from '@material-ui/core';
import { PermIdentity, Mail, PhoneInTalk, Language } from '@material-ui/icons';
import sectionImg from 'assets/signup2.png';

const HeroBlock = styled(Box)({
  background: '#fafafa',
});

const Submit = styled(Button)(({ theme }) => ({
  margin: theme.spacing(3, 0, 3),
  width: 'auto',
  marginLeft: '20px',
  background: '#f15236',
  color: '#fff',
  fontWeight: 600,
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
  padding: '12px 20px',
  borderRadius: 0,
  position: 'relative',
  marginRight: '20px',
  '&:hover': {
    background: '#ff0f0f',
    '&::before': { background: '#ff0f0f' },
    '&::after': { background: '#ff0f0f' },
  },
  '&::before': {
    width: 40,
    content: '""',
    display: 'block',
    background: '#f15236',
    position: 'absolute',
    left: '-20px',
    top: '0',
    bottom: '0',
    right: 'auto',
    borderRadius: '50%',
    transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  '&::after': {
    width: 40,
    content: '""',
    display: 'block',
    background: '#f15236',
    position: 'absolute',
    right: '-20px',
    top: '0',
    bottom: '0',
    left: 'auto',
    borderRadius: '50%',
    transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: theme.breakpoints.values['lg'],
      margin: `0 auto ${theme.spacing(3)}px`,
      padding: theme.spacing(5, 2, 2),
      '& .MuiInput-underline:before': {
        borderColor: '#bbb',
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column-reverse',
      },
    },
    margin: {
      margin: theme.spacing(0, 0, 1),
      background: '#fff',
      '& .MuiInputLabel-formControl': {
        color: '#bbb',
      },
      '& .MuiInputBase-input': {
        padding: 0,
      },
      '& .MuiFilledInput-underline:before': {
        display: 'none',
      },
    },
  })
);

const GetStartedToday: React.FC = () => {
  const classes = useStyles();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState(false);
  const errorMsg = 'Enter a value for this field.';
  const formAction =
    'https://forms.zohopublic.eu/velocityeuinc/form/ContactForm/formperma/qEJUZsAlJCreHdok-sR3FWBMC5bzUgKmQP7mTUy5kWM/htmlRecords/submit';
  const redirectUrl = `${window.location.origin}/thank-you`;

  return (
    <HeroBlock component="div" id="VelocityRequestQuote">
      <Grid container className={classes.root} alignItems="center">
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            padding: '16px 16px 40px',
            textAlign: 'center',
          }}>
          <Typography
            variant="h4"
            component="h4"
            gutterBottom
            style={{ fontWeight: 800, color: '#423f3f', marginBottom: 32 }}>
            Get started today
          </Typography>

          <form
            action={formAction}
            name="form"
            id="form"
            method="POST"
            acceptCharset="UTF-8"
            encType="multipart/form-data">
            {/* To Track referrals , place the referrer name within the " " in the above hidden input field */}
            <TextField type="hidden" name="zf_referrer_name" value="" />
            {/* To redirect to a specific page after record submission , place the respective url within the " " in the above hidden input field */}
            <TextField type="hidden" name="zf_redirect_url" value={redirectUrl} />
            <TextField type="hidden" name="zc_gad" value="">
              {/* If GCLID is enabled in Zoho CRM Integration, click details of AdWords Ads will be pushed to Zoho CRM */}
            </TextField>
            <FormControl required fullWidth className={classes.margin}>
              <FilledInput
                placeholder="First Name *"
                id="contactUs-FirstName"
                name="Name_First"
                style={{
                  padding: '16px 12px',
                  background: '#fff',
                  border: '1px solid #bbb',
                  borderRadius: 4,
                }}
                startAdornment={
                  <InputAdornment style={{ color: '#bbb' }} position="start">
                    <PermIdentity />
                  </InputAdornment>
                }
              />
              {error && <FormHelperText>{errorMsg}</FormHelperText>}
            </FormControl>
            <FormControl required fullWidth className={classes.margin}>
              <FilledInput
                placeholder="Last Name *"
                id="contactUs-LastName"
                name="Name_Last"
                style={{
                  padding: '16px 12px',
                  background: '#fff',
                  border: '1px solid #bbb',
                  borderRadius: 4,
                }}
                startAdornment={
                  <InputAdornment style={{ color: '#bbb' }} position="start">
                    <PermIdentity />
                  </InputAdornment>
                }
              />
              {error && <FormHelperText>{errorMsg}</FormHelperText>}
            </FormControl>
            <FormControl required fullWidth className={classes.margin}>
              <FilledInput
                placeholder="Company Name *"
                id="contactUs-CompanyName"
                name="SingleLine"
                style={{
                  padding: '16px 12px',
                  background: '#fff',
                  border: '1px solid #bbb',
                  borderRadius: 4,
                }}
                startAdornment={
                  <InputAdornment style={{ color: '#bbb' }} position="start">
                    <Language />
                  </InputAdornment>
                }
              />
              {error && <FormHelperText>{errorMsg}</FormHelperText>}
            </FormControl>
            <FormControl required fullWidth className={classes.margin}>
              <FilledInput
                placeholder="Email *"
                id="contactUs-Email"
                name="Email"
                style={{
                  padding: '16px 12px',
                  background: '#fff',
                  border: '1px solid #bbb',
                  borderRadius: 4,
                }}
                startAdornment={
                  <InputAdornment style={{ color: '#bbb' }} position="start">
                    <Mail />
                  </InputAdornment>
                }
              />
              {error && <FormHelperText>{errorMsg}</FormHelperText>}
            </FormControl>
            <FormControl required fullWidth className={classes.margin}>
              <FilledInput
                placeholder="Telephone"
                id="contactUs-Telephone"
                name="PhoneNumber_countrycode"
                style={{
                  padding: '16px 12px',
                  background: '#fff',
                  border: '1px solid #bbb',
                  borderRadius: 4,
                }}
                startAdornment={
                  <InputAdornment style={{ color: '#bbb' }} position="start">
                    <PhoneInTalk />
                  </InputAdornment>
                }
              />
              {error && <FormHelperText>{errorMsg}</FormHelperText>}
            </FormControl>
            <Submit
              fullWidth
              variant="contained"
              disableElevation
              type="submit"
              style={{ textTransform: 'uppercase' }}>
              Find Out More
            </Submit>
          </form>
          <Typography
            variant="body2"
            component="p"
            gutterBottom
            style={{
              fontWeight: 600,
              color: '#423f3f',
              marginBottom: 8,
              marginTop: 48,
              textAlign: 'left',
            }}>
            Required fields *
          </Typography>
          <Typography
            variant="body2"
            component="p"
            gutterBottom
            style={{
              fontWeight: 600,
              color: '#423f3f',
              marginBottom: 8,
              textAlign: 'left',
            }}>
            By clicking the button above, you consent to receiving calls and emails from Velocity-eu
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <img src={sectionImg} alt="" style={{ width: '100%' }} />
        </Grid>
      </Grid>
    </HeroBlock>
  );
};

export default GetStartedToday;
