import React from 'react';
import AppTopBar from 'components/common/AppTopBar';
import UseCaseFinancialBlock1 from 'components/UseCase/UseCaseFinancialBlock1';
import UseCaseFinancialBlock2 from 'components/UseCase/UseCaseFinancialBlock2';
import UseCaseFinancialBlock3 from 'components/UseCase/UseCaseFinancialBlock3';
import Footer from 'components/common/Footer';
import CopyRight from 'components/common/CopyRight';
import AppNavigationBar from 'components/common/AppNavigationBar';

const UseCaseFinancial: React.FC = () => (
  <React.Fragment>
    <AppTopBar elevation={0} style={{ zIndex: 1201 }} />
    <AppNavigationBar />
    <UseCaseFinancialBlock1 />
    <UseCaseFinancialBlock2 />
    <UseCaseFinancialBlock3 />
    <Footer />
    <CopyRight />
  </React.Fragment>
);

export default UseCaseFinancial;
