import React from 'react';
import AppTopBar from 'components/common/AppTopBar';
import AppNavigationBar from 'components/common/AppNavigationBar';
import ThankYou from 'components/ThankYou';
import Footer from 'components/common/Footer';
import CopyRight from 'components/common/CopyRight';

const ThankYouPage: React.FC = () => (
  <React.Fragment>
    <AppTopBar elevation={0} style={{ zIndex: 1201 }} />
    <AppNavigationBar />
    <ThankYou />
    <Footer />
    <CopyRight />
  </React.Fragment>
);

export default ThankYouPage;
