import React from 'react';
import { makeStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';

const HeroBlock = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 0, 8),
}));

const Highlight = styled('a')({
  color: '#f15236',
  fontWeight: 600,
  wordBreak: 'break-word',
  textDecoration: 'none',
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: theme.breakpoints.values['lg'],
      margin: '0 auto',
      padding: theme.spacing(0, 3),
      color: '#423f3f',
    },
    gridLine: {
      borderBottom: '3px solid #4bb1fe',
      paddingBottom: theme.spacing(1),
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
    label: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: 'normal',
      color: '#737373',
    },
    title: {
      fontSize: 28,
      fontWeight: 700,
      marginBottom: 16,
    },
    text: {
      width: '100%',
      fontSize: 18,
      marginBottom: 16,
      display: 'inline-block',
    },
    list: {
      padding: '0 0 16px',
    },
    listItem: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    listItemIcon: {
      minWidth: 32,
    },
    listItemText: {
      margin: 0,
    },
  })
);

const AboutUsBlock3: React.FC = () => {
  const classes = useStyles();

  return (
    <HeroBlock component="div">
      <Grid container className={classes.root}>
        <Grid item xs={12} className={classes.gridLine}>
          <Typography variant="subtitle1" component="p" className={classes.text}>
            {`For 20 Years now we’ve been developing Remote Desktop solutions and Unified Communications. We’ve deployed solutions to hundreds of enterprise customers and thousands of consumers, however nothing prepared us for what happened next. Explosion in demand from businesses of any size to access their business-critical systems.`}
          </Typography>
          <Typography variant="subtitle1" component="p" className={classes.text}>
            {`Velocity Cloud Desktop was born on first of April 2020 after one of our founders was recovering of COVID-19 and directed Velocity’s team to offer our product first to all Education customers to enable our kids to continue their education; and to our Health Care clients to enable them to quickly access critical patience information.`}
          </Typography>
          <Typography variant="subtitle1" component="p" className={classes.text}>
            {`Velocity Cloud Desktop service is a unique combination of Microsoft WVD, Comprehensive Communication platform, Hardware Devices and passionate technical team with unique experience and expertise.`}
          </Typography>
          <Typography variant="subtitle1" component="p" className={classes.text}>
            If you want to join us for the next exiting chapter dop us an email at:
            <Highlight href="mailto:hello@velocity-eu.com"> hello@velocity-eu.com</Highlight>
          </Typography>
          <Typography variant="subtitle1" component="p" className={classes.text}>
            Your Sincerely, <br />
            Velocity EU Team
          </Typography>
        </Grid>
      </Grid>
    </HeroBlock>
  );
};

export default AboutUsBlock3;
