import React from 'react';
import AppTopBar from 'components/common/AppTopBar';
import AppNavigationBar from 'components/common/AppNavigationBar';
import CloudPlansPricing from 'components/CloudPlansPricing';
import CloudFeatures from 'components/CloudFeatures';
import ManagedVirtualDesktop from 'components/ManagedVirtualDesktop';
import AzureInfrastructure from 'components/AzureInfrastructure';
import HelpDeskSupport from 'components/HelpDeskSupport';
import AdditionalServices from 'components/AdditionalServices';
import GetStartedToday from 'components/GetStartedToday';
import Footer from 'components/common/Footer';
import CopyRight from 'components/common/CopyRight';

const Pricing: React.FC = () => (
  <React.Fragment>
    <AppTopBar elevation={0} style={{ zIndex: 1201 }} />
    <AppNavigationBar />
    <CloudPlansPricing />
    <CloudFeatures />
    <ManagedVirtualDesktop />
    <AzureInfrastructure />
    <HelpDeskSupport />
    <AdditionalServices />
    <GetStartedToday />
    <Footer />
    <CopyRight />
  </React.Fragment>
);

export default Pricing;
