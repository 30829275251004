import React from 'react';
import { makeStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import { Box, Grid, Hidden, Typography } from '@material-ui/core';
import sectionImg1 from 'assets/resources-background.png';
import sectionImg2 from 'assets/resources-phone.png';

const HeroBlock = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8, 0),
  minHeight: '30vh',
  backgroundImage: `url(${sectionImg1})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  marginBottom: '12vh',
  display: 'flex',
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(4, 0),
    minHeight: '30vh',
    marginBottom: 0,
  },
}));

const SectionImg = styled('img')({
  height: '35vh',
  alignSelf: 'flex-end',
  position: 'absolute',
  bottom: '-11vh',
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: theme.breakpoints.values['lg'],
      margin: '0 auto',
      padding: theme.spacing(0, 3),
      color: '#fff',
    },
    textGrid: {
      display: 'flex',
      flexDirection: 'column',
    },
    label: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: 'normal',
      color: '#ddd',
    },
    title: {
      fontSize: 56,
      fontWeight: 700,
    },
  })
);

const ResourcesBlock1: React.FC = () => {
  const classes = useStyles();

  return (
    <HeroBlock component="div">
      <Grid container className={classes.root}>
        <Grid item xs={12} className={classes.textGrid}>
          <Typography variant="h5" component="span" gutterBottom className={classes.label}>
            Velocity
          </Typography>
          <Typography variant="h4" component="h2" className={classes.title}>
            Resources
          </Typography>
          <Hidden smDown>
            <SectionImg src={sectionImg2} alt="Velocity Section Image" />
          </Hidden>
        </Grid>
      </Grid>
    </HeroBlock>
  );
};

export default ResourcesBlock1;
