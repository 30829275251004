import React from 'react';
import AppTopBar from 'components/common/AppTopBar';
import AboutUsBlock1 from 'components/AboutUs/AboutUsBlock1';
import AboutUsBlock2 from 'components/AboutUs/AboutUsBlock2';
import AboutUsBlock3 from 'components/AboutUs/AboutUsBlock3';
import Footer from 'components/common/Footer';
import CopyRight from 'components/common/CopyRight';
import AppNavigationBar from 'components/common/AppNavigationBar';

const AboutUs: React.FC = () => (
  <React.Fragment>
    <AppTopBar elevation={0} style={{ zIndex: 1201 }} />
    <AppNavigationBar />
    <AboutUsBlock1 />
    <AboutUsBlock2 />
    <AboutUsBlock3 />
    <Footer />
    <CopyRight />
  </React.Fragment>
);

export default AboutUs;
