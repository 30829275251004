import React from 'react';
import { makeStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';

const HeroBlock = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 0),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: theme.breakpoints.values['lg'],
      margin: '16px auto',
      padding: theme.spacing(1, 2),
      fontSize: 18,
      flexDirection: 'column',
    },
    title: {
      fontWeight: 800,
      fontSize: 44,
      color: '#423f3f',
    },
    textBox: {
      color: '#423f3f',
    },
    textIcon: {
      width: 20,
      marginRight: 12,
    },
    list: {
      padding: '16px 0',
    },
    listItem: {
      paddingTop: 4,
      paddingBottom: 4,
    },
    listItemIcon: {
      minWidth: 32,
    },
    listItemText: {
      margin: 0,
    },
  })
);

const QualityPolicy: React.FC = () => {
  const classes = useStyles();

  return (
    <HeroBlock component="div">
      <Grid container className={classes.root}>
        <Typography gutterBottom variant="h2" component="h2">
          Quality Policy
        </Typography>
        <p>
          The purpose of the Quality Management System is to ensure that the services provided to
          its customers consistently meet or exceed their expectations. The company operates a
          system that regularly evaluates its processes and customer needs and has set quantifiable
          objectives with plans in place to ensure that they are reviewed year on year for
          improvement.
        </p>
        <p>
          It is the policy of Velocity EU Inc to maintain, on a continual basis, an effectively
          managed Quality Assurance program, which will assure customers that the services supplied
          conform to the laid down procedures or disciplines of the company, and which will ensure
          that the customer&rsquo;s needs and expectations are fully met. The management of the
          company is firmly committed to the systems, procedures and controls included in this
          manual, and the total participation of all personnel is mandatory.
        </p>
        <p>
          The CEO and Managing Director is entrusted with the authority and responsibility for the
          control of the Quality and to ensure that all legal and regulatory requirements are met
          such as Health &amp; Safety at work. Company management personnel cannot be over-ruled on
          matters of Quality, and in case of differences of opinion on Quality matters, have the
          responsibility to refer such items to the Managing Director or his representative for
          resolution.
        </p>
        <p>
          This policy of Quality Assurance is in place to ensure that the overall organisational
          objectives of the company are met. The objectives of this company are to ensure that the
          best possible security services are supplied to the company&rsquo;s valued clients, and
          that the company can meet customer needs and requirements as effectively and efficiently
          as possible.
        </p>
        <p>
          One organisational objective is to ensure that all requirements of the documented
          management system that meets the requirements of ISO 9001 are performed in a managed
          methodical way. It is also ensured that the system is fully understood and implemented
          correctly throughout the company. It is the intention of the CEO and the Managing Director
          that this policy along with all other policies will be reviewed on an annual basis at the
          management review meeting.
        </p>
      </Grid>
    </HeroBlock>
  );
};

export default QualityPolicy;
