import React from 'react';
import { makeStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';

const HeroBlock = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 0),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: theme.breakpoints.values['lg'],
      margin: '16px auto',
      padding: theme.spacing(1, 2),
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
    },
    title: {
      fontWeight: 500,
      fontSize: '6rem',
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(8),
    },
    subtitle: {
      fontWeight: 500,
    },
    text: {
      marginBottom: theme.spacing(10),
    },
  })
);

const NotFound: React.FC = () => {
  const classes = useStyles();

  return (
    <HeroBlock component="div">
      <Grid container className={classes.root}>
        <Typography gutterBottom variant="h2" component="h2" className={classes.title}>
          404
        </Typography>
        <Typography gutterBottom variant="h3" component="h5" className={classes.subtitle}>
          Oh, no!
        </Typography>
        <Typography gutterBottom variant="body1" component="p" className={classes.text}>
          There´s nothing here...
        </Typography>
      </Grid>
    </HeroBlock>
  );
};

export default NotFound;
