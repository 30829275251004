import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import {
  Home,
  ThankYouPage,
  Pricing,
  Product,
  TermsPage,
  PrivacyPolicyPage,
  QualityPolicyPage,
  EnvironmentalPolicyPage,
  HealthSafetyPolicyPage,
  NotFoundPage,
  UseCaseFinancial,
  UseCaseMedical,
  UseCaseEducation,
  UseCaseEngineering,
  EnterprisePBX,
  ContactUs,
  DeploymentScenarios,
  CloudDesktop,
  CloudDesktopPremium,
  AboutUs,
  ResourcesPage,
} from 'pages';

const App: React.FC = () => (
  <Router>
    {/* A <Switch> looks through its children <Route>s and
          renders the first one that matches the current URL. */}
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/thank-you" component={ThankYouPage} />
      <Route path="/pricing" component={Pricing} />
      <Route path="/product" component={Product} />
      <Route path="/terms" component={TermsPage} />
      <Route path="/privacy-policy" component={PrivacyPolicyPage} />
      <Route path="/quality-policy" component={QualityPolicyPage} />
      <Route path="/environmental-policy" component={EnvironmentalPolicyPage} />
      <Route path="/health-safety-policy" component={HealthSafetyPolicyPage} />
      <Route path="/use-case-financial" component={UseCaseFinancial} />
      <Route path="/use-case-medical" component={UseCaseMedical} />
      <Route path="/use-case-еducation" component={UseCaseEducation} />
      <Route path="/use-case-engineering" component={UseCaseEngineering} />
      <Route path="/enterprise-pbx" component={EnterprisePBX} />
      <Route path="/contact-us" component={ContactUs} />
      <Route path="/deployment-scenarios" component={DeploymentScenarios} />
      <Route path="/cloud-desktop" component={CloudDesktop} />
      <Route path="/cloud-desktop-premium" component={CloudDesktopPremium} />
      <Route path="/about-us" component={AboutUs} />
      <Route path="/resources" component={ResourcesPage} />
      <Route component={NotFoundPage} />
    </Switch>
  </Router>
);

export default App;
