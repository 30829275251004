import React from 'react';
import { makeStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import { Box, Grid, Typography, Button } from '@material-ui/core';
import sectionImg from 'assets/Velocity-Home_08.jpg';

const HeroBlock = styled(Box)({
  background: '#fff',
});

const FlexBox = styled('div')({
  display: 'flex',
});

const SectionActions = styled(FlexBox)(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

const CTA = styled(Button)({
  background: '#f15236',
  color: '#fff',
  fontWeight: 600,
  fontSize: 18,
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
  padding: '12px 20px',
  borderRadius: 0,
  position: 'relative',
  marginLeft: 20,
  '&:hover': {
    background: '#ff0f0f',
    '&::before': { background: '#ff0f0f' },
    '&::after': { background: '#ff0f0f' },
  },
  '&::before': {
    width: 40,
    content: '""',
    display: 'block',
    background: '#f15236',
    position: 'absolute',
    left: '-20px',
    top: '0',
    bottom: '0',
    right: 'auto',
    borderRadius: '50%',
    transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  '&::after': {
    width: 40,
    content: '""',
    display: 'block',
    background: '#f15236',
    position: 'absolute',
    right: '-20px',
    top: '0',
    bottom: '0',
    left: 'auto',
    borderRadius: '50%',
    transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: theme.breakpoints.values['lg'],
      margin: '0 auto',
      backgroundImage: `url(${sectionImg})`,
      backgroundSize: 'contain',
      backgroundPosition: 'right',
      backgroundRepeat: 'no-repeat',
      backgroundColor: '#fff',
      color: '#423f3f',
      padding: theme.spacing(8, 3),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(4, 3),
        backgroundImage: 'none',
      },
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
    label: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: 'normal',
      color: '#737373',
    },
    title: {
      fontSize: 34,
      fontWeight: 700,
    },
    text: {
      fontSize: 18,
    },
  })
);

const FutureSection: React.FC = () => {
  const classes = useStyles();

  return (
    <HeroBlock component="div" id="VelocitySolutions">
      <Grid container className={classes.root}>
        <Grid item xs={12} md={6}>
          <Typography variant="h5" component="span" className={classes.label} gutterBottom>
            Velocity
          </Typography>
          <br />

          <Typography variant="h4" component="h2" className={classes.title} gutterBottom>
            Cloud Desktop
          </Typography>
          <br />

          <Typography variant="subtitle1" component="span" gutterBottom className={classes.text}>
            {`Velocity Desktop as a Service (DaaS) platform enables enterprise teams to deploy and manage Windows 10 cloud desktops anywhere in the world, with exceptional performance and reliability.`}
            <br />
            <br />
          </Typography>

          <SectionActions>
            <CTA href="/cloud-desktop" variant="contained" disableElevation>
              Learn more
            </CTA>
          </SectionActions>
        </Grid>
      </Grid>
    </HeroBlock>
  );
};

export default FutureSection;
