import React from 'react';
import { makeStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';
import sectionImg from 'assets/use-case-block-1.png';

const HeroBlock = styled(Box)({
  background: '#fff',
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: theme.breakpoints.values['lg'],
      margin: '0 auto',
      color: '#423f3f',
      padding: theme.spacing(8, 3, 2),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(4, 3, 2),
      },
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
    label: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: 'normal',
      color: '#737373',
    },
    title: {
      fontSize: 34,
      fontWeight: 700,
    },
    text: {
      fontSize: 18,
      display: 'inline-block',
      marginBottom: 16,
    },
    imgGrid: {
      backgroundImage: `url(${sectionImg})`,
      backgroundSize: 'contain',
      backgroundPosition: 'top',
      backgroundRepeat: 'no-repeat',
      backgroundColor: '#fff',
      order: 3,
      [theme.breakpoints.down('sm')]: {
        backgroundSize: 'cover',
        marginBottom: 24,
        minHeight: 220,
        order: 2,
      },
    },
    textGrid: {
      paddingRight: 24,
      order: 2,
      [theme.breakpoints.down('sm')]: {
        paddingRight: 0,
        order: 3,
      },
    },
  })
);

const UseCaseEducationBlock1: React.FC = () => {
  const classes = useStyles();

  return (
    <HeroBlock component="div" id="VelocitySolutions">
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Typography variant="h5" component="span" className={classes.label} gutterBottom>
            Velocity Use Case
          </Typography>
          <br />

          <Typography variant="h4" component="h2" className={classes.title} gutterBottom>
            London UK, College of F.E.
          </Typography>
          <br />
        </Grid>

        <Grid item xs={12} md={6} className={classes.textGrid}>
          <Typography variant="body1" component="span" className={classes.text}>
            {`Like many educational organisations budget is always paramount. Added to the Covid-19 pandemic and the uncertainty of term time we were in a very difficult position. After crunching the numbers many times and with the majority of our 8-year-old IT equipment requiring replacement it was not possible to upgrade the network financially and in such a short time frame.`}
          </Typography>
          <Typography variant="body1" component="span" className={classes.text}>
            {`To enable us to deliver the education platform students and teachers require, we approached Velocity EU and after a small pilot we decided to move to the Velocity Cloud Desktop (VDI). This has allowed us to extend the life of our existing hardware, eliminate disruption, minimise investment in new hardware and avoid additional human resources.`}
          </Typography>
          <Typography variant="body1" component="span" className={classes.text}>
            {`The Velocity Cloud desktop is based upon the industry standard Windows 10 and is seamlessly connected to our Microsoft Azure platform using WVD.`}
          </Typography>
        </Grid>

        <Grid item xs={12} md={6} className={classes.imgGrid}></Grid>
      </Grid>
    </HeroBlock>
  );
};

export default UseCaseEducationBlock1;
