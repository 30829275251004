import React from 'react';
import AppTopBar from 'components/common/AppTopBar';
import AppNavigationBar from 'components/common/AppNavigationBar';
import PrivacyPolicy from 'components/PrivacyPolicy';
import Footer from 'components/common/Footer';
import CopyRight from 'components/common/CopyRight';

const PrivacyPolicyPage: React.FC = () => (
  <React.Fragment>
    <AppTopBar elevation={0} style={{ zIndex: 1201 }} />
    <AppNavigationBar />
    <PrivacyPolicy />
    <Footer />
    <CopyRight />
  </React.Fragment>
);

export default PrivacyPolicyPage;
