import React, { useState, MouseEvent } from 'react';
import { createStyles, makeStyles, styled } from '@material-ui/core/styles';
import {
  Box,
  AppBar,
  Toolbar,
  MenuItem,
  MenuList,
  Button,
  Hidden,
  useMediaQuery,
  useTheme,
  Menu,
} from '@material-ui/core';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import NavDrawer from '../NavDrawer';
import { triggerOpt, ColorOnScroll, scrollToElement } from 'common/helpers';
import logoTransparent from 'assets/Velocity-Logo-white-orange-transparent.png';
import buttonIcon from 'assets/my-desktop-icon.png';

const SectionImg = styled('img')({
  width: 'auto',
  height: '100%',
  maxHeight: '100%',
  maxWidth: '100%',
});

const CTA = styled(Button)(({ theme }) => ({
  background: '#f15236',
  color: '#fff',
  fontWeight: 600,
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
  minWidth: '140px',
  padding: '8px 0',
  borderRadius: 0,
  position: 'relative',
  marginRight: '20px',
  '&:hover': {
    background: '#ff0f0f',
    '&::before': { background: '#ff0f0f' },
    '&::after': { background: '#ff0f0f' },
  },
  '&::before': {
    width: 40,
    content: '""',
    display: 'block',
    background: '#f15236',
    position: 'absolute',
    left: '-20px',
    top: '0',
    bottom: '0',
    right: 'auto',
    borderRadius: '50%',
    transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    zIndex: -1,
  },
  '&::after': {
    width: 40,
    content: '""',
    display: 'block',
    background: '#f15236',
    position: 'absolute',
    right: '-20px',
    top: '0',
    bottom: '0',
    left: 'auto',
    borderRadius: '50%',
    transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    zIndex: -1,
  },
  [theme.breakpoints.down('sm')]: {
    minWidth: '0px',
    '& .MuiButton-endIcon': {
      margin: 0,
    },
  },
}));

const NavLink = styled(Button)({
  textTransform: 'capitalize',
  color: '#fff',
  fontWeight: 600,
  padding: 0,
  flexShrink: 0,
});

const LogoWrap = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: 56,
  paddingLeft: 16,
  paddingRight: 16,
  [theme.breakpoints.down('sm')]: {
    height: 50,
  },
  [theme.breakpoints.down('xs')]: {
    height: 38,
  },
  [theme.breakpoints.down(360)]: {
    height: 32,
  },
}));

const useStyles = makeStyles((props: any) => {
  const theme: any = useTheme();

  return createStyles({
    root: {
      width: '100%',
      position: 'relative',
      transition: 'all .15s linear',
      zIndex: 1100,
      height: theme.MainNavHeight,
      top: props.trigger ? theme.AppBarHeightSticky : 0,
      [theme.breakpoints.down('sm')]: {
        top: props.trigger ? theme.AppBarHeightStickyMobile : 0,
        height: theme.MainNavHeightMobile,
      },
    },
    appBar: {
      transition: 'all .15s linear',
      top: 'auto',
      height: theme.MainNavHeight,
      '&.MuiAppBar-colorTransparent .MuiList-root > a': {
        color: '#fff',
      },
      [theme.breakpoints.down('sm')]: {
        height: theme.MainNavHeightMobile,
      },
    },
    logoLink: {
      marginLeft: '-16px',
      [theme.breakpoints.down('sm')]: {
        marginRight: 'auto',
        marginLeft: 0,
      },
    },
    menuList: {
      display: 'flex',
      outline: 'none',
    },
    menuItem: {
      fontWeight: 600,
      fontSize: 15,
    },
    navLinkItem: {
      fontSize: 'inherit',
      color: 'inherit',
      fontWeight: 400,
      flexGrow: 1,
      justifyContent: 'flex-start',
      '&:hover': {
        background: 'none',
      },
    },
    toolbar: {
      justifyContent: 'space-between',
      transition: 'all .15s linear',
      padding: '0 24px',
      height: theme.MainNavHeight,
      [theme.breakpoints.down('sm')]: {
        padding: '0 5px',
        height: theme.MainNavHeightMobile,
      },
    },
  });
});

const AppNavigationBar: React.ElementType = props => {
  const classes = useStyles();
  const theme: any = useTheme();
  const trigger = useScrollTrigger(triggerOpt);
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const ixDown = useMediaQuery(theme.breakpoints.down(375));
  const menuContainer = document.getElementById('AppNavigationBar');

  const [solutionsMenu, setSolutionsMenu] = useState<null | HTMLElement>(null);
  const [storeMenu, setStoreMenu] = useState<null | HTMLElement>(null);
  const [casesMenu, setCasesMenu] = useState<null | HTMLElement>(null);
  const [accountMenu, setAccountMenu] = useState<null | HTMLElement>(null);

  const setClickSolutions = (e: MouseEvent<HTMLButtonElement>) => setSolutionsMenu(e.currentTarget);
  const setClickStore = (e: MouseEvent<HTMLButtonElement>) => setStoreMenu(e.currentTarget);
  const setClickCases = (e: MouseEvent<HTMLButtonElement>) => setCasesMenu(e.currentTarget);
  const setClickAccount = (e: MouseEvent<HTMLButtonElement>) => setAccountMenu(e.currentTarget);

  const setClose = () => {
    setSolutionsMenu(null);
    setStoreMenu(null);
    setCasesMenu(null);
    setAccountMenu(null);
  };

  const menuProps: any = {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
    getContentAnchorEl: null,
    keepMounted: true,
    onClose: setClose,
  };

  return (
    <Box
      component="div"
      className={classes.root}
      id="AppNavigationBar"
      style={{
        position: trigger ? 'fixed' : 'relative',
        top: trigger ? (smDown ? theme.AppBarHeightStickyMobile : theme.AppBarHeightSticky) : '',
      }}
      {...props}>
      <ColorOnScroll {...props}>
        <AppBar className={classes.appBar} {...props}>
          <Toolbar variant="regular" className={classes.toolbar}>
            <NavLink href="/#top" className={classes.logoLink}>
              <LogoWrap>
                <SectionImg src={logoTransparent} alt="Velocity Logo" />
              </LogoWrap>
            </NavLink>
            <Hidden mdDown>
              <MenuList variant="menu" className={classes.menuList}>
                {/* Solutions */}
                <NavLink onClick={setClickSolutions}>
                  <MenuItem className={classes.menuItem}>Solutions</MenuItem>
                </NavLink>
                <Menu
                  container={menuContainer}
                  anchorEl={solutionsMenu}
                  open={Boolean(solutionsMenu)}
                  {...menuProps}>
                  <MenuItem className={classes.menuItem} onClick={() => setClose()}>
                    <NavLink href="/cloud-desktop" className={classes.navLinkItem} disableRipple>
                      Velocity Cloud Desktop
                    </NavLink>
                  </MenuItem>
                  <MenuItem className={classes.menuItem} onClick={() => setClose()}>
                    <NavLink
                      href="/cloud-desktop-premium"
                      className={classes.navLinkItem}
                      disableRipple>
                      Velocity Cloud Desktop Premium
                    </NavLink>
                  </MenuItem>
                  <MenuItem className={classes.menuItem} onClick={() => setClose()}>
                    <NavLink href="/enterprise-pbx" className={classes.navLinkItem} disableRipple>
                      Velocity Enterprise PBX
                    </NavLink>
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => {
                      setClose(), scrollToElement('CloudDesktopPremium');
                    }}>
                    <NavLink className={classes.navLinkItem} disableRipple>
                      Velocity Cloud Connections
                    </NavLink>
                  </MenuItem>
                  <MenuItem className={classes.menuItem} onClick={() => setClose()}>
                    <NavLink
                      href="/deployment-scenarios"
                      className={classes.navLinkItem}
                      disableRipple>
                      Velocity Deployment Scenarios
                    </NavLink>
                  </MenuItem>
                </Menu>
                {/* Store */}
                <NavLink onClick={setClickStore}>
                  <MenuItem className={classes.menuItem}>Store</MenuItem>
                </NavLink>
                <Menu
                  container={menuContainer}
                  anchorEl={storeMenu}
                  open={Boolean(storeMenu)}
                  {...menuProps}>
                  <MenuItem className={classes.menuItem} onClick={setClose}>
                    <NavLink
                      className={classes.navLinkItem}
                      disableRipple
                      href="https://store.velocity-eu.com/">
                      Cloud Endpoint Hardware
                    </NavLink>
                  </MenuItem>
                  <MenuItem className={classes.menuItem} onClick={setClose}>
                    <NavLink
                      className={classes.navLinkItem}
                      disableRipple
                      href="https://store.velocity-eu.com/">
                      Network Devices
                    </NavLink>
                  </MenuItem>
                  <MenuItem className={classes.menuItem} onClick={setClose}>
                    <NavLink
                      className={classes.navLinkItem}
                      disableRipple
                      href="https://store.velocity-eu.com/">
                      PBX Accessories
                    </NavLink>
                  </MenuItem>
                </Menu>
                {/* Use Cases */}
                <NavLink onClick={setClickCases}>
                  <MenuItem className={classes.menuItem}>Use Cases</MenuItem>
                </NavLink>
                <Menu
                  container={menuContainer}
                  anchorEl={casesMenu}
                  open={Boolean(casesMenu)}
                  {...menuProps}>
                  <MenuItem className={classes.menuItem} onClick={setClose}>
                    <NavLink
                      href="/use-case-еducation"
                      className={classes.navLinkItem}
                      disableRipple>
                      Education
                    </NavLink>
                  </MenuItem>
                  <MenuItem className={classes.menuItem} onClick={setClose}>
                    <NavLink href="/use-case-medical" className={classes.navLinkItem} disableRipple>
                      Medical
                    </NavLink>
                  </MenuItem>
                  <MenuItem className={classes.menuItem} onClick={setClose}>
                    <NavLink
                      href="/use-case-engineering"
                      className={classes.navLinkItem}
                      disableRipple>
                      Engineering
                    </NavLink>
                  </MenuItem>
                  <MenuItem className={classes.menuItem} onClick={setClose}>
                    <NavLink
                      href="/use-case-financial"
                      className={classes.navLinkItem}
                      disableRipple>
                      Financial
                    </NavLink>
                  </MenuItem>
                </Menu>
                {/* Resources */}
                <NavLink href="/resources">
                  <MenuItem className={classes.menuItem}>Resources</MenuItem>
                </NavLink>
                {/* Get Help */}
                <NavLink href="https://support.velocity-eu.com/">
                  <MenuItem className={classes.menuItem}>Get Help</MenuItem>
                </NavLink>
                {/* My Account */}
                <NavLink onClick={setClickAccount}>
                  <MenuItem className={classes.menuItem}>My Account</MenuItem>
                </NavLink>
                <Menu
                  container={menuContainer}
                  anchorEl={accountMenu}
                  open={Boolean(accountMenu)}
                  {...menuProps}>
                  <MenuItem className={classes.menuItem} onClick={setClose}>
                    <NavLink
                      className={classes.navLinkItem}
                      href="https://books.velocity-eu.com/portal/velocityeu/login">
                      My Orders
                    </NavLink>
                  </MenuItem>
                  <MenuItem className={classes.menuItem} onClick={setClose}>
                    <NavLink
                      className={classes.navLinkItem}
                      href="https://store.velocity-eu.com/signin">
                      My Velocity Store
                    </NavLink>
                  </MenuItem>
                  <MenuItem className={classes.menuItem} onClick={setClose}>
                    <NavLink
                      className={classes.navLinkItem}
                      href="https://subscriptions.zoho.eu/portal/velocityeu">
                      My Subscriptions
                    </NavLink>
                  </MenuItem>
                  <MenuItem className={classes.menuItem} onClick={setClose}>
                    <NavLink
                      className={classes.navLinkItem}
                      href="https://support.velocity-eu.com/portal/en/signin">
                      My Help Desk
                    </NavLink>
                  </MenuItem>
                  <MenuItem className={classes.menuItem} onClick={setClose}>
                    <NavLink
                      className={classes.navLinkItem}
                      disableRipple
                      href="https://vapp.adminportal.pro/">
                      My Control Panel
                    </NavLink>
                  </MenuItem>
                </Menu>
              </MenuList>
            </Hidden>
            <div style={{ display: 'flex' }}>
              <Hidden xsDown>
                <CTA
                  href="https://aka.ms/wvdwebarm"
                  variant="contained"
                  disableElevation
                  style={{ marginLeft: 0 }}
                  endIcon={
                    (ixDown || !smDown) && (
                      <SectionImg
                        src={buttonIcon}
                        style={{ height: theme.spacing(3), marginRight: 0 }}
                        alt="Velocity Section Image"
                      />
                    )
                  }>
                  {!ixDown && `My Desktop`}
                </CTA>
              </Hidden>
              <Hidden lgUp>
                <NavDrawer />
              </Hidden>
            </div>
          </Toolbar>
        </AppBar>
      </ColorOnScroll>
    </Box>
  );
};

export default AppNavigationBar;
