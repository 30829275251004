import React from 'react';
import AppTopBar from 'components/common/AppTopBar';
import UseCaseMedicalBlock1 from 'components/UseCase/UseCaseMedicalBlock1';
import UseCaseMedicalBlock2 from 'components/UseCase/UseCaseMedicalBlock2';
import Footer from 'components/common/Footer';
import CopyRight from 'components/common/CopyRight';
import AppNavigationBar from 'components/common/AppNavigationBar';

const UseCaseMedical: React.FC = () => (
  <React.Fragment>
    <AppTopBar elevation={0} style={{ zIndex: 1201 }} />
    <AppNavigationBar />
    <UseCaseMedicalBlock1 />
    <UseCaseMedicalBlock2 />
    <Footer />
    <CopyRight />
  </React.Fragment>
);

export default UseCaseMedical;
