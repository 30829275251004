import React from 'react';
import { makeStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';

const HeroBlock = styled(Box)(({ theme }) => ({
  padding: theme.spacing(6, 0),
  backgroundColor: '#f8f8f8',
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(2),
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: theme.breakpoints.values['lg'],
      margin: '0 auto',
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
    label: {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: 'normal',
      color: '#f15236',
      marginBottom: 16,
    },
    title: {
      fontWeight: 700,
      fontSize: 20,
      lineHeight: 1.4,

      color: '#423f3f',
    },
    text: {
      fontSize: 18,
      lineHeight: 1.4,
      marginBottom: 16,
    },
    question: {
      fontWeight: 600,
      fontSize: 20,
      lineHeight: 1.4,
      marginBottom: 16,
    },
    textGrid: {
      display: 'flex',
      justifyContent: 'center',
    },
  })
);

const ContactUsBlock2: React.FC = () => {
  const classes = useStyles();

  return (
    <HeroBlock component="div">
      <Grid container className={classes.root}>
        <Grid item xs={12} sm={6} className={classes.textGrid}>
          <Box minWidth="180px">
            <Typography variant="h4" component="h2" className={classes.label}>
              Americas
            </Typography>
            <Typography variant="subtitle1" component="h6" className={classes.title}>
              Velocity EU, Inc
            </Typography>
            <Typography variant="subtitle1" component="p" className={classes.text}>
              651 N Broad St,
              <br />
              Suite 206
              <br />
              Middletown, DE 19709
              <br />
              USA
              <br />
              +1 (331) 2261818
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.textGrid}>
          <Box minWidth="180px">
            <Typography variant="h4" component="h2" className={classes.label}>
              EMEA
            </Typography>
            <Typography variant="subtitle1" component="h6" className={classes.title}>
              Velocity EU
            </Typography>
            <Typography variant="subtitle1" component="p" className={classes.text}>
              14 East Bay Lane
              <br />
              Stratford
              <br />
              London
              <br />
              E15 2GW
              <br />
              +44 (20) 33895556
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </HeroBlock>
  );
};

export default ContactUsBlock2;
