import React from 'react';
import { makeStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';
import sectionImg from 'assets/use-case-block-2.png';

const HeroBlock = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4, 0, 8),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2, 0, 4),
  },
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: theme.breakpoints.values['lg'],
      margin: '0 auto',
      padding: theme.spacing(0, 3),
      color: '#423f3f',
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
    label: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: 'normal',
      color: '#737373',
    },
    title: {
      fontSize: 34,
      fontWeight: 700,
    },
    text: {
      width: '100%',
      fontSize: 18,
      marginBottom: 16,
      display: 'inline-block',
    },
    imageGrid: {
      backgroundImage: `url(${sectionImg})`,
      backgroundSize: 'contain',
      backgroundPosition: 'center top',
      backgroundRepeat: 'no-repeat',
      [theme.breakpoints.down('sm')]: {
        backgroundSize: 'cover',
        marginBottom: 24,
        minHeight: 220,
      },
    },
    textGrid: {
      paddingLeft: 24,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
      },
    },
    list: {
      padding: '16px 0',
    },
    listItem: {
      paddingTop: 4,
      paddingBottom: 4,
    },
    listItemIcon: {
      minWidth: 32,
    },
    listItemText: {
      margin: 0,
      fontWeight: 600,
    },
  })
);

const UseCaseEducationBlock2: React.FC = () => {
  const classes = useStyles();

  return (
    <HeroBlock component="div">
      <Grid container className={classes.root}>
        <Grid item xs={12} md={6} className={classes.imageGrid}></Grid>
        <Grid item xs={12} md={6} className={classes.textGrid}>
          <Typography variant="subtitle1" component="span" className={classes.text}>
            {`The fully integrated corporate PBX has allowed us to move from the legacy systems and operate the College Numbers, Extensions, Voicemail, Call recording and a host of other features from anywhere, a teacher’s home, college staff room or the admin offices. This has allowed the rapid deployment of a fully featured voice and data network with comprehensive and simple management of our IT estate.`}
          </Typography>
          <Typography variant="subtitle1" component="span" className={classes.text}>
            {`Utilising the Velocity Cloud Desktops has been an un-measurable success allowing students from any vocation to access any programs as if they were in the college. These include our Adobe Creative Cloud applications which amazingly now work on any PC, laptop, tablet, thin client and almost any other device connected to the system.`}
            <br />
            {`Working with Velocity EU, we have acquired some entry level devices which arrive pre- configured and ready to go and we have distributed many to “Digitally Deprived” students who otherwise would not have access to remote learning.`}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" component="p" className={classes.text}>
            {`Moving our desktop PC workloads into the cloud has proven to have huge inherent benefits such as fault tolerance, automated backups, work from anywhere, security and efficiency with significantly boosted performance.`}
          </Typography>
          <Typography variant="subtitle1" component="p" className={classes.text}>
            {`Velocity Eu have taken our college to the next level in IT where we now operate an agile flexible secure and futureproof network.`}
            <br />
            {`Thank you for all the hard work.`}
          </Typography>
          <Typography variant="subtitle1" component="p" className={classes.text}>
            {`A Happy IT Manager!!`}
          </Typography>
        </Grid>
      </Grid>
    </HeroBlock>
  );
};

export default UseCaseEducationBlock2;
