/* eslint-disable */
import React from 'react';
import { makeStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';

const HeroBlock = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 0),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: theme.breakpoints.values['lg'],
      margin: '16px auto',
      padding: theme.spacing(1, 2),
      fontSize: 18,
      flexDirection: 'column',
    },
    title: {
      fontWeight: 800,
      fontSize: 44,
      color: '#423f3f',
    },
    textBox: {
      color: '#423f3f',
    },
    textIcon: {
      width: 20,
      marginRight: 12,
    },
    list: {
      padding: '16px 0',
    },
    listItem: {
      paddingTop: 4,
      paddingBottom: 4,
    },
    listItemIcon: {
      minWidth: 32,
    },
    listItemText: {
      margin: 0,
    },
  })
);

const Terms: React.FC = () => {
  const classes = useStyles();

  return (
    <HeroBlock component="div">
      <Grid container className={classes.root}>
        <Typography gutterBottom variant="h2" component="h2">
          Terms and Conditions
        </Typography>
        <p>Velocity EU Inc Terms and Conditions for Purchasing Goods and Services</p>
        <ol>
          <li>
            <strong>Definitions</strong>
          </li>
        </ol>
        <p>
          For the purpose of these Terms and Conditions:
          <br /> <strong>Agreement</strong> means the Order and the Supplier&rsquo;s acceptance of
          the Order;
          <br /> <strong>Goods</strong> means any goods and/or services agreed in the Agreement to
          be purchased by the Purchaser from the Supplier (including any part or parts of them);
          <br /> <strong>Order</strong> means the Purchaser&rsquo;s written instruction for the
          Supplier to supply the Goods, incorporating these Terms and Conditions;
          <br /> <strong>Delivery Address</strong> means the address the supplier should deliver the
          Goods to.
          <br /> <strong>Delivery Date</strong> means the date the Purchaser requires delivery of
          the Goods.
          <br /> <strong>Purchaser</strong> means Velocity EU Inc registered in Delaware, US.
          <br /> <strong>Supplier</strong> means the person, firm or company who accepts the Order.
        </p>
        {/* @ts-ignore */}
        <ol start="2">
          <li>
            <strong>Supplier's Conditions</strong>
          </li>
        </ol>
        <p>
          2.1 The Order, including these Terms and Conditions contains the entire agreement and
          understanding between the Supplier and the Purchaser and supersedes all prior discussions
          and negotiations between them. No terms or conditions endorsed upon, delivered with or
          otherwise contained or stated in the Supplier&rsquo;s quotation, or in the
          Supplier&rsquo;s acknowledgement or acceptance of the Order shall be binding on the
          Purchaser if in conflict with or in addition to any of the provisions of the Order
          (including but not limited to delivery schedule, price, quantity, specification and terms
          and conditions) unless expressly agreed to in writing by the Purchaser.
        </p>
        <p>
          2.2 Acceptance of an Order by the Supplier shall take place when it is expressly accepted
          by the Supplier; or by any other conduct of the Supplier which the Purchaser reasonably
          considers is consistent with acceptance of the Order.
        </p>
        <p>
          2.3 An Order may be withdrawn or amended by the Purchaser at any time before acceptance by
          the Supplier.
        </p>
        {/* @ts-ignore */}
        <ol start="3">
          <li>
            <strong>Deliveries</strong>
          </li>
        </ol>
        <p>
          3.1 The Purchaser&rsquo;s production schedules are based upon the Supplier&rsquo;s
          commitment that the Goods will be delivered to the Purchaser by the date specified on the
          Order, or, if a date is not specified, within 5 days of the date of the Order. Time of
          delivery is therefore the essence of the Order. If the Supplier fails to make delivery of
          the Goods at the time agreed upon, the Purchaser reserves the right to cancel, purchase
          elsewhere and hold the Supplier accountable for any additional costs or damages incurred
          by the Purchaser.
        </p>
        <p>
          3.2 The Supplier shall be solely responsible for and pay, all costs of delivering the
          Goods to the Delivery Address. Including, without limitation, all shipping and freight
          costs and all duties, fees, tariffs or similar taxes on imports/exports of the Goods
          (Custom Duties).
        </p>
        <p>
          3.3 The Goods shall be delivered, carriage paid by the Supplier, to the Purchaser&rsquo;s
          place of business or to such other place of delivery as is agreed by the Purchaser in
          writing prior to delivery of the Goods. The Supplier shall off-load the Goods at its own
          risk as directed by the Purchaser.
        </p>
        <p>
          3.4 The Supplier shall ensure that each delivery is accompanied by a delivery note which
          shows, inter alia, the Order number, date of Order, number of packages and contents and,
          in the case of part delivery, the outstanding balance remaining to be delivered.
        </p>
        <p>
          3.5 Unless otherwise stipulated by the Purchaser in the Order, deliveries shall only be
          accepted by the Purchaser in normal business hours. If the Goods are not delivered on the
          due date then, without prejudice to any other rights which it may have, the Purchaser
          reserves the right to:
        </p>
        <p>(a) cancel the Agreement in whole or in part</p>
        <p>
          (b) refuse to accept any subsequent delivery of the Goods which the Supplier attempts to
          make
        </p>
        <p>
          (c) recover from the Supplier any expenditure reasonably incurred by the Purchaser in
          obtaining the Goods in substitution from another supplier
        </p>
        <p>
          (d) claim damages for any additional costs, loss or expenses incurred by the Purchaser
          which are in any way attributable to the Supplier's failure to deliver the Goods on the
          due date.
        </p>
        <p>
          3.6 Where the Purchaser agrees in writing to accept delivery by instalments the Agreement
          shall be construed as a single contract in respect of each instalment. Failure by the
          Supplier to deliver any one instalment shall entitle the Purchaser at its option to treat
          the whole Agreement as repudiated.
        </p>
        <p>
          3.7 The Purchaser shall not be deemed to have accepted the Goods until it has had 30 days
          to inspect them following delivery. The Purchaser shall also have the right to reject the
          Goods as though they had not been accepted for 30 days after any latent defect in the
          Goods has become apparent.
        </p>
        <p>
          3.8 The Goods shall remain at the risk of the Supplier until delivery to the Purchaser is
          complete (including off-loading and stacking) at which time the ownership of the Goods
          shall pass to the Purchaser.
        </p>
        {/* @ts-ignore */}
        <ol start="4">
          <li>
            <strong>Prices</strong>
          </li>
        </ol>
        <p>
          The price of the Goods shall be stated in the Order and unless otherwise agreed in writing
          by the Purchaser shall be exclusive of value added tax but inclusive of all other charges.
          No variation in the price nor extra charges shall be accepted by the Purchaser.
        </p>
        {/* @ts-ignore */}
        <ol start="5">
          <li>
            <strong>Payment</strong>
          </li>
        </ol>
        <p>
          5.1 The Purchaser shall pay the price of the Goods that are delivered and accepted
          pursuant to these Terms and Conditions within 30 days from the end of the month of the
          date of valid invoice; as received from the Supplier but time for payment shall not be of
          the essence of the Agreement.
        </p>
        <p>
          5.2 Without prejudice to any other right or remedy, the Purchaser reserves the right to
          set off any amount owing at any time from te Supplier to the Purchaser against any amount
          payable by the Purchaser to the Supplier under the Agreement.
        </p>
        {/* @ts-ignore */}
        <ol start="6">
          <li>
            <strong>Quantities</strong>
          </li>
        </ol>
        <p>
          Delivery of the Goods must equal exact amounts ordered unless otherwise agreed by the
          Purchaser. If the Goods are delivered to the Purchaser in excess of the quantities ordered
          the Purchaser shall not be bound to pay for the excess and any excess shall be and shall
          remain at the Supplier&rsquo;s risk and shall be returnable at the Supplier&rsquo;s
          expense.
        </p>
        {/* @ts-ignore */}
        <ol start="7">
          <li>
            <strong>Warranty</strong>
          </li>
        </ol>
        <p>
          7.1 The Supplier warrants that all Goods delivered hereunder shall be free from defect of
          materials or workmanship and shall conform strictly to the specifications, drawings or
          sample specified or furnished. This warranty shall survive any inspection, delivery,
          acceptance of, or payment by the Purchaser for the Goods.
        </p>
        <p>
          7.2 Unless a longer period is provided by applicable law, or otherwise specified by the
          Parties; all Goods shall have a Warranty period of (1) year from the date of delivery from
          the Supplier.
        </p>
        {/* @ts-ignore */}
        <ol start="8">
          <li>
            <strong>Quality and Defects</strong>
          </li>
        </ol>
        <p>
          8.1 The Goods shall be of the best available design, of the best quality, material and
          workmanship, be without fault and conform in all respects with the Order and specification
          and/or patterns supplied or advised by the Purchaser to the Supplier.
        </p>
        <p>
          8.2 The Purchaser&rsquo;s rights under these Terms and Conditions are in addition to the
          statutory conditions implied in favour of the Purchaser by the Sale of Goods Act 1979.
        </p>
        {/* @ts-ignore */}
        <ol start="9">
          <li>
            <strong>Inspection</strong>
          </li>
        </ol>
        <p>
          9.1 All Goods shall be subject to the Purchaser&rsquo;s (and any of the Purchaser&rsquo;s
          clients if necessary) inspection and test at all times before, during or after
          manufacture. The Supplier shall furnish without additional charge, all reasonable
          facilities and assistance for the safe and convenient inspections and tests required by
          the inspectors. Final inspection and acceptance shall be at the Purchaser&rsquo;s premises
          unless otherwise specified.
        </p>
        <p>
          9.2 The Purchaser shall have the right to reject and return at the Supplier&rsquo;s
          expense or in its discretion, to require the correction or replacement of Goods which are
          defective or do not conform to the requirements of the Order. All rejects shall be held at
          the Supplier&rsquo;s risk and expense, including all transportation and handling costs,
          until returned to the Supplier or corrected by the Supplier.
        </p>
        {/* @ts-ignore */}
        <ol start="10">
          <li>
            <strong>The Purchaser's Property</strong>
          </li>
        </ol>
        <p>
          10.1 All material including tools, furnished or specifically paid for by the Purchaser in
          connection with the Order shall be the property of the Purchaser, shall be subject to
          removal at any time without additional cost upon demand by the Purchaser, shall be used
          only for fulfilling orders from the Purchaser, shall be kept separate from other materials
          or tools and shall be clearly identified as the property of the Purchaser. The Supplier
          assumes all liability for loss or damage, with the exception of normal wear and tear and
          agrees to supply detailed statements of inventory upon request by the Purchaser.
        </p>
        <p>
          10.2 Materials, equipment, tools, dies, moulds, copyright, design rights or any other
          forms of intellectual property rights in all drawings, specifications and data supplied by
          the Purchaser to the Supplier or not so supplied but used by the Supplier specifically in
          the manufacture of the Goods shall at all times be and remain the exclusive property of
          the Purchaser but shall be held by the Supplier in safe custody at its own risk and
          maintained and kept in good condition by the Supplier until returned to the Purchaser and
          shall not be disposed of other than in accordance with the Purchaser&rsquo;s written
          instructions, nor shall such items be used otherwise than as authorised by the Purchaser
          in writing.
        </p>
        {/* @ts-ignore */}
        <ol start="11">
          <li>
            <strong>Confidentiality</strong>
          </li>
        </ol>
        <p>
          The Supplier shall keep in strict confidence all technical or commercial know-how,
          specifications, inventions, processes or initiatives which are of a confidential nature
          and have been disclosed to the Supplier by the Purchaser or its agents and any other
          confidential information concerning the Purchaser&rsquo;s business or its products which
          the Supplier may obtain and the Supplier shall restrict disclosure of such confidential
          material to such of its employees, agents or subcontractors as need to know the same for
          the purpose of discharging the Supplier&rsquo;s obligations to the Purchaser and shall
          ensure that such employees, agents or sub-contractors are subject to like obligations of
          confidentiality as bind the Supplier.
        </p>
        {/* @ts-ignore */}
        <ol start="12">
          <li>
            <strong>Assignment</strong>
          </li>
        </ol>
        <p>
          The parties shall not be entitled to transfer any of their rights or obligations in the
          Agreement or any part of it without the prior written consent of the other party.
        </p>
        {/* @ts-ignore */}
        <ol start="13">
          <li>
            <strong>Changes to Orders</strong>
          </li>
        </ol>
        <p>
          An Order may only be amended by written agreement between the Purchaser and the Supplier.
        </p>
        {/* @ts-ignore */}
        <ol start="14">
          <li>
            <strong>Supplementary Information</strong>
          </li>
        </ol>
        <p>
          Any specification, drawings, notes, instructions, engineering notices or technical data
          referred to in the Order shall be deemed to be incorporated herein by reference as if
          fully set forth, to the extent that they do not conflict with these Terms and Conditions
          or the Order.
        </p>
        {/* @ts-ignore */}
        <ol start="15">
          <li>
            <strong>Publicity, Promotion or Advertising</strong>
          </li>
        </ol>
        <p>
          The Supplier shall not, without the Purchaser&rsquo;s prior written consent, issue any
          news release, advertising, publicity or promotional material regarding the Order
          (including denial or confirmation thereof).
        </p>
        {/* @ts-ignore */}
        <ol start="16">
          <li>
            <strong>Force Majeure</strong>
          </li>
        </ol>
        <p>
          The Purchaser reserves the right to defer the date of delivery or payment or to cancel the
          Agreement or reduce the volume of the Goods ordered if it is prevented from or delayed in
          the carrying on of its business due to circumstances beyond the reasonable control of the
          Purchaser including, without limitation, acts of God, governmental actions, war or
          national emergency, acts of terrorism, protests, riot, civil commotion, fire, explosion,
          flood, epidemic, lock-outs, strikes or other labour disputes (whether or not relating to
          either party's workforce), or restraints or delays affecting carriers or inability or
          delay in obtaining supplies of adequate or suitable materials.
        </p>
        {/* @ts-ignore */}
        <ol start="17">
          <li>
            <strong>Termination</strong>
          </li>
        </ol>
        <p>
          17.1 The Purchaser shall have the right at any time and for any reason to terminate the
          Agreement in whole or in part by giving the Supplier written notice whereupon all work on
          the Agreement shall be discontinued and the Purchaser shall pay to the Supplier fair and
          reasonable compensation for work-in-progress at the time of termination but such
          compensation shall not include loss of anticipated profits or any consequential loss.
        </p>
        <p>
          17.2 The Purchaser shall have the right at any time by giving notice in writing to the
          Supplier to terminate the Agreement forthwith if:
        </p>
        <p>
          (a) the Supplier commits a material breach of any of the terms and conditions of the
          Agreement; or
        </p>
        <p>
          (b) the Supplier (being an individual) shall commit an act of bankruptcy or a receiving
          order be made against him or if the Supplier (being a company) shall enter into an
          arrangement or composition with its Creditors or go into liquidation whether voluntary or
          otherwise except for the purpose of amalgamation or reconstruction.
        </p>
        <p>
          17.3 The termination of the Agreement, however arising, shall be without prejudice to the
          rights and duties of the Purchaser accrued prior to termination. The conditions which
          expressly or impliedly have effect after termination shall continue to be enforceable
          notwithstanding termination.
        </p>
        {/* @ts-ignore */}
        <ol start="18">
          <li>
            <strong>Indemnity</strong>
          </li>
        </ol>
        <p>
          The Supplier shall keep the Purchaser indemnified in full against all direct, indirect or
          consequential liabilities (which terms include without limitation, loss of profit, loss of
          business, depletion of goodwill and like loss) loss, damages, injury, costs and expenses
          (including legal and other professional fees and expenses) awarded against or incurred or
          paid by the Purchaser as a result of or in connection with:
        </p>
        <p>(a) defective workmanship, quality or materials</p>
        <p>
          (b) an infringement or alleged infringement of any intellectual property rights caused by
          the use, manufacture or supply of the Goods
        </p>
        <p>
          (c) any claim made against the Purchaser in respect of any liability, loss, damage,
          injury, cost or expense sustained by the Purchaser&rsquo;s employees or agents or by any
          customer or third party to the extent that such liability, loss, damage, injury, cost or
          expense was caused by, relates to or arises from the Goods as a consequence of a direct or
          indirect breach or negligent performance or failure or delay in performance of the terms
          of the Agreement by the Supplier.
        </p>
        {/* @ts-ignore */}
        <ol start="19">
          <li>
            <strong>Remedies</strong>
          </li>
        </ol>
        <p>
          Without prejudice to any other right or remedy which the Purchaser may have, if any Goods
          are not supplied in accordance with, or the Supplier fails to comply with, any of the
          terms of the Agreement the Purchaser shall be entitled to avail itself of any one or more
          of the following remedies at its discretion, whether or not any part of the Goods have
          been accepted by the Purchaser:
        </p>
        <p>(a) to rescind the Order</p>
        <p>
          (b) to reject the Goods (in whole or in part) and return them to the Supplier at the risk
          and cost of the Supplier on the basis that a full refund for the Goods so returned shall
          be paid forthwith by the Supplier
        </p>
        <p>
          (c) at the Purchaser&acute;s option to give the Supplier the opportunity at the
          Supplier&rsquo;s expense either to remedy any defect in the Goods or to supply replacement
          Goods and carry out any other necessary work to ensure that the terms of the Agreement are
          fulfilled
        </p>
        <p>
          (d) to refuse to accept any further deliveries of the Goods but without any liability to
          the Supplier
        </p>
        <p>
          (e) to carry out at the Supplier&rsquo;s expense any work necessary to make the Goods
          comply with the Agreement
        </p>
        <p>
          (f) to claim such damages as may have been sustained in consequence of the
          Supplier&rsquo;s breach or breaches of the Agreement.
        </p>
        {/* @ts-ignore */}
        <ol start="20">
          <li>
            <strong>Compliance with Laws</strong>
          </li>
        </ol>
        <p>
          The Supplier shall comply with all applicable laws, regulations, regulatory policies,
          guidelines or industry codes which may apply to the provision of the Goods, and any
          mandatory policies of the Purchaser including but not limited to The Modern Slavery Act
          2015, the Bribery Act 2010 and Data Protection and Privacy. If the Supplier commits a
          breach of this clause 20, the Purchaser shall be entitled to terminate this Agreement with
          immediate effect by serving written notice on the Supplier. The Supplier shall indemnify
          and keep indemnified the Purchaser against all and any liability, damages cost or expense
          incurred (without limitation) by the Purchaser arising out of the Supplier&rsquo;s
          breach(es) of this clause 20.
        </p>
        {/* @ts-ignore */}
        <ol start="21">
          <li>
            <strong>Supplier Code of Conduct</strong>
          </li>
        </ol>
        <p>
          The Supplier warrants that at all times it shall comply with
          Support@velocity-eu.com&rsquo;s Supplier Code of Conduct available at
          www.support@velocity-eu.com.co.uk
        </p>
        {/* @ts-ignore */}
        <ol start="22">
          <li>General</li>
        </ol>
        <p>
          22.1 Each right or remedy of a party under the Agreement is without prejudice to any other
          right or remedy of that party whether under the Agreement or not.
        </p>
        <p>
          22.2 If any provision of the Agreement is found by any court, tribunal or administrative
          body of competent jurisdiction to be wholly or partly illegal, invalid, void, voidable,
          unenforceable or unreasonable it shall, to the extent of such illegality, invalidity,
          voidability, unenforceability or unreasonableness, be deemed severable and the remaining
          provisions of the Agreement and the remainder of such provision shall continue in full
          force and effect.
        </p>
        <p>
          22.3 Failure or delay by a party in enforcing or partially enforcing any provision of the
          Agreement shall not be construed as a waiver of any of its rights under the Agreement.
        </p>
        <p>
          22.4 Any waiver by a party of any breach of, or any default under, any provision of the
          Agreement by a party shall not be deemed a waiver of any subsequent breach or default and
          shall in no way affect the other terms of the Agreement.
        </p>
        <p>
          22.5 The parties to the Agreement do not intend that any term of the Agreement shall be
          enforceable by virtue of the Contracts (Rights of Third Parties) Act 1999 by any person
          that is not a party to it.
        </p>
        <p>
          22.6 Where a framework agreement exists between the Purchaser and a Supplier for the
          supply of goods and/or services covered by this Order then the details within the
          framework agreement shall take precedence over these Terms and Conditions.
        </p>
        <p>
          22.7 This Agreement shall be governed by, construed and interpreted according to English
          Law and shall be subject to the exclusive jurisdiction of the English courts
        </p>
        <p>
          <strong>. BASIS OF SALE</strong>
        </p>
        <p>
          1.1 All Contracts shall be governed by these Conditions (and where applicable any other
          terms agreed in writing by an Authorised Officer of VELOCITY EU INC) to the exclusion of
          any other terms and conditions, including without limit any terms on or referred to in any
          Buyer purchase order or other Buyer documentation. In the case of Orders placed by
          Electronic Means which refer to any terms and conditions of the Buyer, VELOCITY EU
          INC&rsquo;s automatic taking on to its system of such order shall amount to a rejection of
          the Buyer&rsquo;s terms and conditions and an offer to supply the Goods ordered on the
          basis of these Conditions.
        </p>
        <p>
          1.2 No variation to these Conditions shall be binding unless agreed in writing by an
          Authorised Officer of VELOCITY EU INC.
        </p>
        <p>
          1.3 Subject to clause 1.2, VELOCITY EU INC's employees or agents have no authority to make
          any representations concerning the Goods or Services. In entering into the Contract the
          Buyer acknowledges that it does not rely on, and irrevocably waives any claim it may have
          for damages for or right to rescind the Contract for any such representations (unless made
          fraudulently).
        </p>
        <p>
          1.4 Any advice or recommendation for the Goods given by VELOCITY EU INC or its employees
          or agents to the Buyer or its employees or agents not confirmed in writing by an
          Authorised Officer is followed or acted upon entirely at the Buyer's own risk and VELOCITY
          EU INC shall not be liable for any such advice or recommendation.
        </p>
        <p>
          1.5 All references in these Conditions to VELOCITY EU INC agreeing, approving, waiving or
          specifying a matter apply only if such is confirmed in writing by an Authorised Officer.
        </p>
        <p>
          1.6 Any typographical, clerical or other error or omission in any sales literature,
          quotation, price list, acceptance of offer, invoice or other document or information
          issued by VELOCITY EU INC shall be subject to correction without any liability on the part
          of VELOCITY EU INC.
        </p>
        <p>
          1.7 It shall be a condition of any quotation and subsequent Contract (if applicable) that
          the information provided by the Buyer is correct, accurate, not misleading and a complete
          response to VELOCITY EU INC's request(s) when provided and remains so for at least until
          the later of the date when the quotation lapses or the relevant Goods and/or Services are
          delivered.
        </p>
        <p>
          1.8 Any quotation for the Goods given by VELOCITY EU INC shall (i) not constitute an offer
          and (ii) only be valid for a period of 5 business days from its date of issue.
        </p>
        <p>
          1.9 Any Order constitutes an offer by the Buyer to purchase the Goods incorporating these
          Conditions. Orders accepted by VELOCITY EU INC are accepted solely subject to these
          Conditions and the Contract shall come into existence when VELOCITY EU INC accepts the
          Order or by processing the Order for delivery (whichever is earlier).
        </p>
        {/* @ts-ignore */}
        <ol start="2">
          <li>
            <strong>CANCELLATION OF ORDERS</strong>
          </li>
        </ol>
        <p>
          2.1 No Contract may be cancelled by the Buyer unless agreed in writing by VELOCITY EU INC
          if so agreed, the Buyer shall indemnify VELOCITY EU INC in full against all loss
          (including loss of profit), costs, damages, charges and expenses incurred by VELOCITY EU
          INC as a result of cancellation.
        </p>
        <p>
          2.2 VELOCITY EU INC shall have the right immediately to cancel or to suspend any Contract
          or any delivery to be made under the Contract without any liability to the Buyer if:
        </p>
        <p>
          2.2.1 the Buyer fails to make any payment when due or breaches any provision of the
          Contract and the Buyer has failed to remedy such breach within 7 days after receipt of
          notice in writing from VELOCITY EU INC requiring the Buyer to do so;
        </p>
        <p>
          2.2.2 the Buyer suspends or threatens to suspend payment of its debts, or is unable to pay
          its debts as they fall due within the meaning of section 123 of the Insolvency Act 1986;
          or
        </p>
        <p>
          2.2.3 the Buyer (a) negotiates with its creditors for rescheduling of its debts, (b) makes
          a proposal to or compounds with its creditors in respect of its debts other than solely by
          way of solvent amalgamation or reconstruction or (c) makes an application to court for
          protection from its creditors generally; or
        </p>
        <p>
          2.2.4 the Buyer passes a resolution for winding-up or for the appointment of an
          administrator, or a liquidator or a winding-up order is made other than solely in relation
          to a solvent amalgamation or reconstruction (or in the case of an individual is made
          bankrupt); or
        </p>
        <p>
          2.2.5 an administrator, receiver or administrative receiver is or is likely to be
          appointed in relation to the Buyer or any of its assets; or
        </p>
        <p>
          2.2.6 any creditor of the Buyer attaches, takes possession of, or any distress, execution
          or similar process is levied or enforced against, all or any part of the Buyer&rsquo;s
          assets, and such attachment or process is not discharged within ten Business Days
        </p>
        <p>2.2.7 the Buyer ceases, or threatens to cease, to carry on business; or</p>
        <p>
          2.2.8 VELOCITY EU INC reasonably apprehends that any of the events mentioned above is
          about to occur in relation to the Buyer and notifies the Buyer accordingly; or
        </p>
        <p>
          2.2.9 the Buyer commits or is a party to dishonest or fraudulent conduct in relation to
          the Contract.
        </p>
        <p>
          2.3 If clause 2.2 applies then, without prejudice to any other right or remedy available
          to VELOCITY EU INC, VELOCITY EU INC shall be entitled to do any one or more of the
          following:
        </p>
        <p>2.3.1 cancel each and every Contract yet to be performed (in whole or in part);</p>
        <p>
          2.3.2 suspend any further deliveries under each and every Contract without liability to
          the Buyer;
        </p>
        <p>
          2.3.3 immediately revoke any and all credit extended to the Buyer on such terms as
          VELOCITY EU INC shall in its sole and absolute discretion determine;
        </p>
        <p>2.3.4 reduce or cancel all quantity and other discounts offered to the Buyer; and</p>
        <p>
          2.3.5 if the Goods have been delivered but not paid for the price shall become immediately
          due and payable notwithstanding any previous agreement or arrangement to the contrary.
        </p>
        {/* @ts-ignore */}
        <ol start="3">
          <li>
            <strong>DELIVERY</strong>
          </li>
        </ol>
        <p>
          3.1 Unless otherwise agreed in writing with an Authorised Officer the costs of packing and
          Delivery shall be for the Buyer's account. If VELOCITY EU INC pays for or incurs all or
          part of such costs, it shall invoice its costs so incurred to the Buyer at the date of
          dispatch.
        </p>
        <p>
          3.2 The Buyer warrants the details of any address for Delivery stipulated by the Buyer
          (including those of End Users). VELOCITY EU INC reserves the right to invoice the Buyer
          for any losses incurred by VELOCITY EU INC for failed or re-routed deliveries as a result
          of inaccurate information provided by the Buyer (including where recipient is not
          available/unwilling to accept the Goods).
        </p>
        <p>
          3.3 Any Delivery dates given are estimated dates only and time is not of the essence for
          Delivery. Changed specifications or instructions may result in revised estimated Delivery
          times.
        </p>
        <p>
          3.4 The Goods may be delivered in instalments. Each delivery shall constitute a separate
          Contract and failure by VELOCITY EU INC to deliver any one or more of the instalments in
          accordance with these Conditions or any claim by the Buyer in respect of any one or more
          instalments shall not entitle the Buyer to treat the Contract as a whole as repudiated.
        </p>
        <p>
          3.5 If the Buyer (i) arranges collection of the Goods from VELOCITY EU INC and the Buyer's
          carrier fails to take receipt of the Goods after VELOCITY EU INC has notified the Goods
          are ready for collection or (ii) fails to give VELOCITY EU INC adequate Delivery
          instructions or (iii) has requested a delay in Delivery or (iv) is unable to give access
          to its premises for the purposes of Delivery or installation then VELOCITY EU INC may at
          the risk and expense of the Buyer:
        </p>
        <p>
          3.5.1 store the Goods until actual Delivery and invoice the Buyer for all such costs of
          storage and re-Delivery; or
        </p>
        <p>
          3.5.2 sell the Goods at the best price readily obtainable and (after deducting all
          storage, selling and other expenses) account to the Buyer (if available) or charge the
          Buyer for any shortfall (as applicable).
        </p>
        <p>
          3.6 Where Goods are to be exported out of the United Kingdom by either (i) VELOCITY EU INC
          to the Buyer or (ii) by the Buyer itself (subject to any special terms agreed in writing
          between the Buyer and VELOCITY EU INC and notwithstanding any other provision of these
          Conditions):
        </p>
        <p>
          (i) The terms of purchase of the Goods will be subject only to warranty provided by the
          original equipment manufacturer (&ldquo;OEM&rdquo;) and the Buyer shall be solely
          responsible for ensuring that it fully understands and is aware of such warranty terms;
        </p>
        <p>
          (ii) The Buyer shall ensure that it complies with any export controls as notified by
          VELOCITY EU INC, the OEM or a third party or as contained within any supporting
          documentation provided with the Goods;
        </p>
        <p>
          (iii) The relevant tax legislation will be applied in accordance with and under the United
          Kingdom legislation at the time of the contract;
        </p>
        <p>
          (iv) The Buyer shall be responsible for complying with any legislation or regulations
          governing the importation of the Goods into the country of destination and for the payment
          of any import taxes or duties thereon;
        </p>
        <p>
          (v) Unless otherwise agreed in writing between the Buyer and VELOCITY EU INC, delivery
          terms of the Goods shall be &lsquo;Delivered at Place&rsquo; (as per Incoterms 2010) to
          the UK port of export and VELOCITY EU INC shall be under no obligation to give notice
          under section 32(3) of the Sale of Goods Act 1979
        </p>
        <p>
          (vi) the Buyer shall be responsible for arranging for testing and inspection of the Goods
          at VELOCITY EU INC's premises before shipment. VELOCITY EU INC shall have no liability for
          any claim in respect of any defect in the Goods, which would be apparent on inspection and
          which is made after shipment, or in respect of any damage during transit;
        </p>
        <p>
          (vii) The Buyer shall not be entitled to withhold payment of the price for the Goods due
          to the Buyer's failure to comply with the provisions of this clause; and
        </p>
        <p>
          (viii) The Goods will be packaged in accordance with VELOCITY EU INC's standard practice,
          and the packaging shall meet any reasonable requirements stipulated in advance by any
          independent contractors or shippers.
        </p>
        <p>
          3.7 The Buyer's failure to make due payment in respect of any deliveries or instalments
          under any Contract shall entitle VELOCITY EU INC to delay, suspend or cancel further
          deliveries in whole or in part at its option.
        </p>
        <p>
          3.8 Where the Buyer fails to take Delivery of the whole quantity of the Contract at the
          due time, any discount or other allowance in respect of the Goods, which the Buyer is or
          would be entitled to shall be forfeited.
        </p>
        <p>
          3.9 Where VELOCITY EU INC has agreed to ship Goods or perform Services direct to End User
          on behalf of Buyer any such shipment or performance shall be deemed to be Delivery to
          Buyer and any refusal by the End User to accept Delivery or performance shall be deemed to
          be a refusal by Buyer. It shall be Buyer&rsquo;s obligation to report any delivery
          discrepancies in accordance with this clause 3 when Goods are shipped direct to End User
          or when Goods are sent onto End User by Buyer.
        </p>
        {/* @ts-ignore */}
        <ol start="4">
          <li>
            <strong>ACCEPTANCE AND RETURNS PROCEDURE</strong>
          </li>
        </ol>
        <p>
          4.1 VELOCITY EU INC shall not be liable in respect of any damage to the Goods, discrepancy
          in the Contract, shortage in the Goods Delivered, loss of the Goods in transit or any
          claim that the Goods delivered or collected do not otherwise comply with the Contract
          other than in accordance with this Condition and warranty clause 4 as stated below.
        </p>
        <p>4.2 Damage, discrepancies, shortages and invoice queries:</p>
        <p>
          (i) The Buyer shall be responsible for inspecting the boxed / parcel contents containing
          the Goods on Delivery to check the Goods for damages, discrepancies and shortages. On
          Delivery, the Buyer must write on the Despatch Note prior to signing of any damages,
          discrepancies and shortages.
        </p>
        <p>
          (ii) The invoiced Charges shall, in the absence of a manifest error, be deemed accepted by
          the Buyer unless the Buyer notifies VELOCITY EU INC customer services in writing within 14
          days of the date of the invoice.
        </p>
        <p>
          (iii) The Buyer shall notify VELOCITY EU INC customer services in writing within 48 hours
          of Delivery of any short Deliveries, damaged Goods Delivered or any non-shipment of Goods
          detailed on the proof of Delivery.
        </p>
        <p>
          Save for bona fide notifications received by VELOCITY EU INC under 4.2(i), (ii) and
          4.2(iii) above, VELOCITY EU INC shall have no liability whatsoever to Buyer in respect of
          the matters noted above.
        </p>
        <p>
          4.3 Goods that fail on installation ("DOAs"): VELOCITY EU INC operates a returns procedure
          for DOAs. The DOA returns procedure may vary depending on the OEM of the Goods and will be
          notified to the Buyer upon the Buyer notifying VELOCITY EU INC (within 14 days of
          Delivery) that the Goods have apparently failed on installation.
        </p>
        <p>
          4.4 Goods that fail after installation ("Faulty Goods"): In no circumstances may the
          alleged Faulty Goods be returned to VELOCITY EU INC without VELOCITY EU INC&rsquo;s prior
          written consent. Where Goods are returned a handling charge may be levied at VELOCITY EU
          INC's discretion and shall be either deducted from any credit allowed by VELOCITY EU INC
          (should the Goods be accepted at VELOCITY EU INC&rsquo;s discretion as Faulty Goods) or be
          payable to VELOCITY EU INC by the Buyer upon demand. The Buyer must notify VELOCITY EU INC
          immediately of the fault becoming apparent and follow VELOCITY EU INC&rsquo;s instructions
          in relation to the fault.
        </p>
        <p>4.5 General provisions relating to DOAs and Faulty Goods:</p>
        <p>
          (1) The Buyer shall pay all VELOCITY EU INC's reasonable costs and expenses if the Goods
          suspected to be DOA or Faulty Goods by the Buyer prove not to be DOA or Faulty Goods (at
          VELOCITY EU INC&rsquo;s sole discretion). (2) Any returns shall be subject to and the
          Buyer shall comply with VELOCITY EU INC&rsquo;s returns authorisation procedures. (3) DOA
          and Faulty Goods shall be dealt with in accordance with the applicable OEM&rsquo;s DOA or
          Faulty Goods procedures and the Buyer shall comply with the same. (4) The Buyer shall be
          responsible for all transportation and insurance costs relating to returned Goods. (5) The
          Buyer shall have no right to return any Goods delivered in accordance with the Contract.
          (6) Goods must be received by VELOCITY EU INC within 14 days of VELOCITY EU INC issuing a
          returns authorisation number to Buyer.
        </p>
        <p>
          4.6 Where a return under this clause 4 is approved in writing by VELOCITY EU INC, the
          Goods (or part thereof) to be returned must be delivered to VELOCITY EU INC's premises in
          its original packaging together with supporting documentation confirming the alleged fault
          and quoting the relevant returns number. In the event the Buyer fails to comply with this
          clause 4.6, VELOCITY EU INC will be entitled to levy a handling fee as applicable in the
          circumstances.
        </p>
        <p>
          4.7 VELOCITY EU INC shall be under no obligation to accept return of any Goods other than
          as provided in the warranty clause below.
        </p>
        {/* @ts-ignore */}
        <ol start="5">
          <li>
            <strong>PRICE</strong>
          </li>
        </ol>
        <p>
          5.1 All VELOCITY EU INC prices for Goods and/or Services ("Price(s)") are quoted subject
          to (i) acceptance within any period specified and (i) any increase which may occur as a
          result of factors falling outside the control of VELOCITY EU INC, which without
          limitation, shall include any of the following circumstances:
        </p>
        <p>
          (a) where the Buyer has requested (whether before or after a Contract has been made) any
          variation whatsoever to the quantity, capacity, form, content, style or description of the
          Order or Goods and/or Services, or has requested an earlier or a later Delivery date to
          that originally requested; or
        </p>
        <p>
          (b) where steps are taken by VELOCITY EU INC to comply with any statutory provisions from
          time to time in force, and any increases in the price charged to VELOCITY EU INC of any
          equipment or goods bought in from VELOCITY EU INC&rsquo;s suppliers in order for VELOCITY
          EU INC to fulfil the Contract; or
        </p>
        <p>
          (c) where the supply of the Goods or the provision of Services is suspended, varied or
          otherwise delayed by any acts or omissions of the Buyer
        </p>
        <p>
          (d) where VELOCITY EU INC has incurred any additional or unforeseen import duties after
          the Contract has been made.
        </p>
        <p>
          5.2 Unless otherwise stated, the Price does not include the costs of Delivery and VAT and
          any other applicable customs or excise duties or taxes (where applicable) and these will
          be added to all invoices at the rate ruling at the date of despatch.
        </p>
        {/* @ts-ignore */}
        <ol start="6">
          <li>
            <strong>PAYMENT</strong>
          </li>
        </ol>
        <p>
          6.1 Payment of the Charges or any part thereof and any other charges due under the
          Contract must be made by the Buyer within 30 days month end following the date of VELOCITY
          EU INC's invoice (unless otherwise specified in writing by an Authorised Officer).
        </p>
        <p>
          6.2 Should the Buyer fail to make payment by the due date, VELOCITY EU INC shall be
          entitled to charge interest on the overdue amount at the rate of 4% above Barclays
          Banks&rsquo; base rate from time to time. Such interest shall accrue on a daily basis from
          the due date until the actual date of payment (whether before or after judgment).
        </p>
        <p>
          6.3 The Buyer shall make all payments in pounds sterling immediately when due without set
          off, deferment, deduction or withholding whatsoever (whether on account of any claim or
          counterclaim or otherwise). Where payments in an alternative currency are authorised in
          writing by the Authorised Officer prior to the Contract being concluded, such payments
          shall be made by telegraphic transfer to the account to be designated by the Authorised
          Officer from time to time.
        </p>
        <p>6.4 The time of payment shall be of the essence of the Contract.</p>
        <p>
          6.5 The Buyer shall indemnify VELOCITY EU INC against the total costs incurred (without
          limitation) by VELOCITY EU INC arising out of the Buyer's breach(es) of these Conditions.
        </p>
        <p>
          6.6 On the happening of a "Relevant Event" VELOCITY EU INC shall be entitled in its sole
          and absolute discretion to alter its terms of payment or to alter any credit terms which
          may have been granted. For the purposes of this clause 6.6, a "Relevant Event" shall be
          defined as being:
        </p>
        <p>
          (i) where VELOCITY EU INC is notified or otherwise reasonably believes that the Buyer's
          credit record has worsened to a level unacceptable to VELOCITY EU INC; or
        </p>
        <p>
          (ii) where VELOCITY EU INC in its sole discretion deems the Buyer's financial position to
          be unacceptable; or
        </p>
        <p>(iii) where VELOCITY EU INC's trade indemnity insurers require such alteration.</p>
        <p>
          6.7 Notwithstanding clause 6.6, VELOCITY EU INC reserves the right to withdraw any credit
          facilities afforded to the Buyer at any time, without notice.
        </p>
        <p>
          6.8 In the event that the trading relationship between the Buyer and VELOCITY EU INC is
          terminated for whatsoever reason then all sums due by the Buyer shall immediately become
          due and payable.
        </p>
        {/* @ts-ignore */}
        <ol start="7">
          <li>
            <strong>PRICES AND PAYMENT FOR CLOUD SERVICES</strong>
          </li>
        </ol>
        <p>
          7.1 Clause 6 applies in relation to Cloud Services in addition to the terms contained in
          this clause 7. In the event of conflict between clauses 6 and 7 in relation to Cloud
          Services, clause 7 shall take precedence.
        </p>
        <p>
          7.2 Unless expressly stated otherwise in the relevant Order or otherwise agreed between
          the parties, the Buyer shall pay the full amount of Cloud Charges in pounds sterling
          within 30 (thirty) days of the month end following the date of invoice.
        </p>
        <p>
          7.3 VELOCITY EU INC shall give the Buyer 28 (twenty-eight) days' prior written notice of
          any rises in the Cloud Charges.
        </p>
        {/* @ts-ignore */}
        <ol start="8">
          <li>
            <strong>SUSPENSION OF CLOUD SERVICES</strong>
          </li>
        </ol>
        <p>8.1 VELOCITY EU INC may suspend all or any part of the Cloud Services immediately:</p>
        <ol>
          <li>where the Buyer fails to pay any Cloud Charges in accordance with the Contract;</li>
          <li>
            where VELOCITY EU INC has reason to terminate any Contract in accordance with its terms;
          </li>
          <li>
            if a Third Party Provider disables an End User&rsquo;s use of the Cloud Services; or
          </li>
          <li>in any circumstance indicated in the applicable Order.</li>
        </ol>
        <p>
          8.2 Where VELOCITY EU INC suspends the Cloud Services in accordance with clause 8.1, save
          where VELOCITY EU INC is entitled to and subsequently elects to terminate the Contract in
          respect of such Cloud Services in accordance with the terms of that Contract or these
          Conditions, VELOCITY EU INC shall use its reasonable endeavours to reinstate the Cloud
          Services as soon as is reasonably practical upon VELOCITY EU INC becoming satisfied
          (acting reasonably) that the grounds for suspension are no longer applicable and subject
          to the Buyer having paid to VELOCITY EU INC a reinstatement fee in respect of the
          restoration of such Cloud Service(s) in the sum of &pound;250 (or such other amount as may
          be notified to the Buyer from time to time) which shall be payable on demand.
        </p>
        <p>
          8.3 If VELOCITY EU INC exercises its right of suspension under this clause or under any
          Contract this will not exclude its right to terminate the Cloud Services later in respect
          of that or any other event, nor will it prevent VELOCITY EU INC claiming damages from the
          Buyer in respect of any breach.
        </p>
        {/* @ts-ignore */}
        <ol start="9">
          <li>
            <strong>RETENTION OF TITLE AND RISK</strong>
          </li>
        </ol>
        <p>9.1 Risk in the Goods will pass to the Buyer on completion of Delivery.</p>
        <p>
          9.2 Title to the Goods (including full legal and beneficial ownership but excluding
          software, title of which shall never pass to the Buyer) shall not pass to the Buyer until:
        </p>
        <p>
          (i) VELOCITY EU INC receives payment in full for the Goods as supplied to the Buyer; and
        </p>
        <p>
          (ii) VELOCITY EU INC receives payment in full for all and any other debts owed by the
          Buyer to VELOCITY EU INC at any given time; or
        </p>
        <p>
          (iii) The Buyer resells the Goods, in which case title to the Goods shall pass to the
          Buyer at such time as specified in clause 9.5.
        </p>
        <p>9.3 Until title to the Goods has passed to the Buyer, the Buyer will:</p>
        <p>(i) hold the Goods as bailee for VELOCITY EU INC;</p>
        <p>(ii) store the Goods separately from all other material in the Buyer's possession;</p>
        <p>(iii) take all reasonable care of the Goods and keep them in reasonable condition;</p>
        <p>
          (iv) insure the Goods: (i) with a reputable insurer (ii) from the date of delivery (iii)
          against all risks (iv) for an amount at least equal to the Price (v) noting VELOCITY EU
          INC's interest on the policy;
        </p>
        <p>(v) ensure that the Goods are clearly identifiable as belonging to VELOCITY EU INC;</p>
        <p>(vi) not remove or alter any mark on or packaging of the Goods;</p>
        <p>
          (vii) inform VELOCITY EU INC as soon as possible if it becomes subject to an Insolvency
          Event; and
        </p>
        <p>
          (viii) provide VELOCITY EU INC such information concerning the Goods as VELOCITY EU INC
          may request from time to time.
        </p>
        <p>
          9.4 Notwithstanding clause 9.3, and subject to clause 9.5, the Buyer may use or resell the
          Goods in the ordinary course of its business (but not otherwise) before VELOCITY EU INC
          receives payment for the Goods. However, if the Buyer resells the Goods before that time:
        </p>
        <p>9.4.1 It does so as principal and not as VELOCITY EU INC&rsquo;s agent; and</p>
        <p>
          9.4.2 Title to the Goods shall pass from VELOCITY EU INC to the Buyer immediately before
          the time at which resale by the Buyer occurs.{' '}
        </p>
        <p>
          9.5 If, at any time before title to the Goods has passed to the Buyer, the Buyer informs
          VELOCITY EU INC, or VELOCITY EU INC reasonably believes, that the Buyer has or is likely
          to become subject to an Insolvency Event and the Goods remain in the possession or control
          of the Buyer, then, without limiting any of VELOCITY EU INC&rsquo;s other rights and
          remedies:
        </p>
        <p>
          9.5.1 The Buyer&rsquo;s right to resell the Goods or use them in the ordinary course of
          its business ceases immediately; and
        </p>
        <p>9.5.2 VELOCITY EU INC may at any time:</p>
        <p>9.5.2.1 at its sole discretion elect to transfer title to Buyer; or</p>
        <p>
          9.5.2.2 require the Buyer at the Buyer's expense to redeliver the Goods to VELOCITY EU INC
          and if Buyer fails to do so promptly, enter any premises where the Goods are stored and
          repossess them.
        </p>
        <p>
          9.6 Where the Buyer uses banking facilities or factoring or an invoice discounting company
          which involves the selling of debtors or using debtors as security, the Buyer shall notify
          the third party concerned of VELOCITY EU INC's interest in the Goods and specifically that
          title in the Goods has not passed until VELOCITY EU INC&rsquo;s invoice has been paid in
          full and otherwise as set out in these Conditions.
        </p>
        {/* @ts-ignore */}
        <ol start="10">
          <li>
            <strong>LIMITED WARRANTY</strong>
          </li>
        </ol>
        <p>
          10.1 Buyer acknowledges that VELOCITY EU INC does not manufacture the Goods (or where
          Goods comprise computer software does not publish or license the software) and subject to
          the conditions set out below in this clause 10 VELOCITY EU INC only sells Goods with the
          benefit of the OEM&rsquo;s warranty. Warranties are offered from the OEM on a pass-through
          basis to End User or directly by OEM to End User.
        </p>
        <p>
          10.2 VELOCITY EU INC warrants that at the time of Delivery, Goods will conform to the
          specifications stated by the OEM in its published data sheet for the Goods. To the extent
          legally and contractually permitted, VELOCITY EU INC shall pass through to Buyer any
          transferable Goods warranties, indemnities, and remedies provided to VELOCITY EU INC by
          the OEM, including those for intellectual property infringement.
        </p>
        <p>
          10.3 The warranty in clause 10.2 above is in lieu of all warranties whatsoever (whether
          expressed or implied and whether arising at common law or by statute) all of which are
          hereby excluded to the full extent permitted by law. VELOCITY EU INC does not attempt to
          exclude the warranty as to title.
        </p>
        <p>
          10.4 VELOCITY EU INC's warranty in clause 10.2 shall only operate where VELOCITY EU INC is
          able to claim under the OEM&rsquo;s warranty. The Buyer shall be responsible for making
          itself aware of the terms of the OEM&rsquo;s warranty prior to the Contract being formed
          and complying in all respects with the same at all times.
        </p>
        {/* @ts-ignore */}
        <ol start="11">
          <li>
            <strong>LIMITATION OF VELOCITY EU INC'S LIABILITY</strong>
          </li>
        </ol>
        <p>
          11.1 VELOCITY EU INC'S LIABILITY UNDER ANY CONTRACT IS LIMITED TO MAKING GOOD DAMAGE OR
          FAILURES TO THE EXTENT DESCRIBED IN CLAUSE 4 AND SUBJECT TO CLAUSE 10.
        </p>
        <p>
          11.2 VELOCITY EU INC&lsquo;S LIABILITY TO BUYER IS LIMITED TO BUYER&lsquo;S DIRECT DAMAGES
          UP TO AN AMOUNT NOT EXCEEDING THE PRICE OF THE GOODS AT ISSUE. THIS LIMITATION OF
          LIABILITY DOES NOT APPLY IN CASE OF DEATH OR PERSONAL INJURY CAUSED BY VELOCITY EU
          INC&lsquo;S NEGLIGENCE. VELOCITY EU INC IS NOT LIABLE FOR AND BUYER IS NOT ENTITLED TO ANY
          INDIRECT, SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES (FOR EXAMPLE, LOSS OF PROFITS OR
          REVENUE, LOSS OF DATA, LOSS OF USE, REWORK, REPAIR, MANUFACTURING EXPENSE, COSTS OF
          PRODUCT RECALL, INJURY TO REPUTATION OR LOSS OF CUSTOMERS). TO THE EXTENT VELOCITY EU INC
          CANNOT LAWFULLY DISCLAIM ANY IMPLIED OR STATUTORY WARRANTIES, BUYER&lsquo;S STATUTORY
          RIGHTS ARE NOT AFFECTED BY THIS LIMITATION OF LIABILITY.
        </p>
        <p>
          11.3 TO THE EXTENT THE LAW DOES NOT PERMIT SUCH LIABILITY TO BE EXCLUDED, VELOCITY EU INC
          DOES NOT ATTEMPT TO LIMIT ITS LIABILITY FOR DAMAGE TO THE TANGIBLE PROPERTY OF THE BUYER
          RESULTING FROM THE NEGLIGENCE OF VELOCITY EU INC OR ITS EMPLOYEES OR AGENTS TO THE EXTENT
          THAT VELOCITY EU INC IS INSURED AGAINST SUCH LOSS.
        </p>
        <p>
          11.4 THE BUYER UNDERTAKES WITH VELOCITY EU INC THAT IT WILL ENSURE COMPLIANCE SO FAR AS IS
          REASONABLY PRACTICABLE BY ITSELF, ITS EMPLOYEES, AGENTS AND LICENSEES WITH ANY
          INSTRUCTIONS GIVEN BY VELOCITY EU INC OR THE OEM FOR THE PURPOSE OF ENSURING THE GOODS
          WILL BE SAFE AND WITHOUT RISK TO HEALTH WHEN PROPERLY USED AND WILL TAKE ANY STEPS AND
          PRECAUTIONS, HAVING REGARD TO THE NATURE OF THE GOODS AS ARE NECESSARY TO PRESERVE THE
          HEALTH AND SAFETY OF PERSONS HANDLING, USING OR DISPOSING OF THEM.
        </p>
        <p>
          <br /> 11.5 VELOCITY EU INC GIVES NO UNDERTAKING THAT THE GOODS ARE FIT FOR ANY PARTICULAR
          PURPOSE (INCLUDING ANY PURPOSE FOR WHICH SUCH GOODS ARE COMMONLY SUPPLIED) OR IS OF ANY
          PARTICULAR QUALITY IN RESPECT OF ITS APPEARANCE, FINISH, SAFETY, DURABILITY OR FREEDOM
          FROM DEFECTS OR OTHERWISE. THE BUYER HAVING GREATER KNOVELOCITY EU INCEDGE OF HIS OWN
          REQUIREMENTS RELIES ENTIRELY ON HIS OWN SKILL AND JUDGEMENT IN EVALUATING WHETHER THE
          EQUIPMENT IS IN EVERY RESPECT OF SATISFACTORY QUALITY.
        </p>
        {/* @ts-ignore */}
        <ol start="12">
          <li>
            <strong>TELECOMMUNICATIONS GOODS</strong>
          </li>
        </ol>
        <p>
          Where Goods supplied are to be used in conjunction with British Telecom ("BT") lines or
          apparatus then the following additional Conditions shall apply: (i) BT shall have the
          right to require modifications to be carried out to Goods already installed and in use and
          the modifications will be carried out at the Buyer's expense (ii) the Buyer shall
          indemnify VELOCITY EU INC against all and any liability, cost or expense arising out of or
          in connection with damage, loss or injury to BT goods or personnel in connection with or
          arising out of the Buyer's acts or omissions.
        </p>
        {/* @ts-ignore */}
        <ol start="13">
          <li>
            <strong>FORCE MAJEURE</strong>
          </li>
        </ol>
        <p>
          VELOCITY EU INC shall not be liable for any loss or damage and be entitled to cancel or
          rescind any Contract if the performance of its obligations under the Contract is in any
          way adversely affected by any cause whatsoever beyond VELOCITY EU INC's control including
          (but not limited to) the delays or default of supplies or the defaults of any
          sub-contractor, act of God, explosion, fire or accident, war, threat of war, sabotage,
          insurrection, civil disturbance, requisition, Acts, restrictions, regulations, bye-laws,
          prohibitions or measures of any Government or Parliamentary or Local Authority, strike,
          lock-out, trade disputes, flood, accident to plant or machinery, shortage of materials or
          labour, import or export regulations or embargoes. If due to any such event VELOCITY EU
          INC has insufficient stocks to satisfy an Order VELOCITY EU INC may apportion available
          stocks between its customers at its sole discretion.
        </p>
        {/* @ts-ignore */}
        <ol start="14">
          <li>
            <strong>CONFIGURATION</strong>
          </li>
        </ol>
        <p>
          14.1 The Buyer shall be solely responsible for the accuracy of a configuration services
          Order and VELOCITY EU INC shall provide such services entirely under Buyer&rsquo;s
          instruction without warranting that the configured Goods are satisfactory for the purpose
          for which it is required.
        </p>
        <p>
          14.2 Configuration services have a warranty of 14 days from date of shipment to Buyer.
          VELOCITY EU INC's sole liability in respect of any defective configuration services for
          which VELOCITY EU INC is responsible shall be the repair (or at VELOCITY EU INC's sole
          option, replacement) of the Goods on which the services have been performed. Claims under
          this clause 14.2 must be made within 21 days of the date of shipment.
        </p>
        {/* @ts-ignore */}
        <ol start="15">
          <li>
            <strong>DATA PROTECTION</strong>
          </li>
        </ol>
        <p>
          15.1 The parties acknowledge and agree that some or all of the Services to be provided by
          VELOCITY EU INC pursuant to an Order entered into pursuant to these Conditions may involve
          VELOCITY EU INC processing Personal Data of which Buyer is the Data Controller. The
          parties acknowledge and agree that, in respect of such processing, for the purposes of the
          Data Protection Legislation, Buyer is the Data Controller and VELOCITY EU INC is the Data
          Processor.
        </p>
        <p>15.2 Buyer warrants that:</p>
        <p>
          (i) it has all necessary and appropriate consents and notices in place to enable the
          lawful transfer of any Personal Data to VELOCITY EU INC for the duration and purposes of
          any Contract;
        </p>
        <p>
          (ii) all instructions given by it to VELOCITY EU INC in respect of Personal Data shall at
          all times be in accordance with Data Protection Laws; and
        </p>
        <p>
          (iii) it has undertaken due diligence in relation to VELOCITY EU INC&rsquo;s processing
          operations, and it is satisfied that VELOCITY EU INC&rsquo;s processing operations are
          suitable for the purposes for which the Buyer proposes to use the services and engage
          VELOCITY EU INC to process the Protected Data.
        </p>
        <p>
          15.3 VELOCITY EU INC shall process the Personal Data only in accordance with Buyer's
          instructions from time to time and shall not process the Personal Data for any purposes
          other than those expressly authorised by Buyer.
        </p>
        <p>
          15.4 VELOCITY EU INC shall take reasonable steps to ensure the reliability of all its
          employees who have access to the Personal Data.
        </p>
        <p>
          15.5 Each party warrants to the other that it will process the Personal Data in compliance
          with all applicable laws, enactments, regulations, orders, standards and other similar
          instruments.
        </p>
        <p>
          15.6 VELOCITY EU INC warrants that, having regard to the state of technological
          development and the cost of implementing any measures, it will:
        </p>
        <p>
          (i) take appropriate technical and organisational measures against the unauthorised or
          unlawful processing of Personal Data and against the accidental loss or destruction of, or
          damage to, Personal Data to ensure a level of security appropriate to:
        </p>
        <p>
          (a) the harm that might result from such unauthorised or unlawful processing or accidental
          loss, destruction or damage; and
        </p>
        <p>(b) the nature of the data to be protected; and</p>
        <p>(ii) take reasonable steps to ensure compliance with those measures.</p>
        <p>
          15.7 On the basis VELOCITY EU INC warrants to adhere to the remainder of this condition
          15.7, Buyer consents to VELOCITY EU INC engaging sub-processors for carrying out any
          processing activities in respect of the Protected Data. Prior to appointing any such
          sub-processor, VELOCITY EU INC warrants that:
        </p>
        <p>
          (i) prior to the relevant sub-processor carrying out any processing activities in respect
          of the Protected Data, VELOCITY EU INC appoints each sub-processor under a written
          contract containing materially the same obligations as under this condition 15;
        </p>
        <p>
          (ii) VELOCITY EU INC ensures each such sub-processor complies with all such obligations;
          and
        </p>
        <p>
          (iii) VELOCITY EU INC to remain fully liable for all the acts and omissions of each
          sub-processor as if they were its own.
        </p>
        <p>
          15.8 Each party agrees to indemnify and keep indemnified and defend at its own expense the
          other party against all costs, claims, penalties, fines, damages or expenses incurred by
          the other party or for which the other party may become liable due to any failure by the
          first party or its employees or agents to comply with any of its obligations under this
          condition 15.
        </p>
        <p>
          15.9 Buyer acknowledges that VELOCITY EU INC is reliant on Buyer for direction as to the
          extent to which VELOCITY EU INC is entitled to use and process the Personal Data.
          Consequently, VELOCITY EU INC will not be liable for any claim brought by a Data Subject
          arising from any action or omission by VELOCITY EU INC, to the extent that such action or
          omission resulted directly from Buyer's instructions.
        </p>
        <p>
          15.10 The Buyer agrees that VELOCITY EU INC may transfer Protected Data that is processed
          pursuant to VELOCITY EU INC providing the goods and/or services in accordance with an
          Order to countries outside the European Economic Area (EEA)<strong></strong>or to any
          International Organisation(s) (an <strong>International Recipient</strong>), provided all
          transfers by VELOCITY EU INC of Protected Data to an International Recipient (and any
          onward transfer) shall (to the extent required under Data Protection Laws) be effected by
          way of appropriate safeguards and in accordance with Data Protection Laws.
        </p>
        <p>
          15.11 VELOCITY EU INC shall promptly (i) refer all data subject requests it receives to
          the Buyer and (ii) notify the Buyer of the Personal Data Breach and provide any necessary
          details of the same.
        </p>
        {/* @ts-ignore */}
        <ol start="16">
          <li>
            <strong>GENERAL</strong>
          </li>
        </ol>
        <p>
          16.1 Where applicable, Buyer is responsible for all obligations and liabilities under the
          European Union&lsquo;s (i) Waste Electrical and Electronic Equipment Directive
          (2012/19/EU), (ii) Packaging Waste Directive (94/62/EC) and (iii) Batteries Directive
          (2006/66/EC), all as amended and all related national implementing measures in force from
          time to time. VELOCITY EU INC shall have no liability or obligations under the preceding
          directives.
        </p>
        <p>
          16.2 Buyer agrees to comply with the UK Bribery Act 2010 and corresponding legislation
          applicable in the jurisdictions Buyer conducts business in. Buyer shall not make any
          direct or indirect payment, offer to pay, or authorise to pay, any gift, money, promise to
          give or authorise the giving of anything of value to any government official or politician
          or the immediate family of the same for the purpose of influencing acts or decisions of
          such individual in order to assist directly or indirectly Buyer or VELOCITY EU INC in
          obtaining or retaining business or securing an improper advantage. Buyer&rsquo;s
          commitment to comply with the Bribery Act 2010 and other such legislation shall also
          extend to its dealings with VELOCITY EU INC, its suppliers, the End Users and any other
          commercial parties.
        </p>
        <p>
          16.3 VELOCITY EU INC may assign any of its rights and/or obligations under a Contract. The
          Buyer may not without the prior written consent of VELOCITY EU INC assign any of its
          rights and/or obligations under any Contract.
        </p>
        <p>
          16.4 No delay or failure by VELOCITY EU INC in enforcing any provision shall constitute a
          waiver of that provision or any other provision. No waiver by VELOCITY EU INC of any
          breach of a Contract shall be considered as a waiver of any subsequent breach of the same
          or any other provision.
        </p>
        <p>
          16.5 If any provision of these Conditions is held by any competent authority to be invalid
          or unenforceable in whole or in part the validity of the other provisions of these
          Conditions and the remainder of the provision in question shall not be affected.
        </p>
        <p>
          16.6 VELOCITY EU INC's rights are cumulative and in addition to any rights available to it
          at common law.
        </p>
        <p>
          16.7 These Conditions are VELOCITY EU INC's current Conditions. VELOCITY EU INC maintains
          the right to add to or amend these Conditions at any time and in its sole discretion. The
          Buyer will be responsible for satisfying themselves as to the Conditions on an ongoing
          basis and those applicable to the transaction(s) in hand, by either viewing the VELOCITY
          EU INC website or requesting a hard copy direct from VELOCITY EU INC.
        </p>
        <p>
          16.8 Nothing in these Conditions shall confer on any third party (that is, any party other
          than VELOCITY EU INC or the Buyer) any benefit or the right to enforce any term of these
          Conditions and the application of the Contracts (Rights of Third Parties) Act 1999 to
          these Conditions is hereby excluded.
        </p>
        <p>
          16.9 The Buyer and Velocity Eu Inc agree that each Contract (incorporating these
          Conditions) shall be governed by and construed in accordance with English law and each of
          the Buyer and Velocity Eu Inc irrevocably agrees that the courts of England and Wales
          shall have exclusive jurisdiction to settle any dispute or claim (including but not
          limited to non-contractual disputes and claims).
        </p>
        <p>
          16.10 VELOCITY EU INC may monitor, record, store and use any telephone, email or other
          communication with the Buyer in order to (i) check any instructions given to VELOCITY EU
          INC, (ii) for training purposes, (iii) for crime prevention and (iv) to improve the
          quality of VELOCITY EU INC&rsquo;s customer service.
        </p>
        <p>
          16.11 Except as specifically permitted in writing by (a) VELOCITY EU INC or (b) the
          relevant manufacturer(s) of relevant Goods or (c) the provider of relevant Services, the
          Buyer hereby agrees that it will not (and will inform third parties they are not permitted
          to): (i) copy or manufacture any Goods; or (ii) translate, modify, adapt, enhance, extend,
          decompile, disassemble or reverse engineer any of the Goods.
        </p>
      </Grid>
    </HeroBlock>
  );
};

export default Terms;
