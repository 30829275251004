import React from 'react';
import { makeStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import clsx from 'clsx';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FiberManualRecord } from '@material-ui/icons';
import winNTLogo from 'assets/winnt-server-logo.png';
import win2KLogo from 'assets/win-2k-server.png';
import win2003Logo from 'assets/win-2003-server.jpg';
import winxp from 'assets/windowsxp.png';
import winvista from 'assets/win-vista-logo.png';
import win2008serv from 'assets/win-2008-server.png';
import VMWareLogo from 'assets/vmware.png';
import win2012Logo from 'assets/win-2012-server.png';
import winAzureHello from 'assets/azure-hello.png';
import win10Ent from 'assets/win-10-enterprise.png';
import winWVDLogo from 'assets/win-wvd-logo.png';
import winWVD19Logo from 'assets/win-wvd-2019.png';
import velocityCloudLogo from 'assets/velocity-cloud-logo.png';

const HeroBlock = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8, 0),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(4, 0),
  },
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: theme.breakpoints.values['lg'],
      margin: '0 auto',
      padding: theme.spacing(0, 3),
      color: '#423f3f',
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
    label: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: 'normal',
      color: '#737373',
    },
    title: {
      fontSize: 34,
      fontWeight: 700,
      marginBottom: 32,
    },
    text: {
      fontSize: 20,
      marginBottom: 24,
      marginTop: '0 !important',
    },
    textBold: {
      marginBottom: '0 !important',
    },
    quote: {
      fontSize: 20,
      fontWeight: 600,
      fontStyle: 'italic',
      marginBottom: 24,
    },
    list: {
      padding: '16px 0',
    },
    listItem: {
      paddingTop: 4,
      paddingBottom: 4,
      paddingLeft: 0,
      paddingRight: 0,
    },
    listItemIcon: {
      minWidth: 32,
    },
    listItemText: {
      margin: 0,
    },
    timeline: {
      width: '100% !important',
      maxWidth: 'none',
      '&:before': {
        backgroundColor: '#d3d3d3',
      },
    },
    timelineDate: {
      color: '#353639',
      fontWeight: `700 !important` as any,
      fontSize: '24px !important',
      top: '0 !important',
      bottom: '0 !important',
      opacity: '1 !important',
      marginTop: -6,
      display: 'flex !important',
      flexDirection: 'column',
      width: '100%',
      '&::after': {
        content: "''" as string,
        display: 'inline-block',
        maxWidth: 240,
        width: '100%',
        height: 135,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        alignSelf: 'flex-start',
        marginTop: 8,
      },
    },
    timelineNTLogo: {
      '&::after': {
        backgroundImage: `url(${winNTLogo})`,
      },
    },
    timeline2KLogo: {
      '&::after': {
        backgroundImage: `url(${win2KLogo})`,
      },
    },
    timeline2003ServLogo: {
      '&::after': {
        backgroundImage: `url(${win2003Logo})`,
        backgroundPosition: 'top right',
      },
    },
    timelineXPLogo: {
      '&::after': {
        backgroundImage: `url(${winxp})`,
        backgroundSize: '70%',
        backgroundPosition: 'top right',
      },
    },
    timelineVistaLogo: {
      '&::after': {
        backgroundImage: `url(${winvista})`,
      },
    },
    timeline2008Logo: {
      '&::after': {
        backgroundImage: `url(${win2008serv})`,
        backgroundPosition: 'top right',
      },
    },
    timelineVMWareLogo: {
      '&::after': {
        backgroundImage: `url(${VMWareLogo})`,
      },
    },
    timeline2012Logo: {
      '&::after': {
        backgroundImage: `url(${win2012Logo})`,
      },
    },
    timelineAzureHelloLogo: {
      '&::after': {
        backgroundImage: `url(${winAzureHello})`,
      },
    },
    timeline10EntLogo: {
      '&::after': {
        backgroundImage: `url(${win10Ent})`,
        backgroundPosition: 'top right',
      },
    },
    timelineWVDLogo: {
      '&::after': {
        backgroundImage: `url(${winWVDLogo})`,
      },
    },
    timelineWVD2019Logo: {
      '&::after': {
        backgroundImage: `url(${winWVD19Logo})`,
        backgroundPosition: 'top right',
      },
    },
    timelineVelocityCloud: {
      '&::after': {
        backgroundImage: `url(${velocityCloudLogo})`,
      },
    },
    timelineDateRight: {
      '&::after': {
        alignSelf: 'flex-end',
      },
    },
    timelineDateMobile: {
      '&::after': {
        alignSelf: 'flex-start',
        backgroundPosition: '8px 5%, 8px 95%',
        backgroundSize: '90%, 55%',
      },
    },
  })
);

const styles = {
  icons: {
    width: '30px',
    height: '30px',
    border: 'none',
    background: '#4bb1fe',
    color: '#353639',
    marginLeft: -15,
    boxShadow: '0 0 0 20px #fff, 0px 6px 12px 20px #00000044',
    marginTop: 15,
  },
  iconsMobile: {
    width: '20px',
    height: '20px',
    border: 'none',
    background: '#4bb1fe',
    color: '#353639',
    marginLeft: 10,
    boxShadow: '0 0 0 16px #fff, 0px 4px 8px 20px #00000044',
    marginTop: 12,
  },
  content: {
    background: '#dfdfdf',
    color: '#353639',
    borderBottom: '3px solid #4bb1fe',
    boxShadow: 'none',
    minHeight: 145,
  },
};

const listItems = [
  'Printer and clipboard redirection and remote session monitoring is introduced.',
  'Terminal Services licensing is introduced: Windows 2000 client devices are free, all others require a Terminal Services Client Access License (TS-CAL).',
  'RDP (Remote Desktop Protocol) is refined with bitmap caching and API is created.',
];

const listItems2 = [
  'Terminal Server licensing is no longer free for non-Windows 2000 client devices.',
  `Windows XP is a desktop operating system based on NT and supports single user session RDP connections.`,
  'Windows Server 2003 with Terminal Services role supports multiple concurrent users like NT 4.0 and Server 2000.',
  'any RDP improvements are introduced including local drives and audio redirection, admin tools, group policies, WMI provider, and much more.',
];

const listItems3 = [
  'On-premises deployments run Windows Server 2003 and virtual desktops are served via Terminal Services to both local and remote users.',
  'Hosting providers leverage Windows Server 2003 to host virtual desktop sessions for customers in data centers.',
  'Licensing is provided via the Microsoft SPLA program for the Server operating system and Terminal Services Subscriber Access Licenses (TS-SAL).',
];

const listItems4 = [
  'Allows Windows desktop OS virtual machines (Vista and XP) to be streamed to a local device (e.g. PC or thin client).',
  'For hosting service providers there is a catch. Hardware cannot be shared among customers under the new licensing terms. Each customer organization must have its own dedicated hardware.',
  'Customers must purchase VECD licenses and maintain an agreement directly with Microsoft. Service Providers cannot resell VECD licenses.',
  'Licensing terms make singe-user VDI desktops very expensive as they cannot benefit from service providers’ economies of scale.',
];

const listItems5 = [
  'RDS becomes more robust with great deployment flexibility and scalability.',
  'There are now RDS roles that can be deployed in a highly available, redundant manner to create large deployments with users automatically load-balanced across multiple RD Session Hosts.',
];

const listItems6 = [
  'To address performance challenges and user isolation concerns, this new technology allows each user to have their own dedicated desktop virtual machine.',
  'Windows XP and Vista are supported operating systems with VMware View.',
  'Although the technology and use-cases are strong, Microsoft’s licensing restrictions on multi-tenant hosting environments for desktops make it very expensive due to dedicated hardware requirements.',
];

const listItems7 = [
  'Customers with Software Assurance (SA) can stream desktops from a data centre at no additional charge.',
  'Those without Software Assurance can purchase a per-device VDA (virtual desktop access) license.',
  'Dedicated hardware limitation is still in place, making hosting of dedicated virtual desktops an expensive proposition.',
  'Customers must still purchase the VDA license and have an agreement directly with Microsoft, not the Service Provider.',
];

const listItems8 = [
  'Qualified Multi-tenant Hosters (QMTH) program participants can now host and stream Windows 10 Enterprise desktops (e.g. Windows 7 and 10) without being subject to the dedicated hardware requirement for each customer.',
  'CSP resellers can now purchase Windows 10 Enterprise subscription licenses through the CSP program and resell these licenses to customers as part of their services – maintaining the billing relationship with the customer, unlike with VECD and VDA.',
  'Customers with existing Windows 10 Enterprise with active Software Assurance or Windows 10 Enterprise E3/E5 via CSP can run desktops VMs in Azure and with qualified multi-tenant hosters (QMTH).',
];

const listItems9 = [
  'RDmi represents significant evolution of the RDS product. RDS infrastructure roles (e.g. gateway, connection broken, etc.) are moved from domain-joined VMs to standalone Webapps.',
  'RDmi promises to be a scalable, non-ADDS dependent set of Webapps that will finally enable service providers to cost effectively deliver virtual desktops from a multi-tenant control plane.',
];

const listItems10 = [
  'RDmi project evolves into WVD.',
  'WVD is an Azure hosted, Microsoft managed control plane replacing all RDS infrastructure roles that traditionally run on Windows servers.',
  'New operating system called Windows 10 Enterprise multi-session promises to bring parity to the end-user desktop experience across all physical and virtual Windows 10 devices.',
  'Microsoft acquires FSLogix, a user profile management technology that removes many challenges related to Windows profiles in virtual desktop environments.',
];

const listItems11 = [
  'Licensing virtual desktops is now super simple – all that’s needed is a Windows 10 Enterprise subscription under any licensing program. This license includes the use of WVD management service hosted in Azure and managed by Microsoft at no charge.',
  'Windows 10 Enterprise VMs are used to serve virtual desktops. Pooled and personal desktops are supported.',
  'Desktop VMs run in customer’s Azure subscription and consume storage, compute and networking resources.',
  'WVD is an Azure-only service and so is the Windows 10 Enterprise multi- session operating system. On-premises deployments of virtual desktops still require the use Windows Server operating system (e.g. 2016 and 2019) with RDS licensing.',
  'Newly released Windows Server 2019 no longer supports Office 365 ProPlus – the most popular set of applications running in virtual desktops. Microsoft is clearly signalling to the market that future virtual desktop deployments will be using WVD and running in Azure.',
];

const listItems12 = [
  'Globally Available in 60+ regions',
  'Deployments & Onboarding from 30 min to two hours',
  'Unlimited scalability',
];

const AboutUsBlock2: React.FC = () => {
  const classes = useStyles();
  const mdUp = useMediaQuery('(min-width: 1170px)');

  return (
    <HeroBlock component="div">
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <VerticalTimeline className={classes.timeline}>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={styles.content}
              contentArrowStyle={{ borderRight: '12px solid  #dfdfdf' }}
              date="1998"
              dateClassName={clsx(
                classes.timelineDate,
                classes.timelineDateRight,
                classes.timelineNTLogo,
                !mdUp && classes.timelineDateMobile
              )}
              position="right"
              iconStyle={mdUp ? styles.icons : styles.iconsMobile}>
              <Typography variant="body1" component="p" className={classes.text}>
                {`Our first major challenge was to deploy FoxPro application to 1000 users in multiple locations connected via 64Kbps WAN`}
              </Typography>
              <Typography variant="body1" component="p" className={classes.textBold}>
                <strong>{`Solution - Windows NT 4.0 Terminal Server to the rescue`}</strong>
              </Typography>
              <Typography variant="body1" component="p" className={classes.text}>
                {`Microsoft releases Windows NT 4.0 Terminal Server edition with built-in multi-user expansion module (MultiWin) licensed from Citrix`}
              </Typography>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={styles.content}
              contentArrowStyle={{ borderRight: '12px solid  #dfdfdf' }}
              date="1999"
              dateClassName={clsx(
                classes.timelineDate,
                classes.timeline2KLogo,
                !mdUp && classes.timelineDateMobile
              )}
              position="left"
              iconStyle={mdUp ? styles.icons : styles.iconsMobile}>
              <Typography variant="body1" component="p" className={classes.text}>
                {`Building on our experience and increase demand from customers we’ve migrated all existing thin client deployments to Windows 2000 Server and for the first time utilised WYSE Thin terminals reducing by up to 90% deployment and management time and costs. Major Success!!! It feels somehow that we are reliving this 20 years later, but lets continue with our story.`}
              </Typography>
              <Typography variant="body1" component="p" className={classes.textBold}>
                <strong>{`Windows 2000 Server Family`}</strong>
              </Typography>
              <Typography variant="body1" component="p" className={classes.text}>
                {`Windows 2000 Server Family based on NT is released with multi-user capabilities integrated into the kernel and Terminal Services available as a standard feature.`}
              </Typography>
              <List className={classes.list}>
                {listItems.map((item, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <ListItem className={classes.listItem} key={i}>
                    <ListItemIcon className={classes.listItemIcon}>
                      <FiberManualRecord fontSize="small" htmlColor="#353639" />
                    </ListItemIcon>
                    <ListItemText className={classes.listItemText} primary={item} />
                  </ListItem>
                ))}
              </List>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={styles.content}
              contentArrowStyle={{ borderRight: '12px solid  #dfdfdf' }}
              date="2001"
              dateClassName={clsx(
                classes.timelineDate,
                classes.timelineDateRight,
                classes.timelineXPLogo,
                !mdUp && classes.timelineDateMobile
              )}
              position="right"
              iconStyle={mdUp ? styles.icons : styles.iconsMobile}>
              <Typography variant="body1" component="p" className={classes.text}>
                {`First ADSL connections commercially available and major new innovation by our technical team. Welcome to ASP2001! We have launched our first ASP (Application Service Provider) platform to deliver business applications from our private datacentre based in London Docklands Internet Exchange over public internet (WOW this was exiting). No more WAN bottlenecks and expensive leased line connections. This was also the year of our first Windows 2000 RDP Cluster build on Compaq 1U DL360 & DL380 servers (Do you remember Compaq?)`}
              </Typography>
              <Typography variant="body1" component="p" className={classes.textBold}>
                <strong>{`Windows Server 2003 and Windows XP are launched with improved Terminal Server capabilities`}</strong>
              </Typography>
              <List className={classes.list}>
                {listItems2.map((item, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <ListItem className={classes.listItem} key={i}>
                    <ListItemIcon className={classes.listItemIcon}>
                      <FiberManualRecord fontSize="small" htmlColor="#353639" />
                    </ListItemIcon>
                    <ListItemText className={classes.listItemText} primary={item} />
                  </ListItem>
                ))}
              </List>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={styles.content}
              contentArrowStyle={{ borderRight: '12px solid  #dfdfdf' }}
              date="2003 - 2007"
              dateClassName={clsx(
                classes.timelineDate,
                classes.timelineDateRight,
                classes.timeline2003ServLogo,
                !mdUp && classes.timelineDateMobile
              )}
              position="right"
              iconStyle={mdUp ? styles.icons : styles.iconsMobile}>
              <Typography variant="body1" component="p" className={classes.text}>
                {`Continues development of many proprietary automation tools`}
              </Typography>
              <Typography variant="body1" component="p" className={classes.textBold}>
                <strong>{`Terminal Server based desktop sessions grow in popularity`}</strong>
              </Typography>
              <List className={classes.list}>
                {listItems3.map((item, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <ListItem className={classes.listItem} key={i}>
                    <ListItemIcon className={classes.listItemIcon}>
                      <FiberManualRecord fontSize="small" htmlColor="#353639" />
                    </ListItemIcon>
                    <ListItemText className={classes.listItemText} primary={item} />
                  </ListItem>
                ))}
              </List>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={styles.content}
              contentArrowStyle={{ borderRight: '12px solid  #dfdfdf' }}
              date="2007"
              dateClassName={clsx(
                classes.timelineDate,
                classes.timelineVistaLogo,
                !mdUp && classes.timelineDateMobile
              )}
              position="left"
              iconStyle={mdUp ? styles.icons : styles.iconsMobile}>
              <Typography variant="body1" component="p" className={classes.text}>
                {`Not many changes of the RDS, however for us this was a major milestone for us that accelerated our company growth by a 1000%. Building on our knowledge of networks (private and Internet), managing data canters and high availability clusters we’ve developed our first SIP native IP-Centrex product “Rainbow”. Firs we’ve innovated and transformed application delivery and now we’ve repeated our success by transforming traditional Corporate Voice.`}
              </Typography>
              <Typography variant="body1" component="p" className={classes.textBold}>
                <strong>{`Windows 20Desktop OS streaming with VECD`}</strong>
                <br />
                <strong>{`Microsoft modifies its desktop OS licensing terms with VECD (Vista Enterprise Centralized Desktop)`}</strong>
              </Typography>
              <Typography variant="body1" component="p" className={classes.text}>
                {`Windows 2000 Server Family based on NT is released with multi-user capabilities integrated into the kernel and Terminal Services available as a standard feature.`}
              </Typography>
              <List className={classes.list}>
                {listItems4.map((item, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <ListItem className={classes.listItem} key={i}>
                    <ListItemIcon className={classes.listItemIcon}>
                      <FiberManualRecord fontSize="small" htmlColor="#353639" />
                    </ListItemIcon>
                    <ListItemText className={classes.listItemText} primary={item} />
                  </ListItem>
                ))}
              </List>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={styles.content}
              contentArrowStyle={{ borderRight: '12px solid  #dfdfdf' }}
              date="2008"
              dateClassName={clsx(
                classes.timelineDate,
                classes.timelineDateRight,
                classes.timeline2008Logo,
                !mdUp && classes.timelineDateMobile
              )}
              position="right"
              iconStyle={mdUp ? styles.icons : styles.iconsMobile}>
              <Typography variant="body1" component="p" className={classes.text}>
                {`The BIG BANG! Our Rainbow VoIP goes big. Welcome to the consumer market. Rainbow VoIP and Siemens codevelop the first DECT IP Phone.`}
              </Typography>
              <Typography variant="body1" component="p" className={classes.textBold}>
                <strong>{`Windows Server 2008 is released and Terminal Services is renamed to Remote Desktop Services (RDS)`}</strong>
              </Typography>
              <List className={classes.list}>
                {listItems5.map((item, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <ListItem className={classes.listItem} key={i}>
                    <ListItemIcon className={classes.listItemIcon}>
                      <FiberManualRecord fontSize="small" htmlColor="#353639" />
                    </ListItemIcon>
                    <ListItemText className={classes.listItemText} primary={item} />
                  </ListItem>
                ))}
              </List>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={styles.content}
              contentArrowStyle={{ borderRight: '12px solid  #dfdfdf' }}
              date="2009"
              dateClassName={clsx(
                classes.timelineDate,
                classes.timelineVMWareLogo,
                !mdUp && classes.timelineDateMobile
              )}
              position="left"
              iconStyle={mdUp ? styles.icons : styles.iconsMobile}>
              <Typography variant="body1" component="p" className={classes.text}>
                {`After a year in development we launch our first consumer VoIP offering. The innovation “Fixed Monthly Plan – Unlimited Calls”.`}
                <br />
                <strong>NOTE</strong>
                {`: Remember this part this is still relevant today.`}
                <br />
                {`First month we ship 12,000 units. In addition, the corporate IP-Centrex gets a face lift. Multiple VMware migration projects.`}
              </Typography>
              <Typography variant="body1" component="p" className={classes.textBold}>
                <strong>{`VDI Goes Mainstream`}</strong>
                <br />
                {`VMware introduces VMware View 4.0, a VDI product that allows one-to-one user to desktop assignment`}
              </Typography>
              <List className={classes.list}>
                {listItems6.map((item, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <ListItem className={classes.listItem} key={i}>
                    <ListItemIcon className={classes.listItemIcon}>
                      <FiberManualRecord fontSize="small" htmlColor="#353639" />
                    </ListItemIcon>
                    <ListItemText className={classes.listItemText} primary={item} />
                  </ListItem>
                ))}
              </List>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={styles.content}
              contentArrowStyle={{ borderRight: '12px solid  #dfdfdf' }}
              date="2010"
              dateClassName={clsx(
                classes.timelineDate,
                classes.timelineDateRight,
                !mdUp && classes.timelineDateMobile
              )}
              position="right"
              iconStyle={mdUp ? styles.icons : styles.iconsMobile}>
              <Typography variant="body1" component="p" className={classes.text}>
                {`With strong demand for ADSL we announce partnership with Cable & Wireless to deliver the first SDSL in UK capable of VoIP & IPTV.`}
              </Typography>
              <Typography variant="body1" component="p" className={classes.textBold}>
                <strong>{`Microsoft retires VECD and replaces it with a new license program for streaming desktops`}</strong>
              </Typography>
              <List className={classes.list}>
                {listItems7.map((item, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <ListItem className={classes.listItem} key={i}>
                    <ListItemIcon className={classes.listItemIcon}>
                      <FiberManualRecord fontSize="small" htmlColor="#353639" />
                    </ListItemIcon>
                    <ListItemText className={classes.listItemText} primary={item} />
                  </ListItem>
                ))}
              </List>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={styles.content}
              contentArrowStyle={{ borderRight: '12px solid  #dfdfdf' }}
              date="2012"
              dateClassName={clsx(
                classes.timelineDate,
                classes.timeline2012Logo,
                !mdUp && classes.timelineDateMobile
              )}
              position="left"
              iconStyle={mdUp ? styles.icons : styles.iconsMobile}>
              <Typography variant="body1" component="p" className={classes.text}>
                {`Microsoft releases Windows Server 2012 with significant improvements to RDS scalability and a new management interface via the Server Manager rather than individual administrative tools for each RDS role as in previous versions of Windows Server.`}
              </Typography>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={styles.content}
              contentArrowStyle={{ borderRight: '12px solid  #dfdfdf' }}
              date="2014 - 2016"
              dateClassName={clsx(
                classes.timelineDate,
                classes.timelineDateRight,
                !mdUp && classes.timelineDateMobile
              )}
              position="right"
              iconStyle={mdUp ? styles.icons : styles.iconsMobile}>
              <Typography variant="body1" component="p" className={classes.text}>
                {`Microsoft launches and eventually discontinues a platform-as-a- service (PaaS) product called Azure RemoteApp (ARA) to host RDS RemoteApps in Azure.`}
              </Typography>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={styles.content}
              contentArrowStyle={{ borderRight: '12px solid  #dfdfdf' }}
              date="2016"
              dateClassName={clsx(
                classes.timelineDate,
                classes.timelineAzureHelloLogo,
                !mdUp && classes.timelineDateMobile
              )}
              position="left"
              iconStyle={mdUp ? styles.icons : styles.iconsMobile}>
              <Typography variant="body1" component="p" className={classes.text}>
                <strong>Hello Microsoft Azure and welcome to the cloud</strong>
                {` Rapid migration of all core platforms and customers to the cloud.`}
              </Typography>
              <Typography variant="body1" component="p" className={classes.textBold}>
                {`Microsoft launches Windows 10 Enterprise subscription via CSP`}
              </Typography>
              <List className={classes.list}>
                {listItems8.map((item, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <ListItem className={classes.listItem} key={i}>
                    <ListItemIcon className={classes.listItemIcon}>
                      <FiberManualRecord fontSize="small" htmlColor="#353639" />
                    </ListItemIcon>
                    <ListItemText className={classes.listItemText} primary={item} />
                  </ListItem>
                ))}
              </List>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={styles.content}
              contentArrowStyle={{ borderRight: '12px solid  #dfdfdf' }}
              date="2017"
              dateClassName={clsx(
                classes.timelineDate,
                classes.timelineDateRight,
                classes.timeline10EntLogo,
                !mdUp && classes.timelineDateMobile
              )}
              position="right"
              iconStyle={mdUp ? styles.icons : styles.iconsMobile}>
              <Typography variant="body1" component="p" className={classes.textBold}>
                <strong>{`Microsoft announces Remote Desktop Modern Infrastructure (RDmi) product at Inspire 2017`}</strong>
              </Typography>
              <List className={classes.list}>
                {listItems9.map((item, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <ListItem className={classes.listItem} key={i}>
                    <ListItemIcon className={classes.listItemIcon}>
                      <FiberManualRecord fontSize="small" htmlColor="#353639" />
                    </ListItemIcon>
                    <ListItemText className={classes.listItemText} primary={item} />
                  </ListItem>
                ))}
              </List>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={styles.content}
              contentArrowStyle={{ borderRight: '12px solid  #dfdfdf' }}
              date="2018"
              dateClassName={clsx(
                classes.timelineDate,
                classes.timelineWVDLogo,
                !mdUp && classes.timelineDateMobile
              )}
              position="left"
              iconStyle={mdUp ? styles.icons : styles.iconsMobile}>
              <Typography variant="body1" component="p" className={classes.text}>
                <strong>{`“The Board Meeting” Our bet: Microsoft Azure & WVD.`}</strong>
              </Typography>
              <br />
              <Typography variant="body1" component="p" className={classes.text}>
                <strong>Windows Virtual Desktop (WVD)</strong>
              </Typography>
              <List className={classes.list}>
                {listItems10.map((item, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <ListItem className={classes.listItem} key={i}>
                    <ListItemIcon className={classes.listItemIcon}>
                      <FiberManualRecord fontSize="small" htmlColor="#353639" />
                    </ListItemIcon>
                    <ListItemText className={classes.listItemText} primary={item} />
                  </ListItem>
                ))}
              </List>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={styles.content}
              contentArrowStyle={{ borderRight: '12px solid  #dfdfdf' }}
              date="2019"
              dateClassName={clsx(
                classes.timelineDate,
                classes.timelineDateRight,
                classes.timelineWVD2019Logo,
                !mdUp && classes.timelineDateMobile
              )}
              position="right"
              iconStyle={mdUp ? styles.icons : styles.iconsMobile}>
              <Typography variant="body1" component="p" className={classes.text}>
                <strong>{`Our first WVD pilot projects`}</strong>
              </Typography>
              <br />
              <Typography variant="body1" component="p" className={classes.text}>
                <strong>WVD Public Preview and General Availability</strong>
                <br />
                <strong>
                  Microsoft opens WVD preview to the public and releases Windows 10 Enterprise
                  multi-session
                </strong>
              </Typography>
              <List className={classes.list}>
                {listItems11.map((item, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <ListItem className={classes.listItem} key={i}>
                    <ListItemIcon className={classes.listItemIcon}>
                      <FiberManualRecord fontSize="small" htmlColor="#353639" />
                    </ListItemIcon>
                    <ListItemText className={classes.listItemText} primary={item} />
                  </ListItem>
                ))}
              </List>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={styles.content}
              contentArrowStyle={{ borderRight: '12px solid  #dfdfdf' }}
              date="2020"
              dateClassName={clsx(
                classes.timelineDate,
                classes.timelineWVDLogo,
                classes.timelineVelocityCloud,
                !mdUp && classes.timelineDateMobile
              )}
              position="left"
              iconStyle={mdUp ? styles.icons : styles.iconsMobile}>
              <Typography variant="body1" component="p" className={classes.text}>
                {`March the World stops, and we all go home! #WFH`}
              </Typography>
              <br />
              <Typography variant="body1" component="p" className={classes.text}>
                <strong>Welcome to Velocity Cloud Desktop & #WFA (Work from Anywhere)</strong>
              </Typography>
              <List className={classes.list}>
                {listItems12.map((item, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <ListItem className={classes.listItem} key={i}>
                    <ListItemIcon className={classes.listItemIcon}>
                      <FiberManualRecord fontSize="small" htmlColor="#353639" />
                    </ListItemIcon>
                    <ListItemText className={classes.listItemText} primary={item} />
                  </ListItem>
                ))}
              </List>
            </VerticalTimelineElement>
          </VerticalTimeline>
        </Grid>
      </Grid>
    </HeroBlock>
  );
};

export default AboutUsBlock2;
