import React from 'react';
import { makeStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';
import sectionImg from 'assets/velocity-use-case-img3.png';

const HeroBlock = styled(Box)(({ theme }) => ({
  padding: theme.spacing(6, 0, 4),
  backgroundColor: '#f8f8f8',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(4, 0),
  },
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: theme.breakpoints.values['lg'],
      margin: '0 auto',
      padding: theme.spacing(0, 3),
      color: '#423f3f',
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
    label: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: 'normal',
      color: '#737373',
    },
    title: {
      fontSize: 34,
      fontWeight: 700,
    },
    text: {
      fontSize: 18,
      paddingLeft: 16,
      display: 'inline-block',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
      },
    },
    imageGrid: {
      backgroundImage: `url(${sectionImg})`,
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      [theme.breakpoints.down('sm')]: {
        backgroundImage: 'none',
      },
    },
  })
);

const EnterprisePBXBlock2: React.FC = () => {
  const classes = useStyles();

  return (
    <HeroBlock component="div">
      <Grid container className={classes.root}>
        <Grid item xs={12} md={6} className={classes.imageGrid}></Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle1" component="span" gutterBottom className={classes.text}>
            {`Velocity’s Cloud PBX requires no physical phone lines (PTSN) and allows free calls between the users! Additionally, it provides features like transfer calls, voicemail, call recording, interactive voice menus (IVRs), call queues and other. Even more exciting features like hosting and recording your own video and audio conferences are also available.`}
            <br />
            <br />
            {`The Session Border Controller is a network element deployed to protect SIP based voice over Internet Protocol (VoIP) networks. It is responsible for securing media and voice traffic through it’s encryption capabilities; and also for ensuring stable and uninterrupted communication through all communication channels of the PBX.`}
          </Typography>
        </Grid>
      </Grid>
    </HeroBlock>
  );
};

export default EnterprisePBXBlock2;
