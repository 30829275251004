import React from 'react';
import { makeStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { FiberManualRecord } from '@material-ui/icons';

const HeroBlock = styled(Box)(({ theme }) => ({
  padding: theme.spacing(6, 0),
  backgroundColor: '#f15137',
  marginTop: theme.spacing(2),
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: theme.breakpoints.values['lg'],
      margin: '0 auto',
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
    label: {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: 'normal',
      color: '#f15236',
      marginBottom: 16,
    },
    heading: {
      fontSize: 34,
      fontWeight: 700,
      lineHeight: '36px',
      maxWidth: 1280,
      width: '100%',
      margin: theme.spacing(0, 'auto', 4),
    },
    title: {
      fontWeight: 700,
      fontSize: 20,
      lineHeight: 1.4,
      color: '#423f3f',
    },
    text: {
      fontSize: 18,
      lineHeight: 1.4,
      marginBottom: 16,
    },
    question: {
      fontWeight: 600,
      fontSize: 20,
      lineHeight: 1.4,
      marginBottom: 16,
    },
    textGrid: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(0, 3),
      color: '#fff',
    },
    shieldHeading: {
      color: '#f15236',
      fontSize: 30,
      fontWeight: 800,
      display: 'inline-flex',
      alignItems: 'center',
      alignSelf: 'flex-start',
    },
    list: {
      padding: '24px 0 16px',
      [theme.breakpoints.down('sm')]: {
        padding: '8px 0 32px',
      },
    },
    listItem: {
      paddingTop: 4,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      alignItems: 'flex-start',
    },
    listItemIcon: {
      minWidth: 32,
      minHeight: 24,
      alignItems: 'center',
    },
    listItemText: {
      margin: 0,
    },
  })
);

const listItems = [
  'Best value, lowest cost solution – full feature set with flexible named and concurrent user pricing models',
  'Advanced autoscaling – schedule and event-driven autoscaling helps save on Azure compute and storage costs',
  'Desktop Image management – create, import, manage, and deploy multiple desktop images at scale',
  'Monitoring and dashboards – empower help desk with the tools they need to efficiently support end-users',
  'Manage existing WVD deployments or create new ones – can be layered on top of existing Windows Virtual Desktop environment non-disruptively',
  'Instant deployment and billing – gets billed on the same invoice as CSP Azure Subscription.No need to go through a new procurement process.',
  'Security, compliance and data residency – WVD native, Enterprise-grade Azure application deployed to customer’s own Azure subscription and controlled exclusively by the customer',
  'Zero vendor lock-in – no custom agents, custom client apps, orchestration VMs, or control planes.  Remove without impact on users and infrastructure.',
];

const CloudDesktop4: React.FC = () => {
  const classes = useStyles();

  return (
    <HeroBlock component="div">
      <Grid container className={classes.root}>
        <Grid item xs={12} className={classes.textGrid}>
          <Typography gutterBottom variant="h4" component="h2" className={classes.heading}>
            What makes Velocity Cloud Desktop for WVD better than what is currently available on the
            market?
          </Typography>

          <List className={classes.list}>
            {listItems.map((item, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <ListItem className={classes.listItem} key={i}>
                <ListItemIcon className={classes.listItemIcon}>
                  <FiberManualRecord style={{ fontSize: '.9rem' }} htmlColor="#fff" />
                </ListItemIcon>
                <ListItemText
                  className={classes.listItemText}
                  primary={<span style={{}}>{item}</span>}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </HeroBlock>
  );
};

export default CloudDesktop4;
