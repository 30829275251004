import React from 'react';
import { makeStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { ArrowRight } from '@material-ui/icons';

const HeroBlock = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 0),
}));

const Highlight = styled('a')({
  color: '#f15236',
  fontWeight: 600,
  wordBreak: 'break-word',
  textDecoration: 'none',
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: theme.breakpoints.values['lg'],
      margin: '0 auto',
      padding: theme.spacing(0, 3),
      color: '#423f3f',
    },
    gridLine: {
      borderBottom: '3px solid #4bb1fe',
      paddingBottom: theme.spacing(1),
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
    label: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: 'normal',
      color: '#737373',
    },
    title: {
      fontSize: 28,
      fontWeight: 700,
      marginBottom: 16,
    },
    text: {
      width: '100%',
      fontSize: 18,
      marginBottom: 16,
      display: 'inline-block',
    },
    list: {
      padding: '0 0 16px',
    },
    listItem: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    listItemIcon: {
      minWidth: 32,
    },
    listItemText: {
      margin: 0,
    },
  })
);
const listItems = [
  'Microsoft Edge — Windows',
  'Internet Explorer — Windows',
  'Apple Safari — macOS',
  'Mozilla Firefox — Windows, macOS, Linux',
  'Google Chrome —	Windows, macOS, Linux, Chrome OS',
];

const ResourcesBlock3: React.FC = () => {
  const classes = useStyles();

  return (
    <HeroBlock component="div">
      <Grid container className={classes.root}>
        <Grid item xs={12} className={classes.gridLine}>
          <Typography variant="h4" component="h2" className={classes.title}>
            Connect with the web client
          </Typography>

          <Typography variant="subtitle1" component="p" className={classes.text}>
            {`While any HTML5-capable browser should work, we officially support the following operating systems and browsers.`}
          </Typography>

          <Typography variant="subtitle1" component="p" className={classes.text}>
            <strong>{`SUPPORTED OPERATING SYSTEMS AND BROWSERS`}</strong>
          </Typography>

          <List className={classes.list}>
            {listItems.map((item, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <ListItem className={classes.listItem} key={i}>
                <ListItemIcon className={classes.listItemIcon}>
                  <ArrowRight fontSize="large" htmlColor="#009be3" />
                </ListItemIcon>
                <ListItemText
                  className={classes.listItemText}
                  primary={<span style={{ fontWeight: 600 }}>{item}</span>}
                />
              </ListItem>
            ))}
          </List>

          <Typography variant="subtitle1" component="p" className={classes.text}>
            {`Access remote resources feed`}
          </Typography>
          <Typography variant="subtitle1" component="p" className={classes.text}>
            {`In a browser, navigate to the Azure Resource Manager-integrated version of the Windows Virtual Desktop web client at `}
            <Highlight href="https://rdweb.wvd.microsoft.com/arm/webclient">
              https://rdweb.wvd.microsoft.com/arm/webclient
            </Highlight>
            {` and sign in with your user account.`}
          </Typography>
        </Grid>
      </Grid>
    </HeroBlock>
  );
};

export default ResourcesBlock3;
