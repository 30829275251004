import React from 'react';
import AppTopBar from 'components/common/AppTopBar';
import ContactUsBlock1 from 'components/ContactUs/ContactUsBlock1';
import ContactUsBlock2 from 'components/ContactUs/ContactUsBlock2';
import RequestDemo from 'components/common/RequestDemo';
import Footer from 'components/common/Footer';
import CopyRight from 'components/common/CopyRight';
import AppNavigationBar from 'components/common/AppNavigationBar';
import { styled } from '@material-ui/core/styles';

const ContactUsPage: React.FC = () => (
  <React.Fragment>
    <AppTopBar elevation={0} style={{ zIndex: 1201 }} />
    <AppNavigationBar />
    <ContactUsBlock1 />
    <ContactUsBlock2 />
    <RequestDemo />
    <Footer />
    <CopyRight />
  </React.Fragment>
);
const ContactUs = styled(ContactUsPage)({
  backgroundColor: '#fff',
});

export default ContactUs;
