import React from 'react';
import AppTopBar from 'components/common/AppTopBar';
import AppNavigationBar from 'components/common/AppNavigationBar';
import NotFound from 'components/NotFound';
import Footer from 'components/common/Footer';
import CopyRight from 'components/common/CopyRight';

const NotFoundPage: React.FC = () => (
  <React.Fragment>
    <AppTopBar elevation={0} style={{ zIndex: 1201 }} />
    <AppNavigationBar />
    <NotFound />
    <Footer />
    <CopyRight />
  </React.Fragment>
);

export default NotFoundPage;
