import React from 'react';
import { makeStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';
import sectionImg from 'assets/cloud-pbx.png';

const HeroBlock = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8, 0),
  backgroundColor: '#1a1d20',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(4, 0),
  },
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: theme.breakpoints.values['lg'],
      margin: '0 auto',
      padding: theme.spacing(0, 3),
      color: '#fff',
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
    label: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: 'normal',
      color: '#ddd',
    },
    title: {
      fontSize: 34,
      fontWeight: 700,
    },
    text: {
      fontSize: 18,
      lineHeight: 1.4,
      marginBottom: 16,
    },
    question: {
      fontWeight: 600,
      fontSize: 20,
      lineHeight: 1.4,
      marginBottom: 16,
    },
    textGrid: {
      paddingRight: 32,
      [theme.breakpoints.down('sm')]: {
        paddingRight: 0,
      },
    },
    imageGrid: {
      backgroundImage: `url(${sectionImg})`,
      backgroundSize: 'contain',
      backgroundPosition: 'right top',
      backgroundRepeat: 'no-repeat',
      [theme.breakpoints.down('sm')]: {
        backgroundSize: '90%',
        backgroundPosition: 'center top',
        height: 226,
        marginTop: 16,
      },
    },
  })
);

const EnterprisePBXBlock1: React.FC = () => {
  const classes = useStyles();

  return (
    <HeroBlock component="div">
      <Grid container className={classes.root}>
        <Grid item xs={12} md={6} className={classes.textGrid}>
          <Typography variant="h5" component="span" gutterBottom className={classes.label}>
            Velocity
          </Typography>
          <br />
          <Typography variant="h4" component="h2" gutterBottom className={classes.title}>
            Cloud PBX
          </Typography>
          <br />
          <Typography variant="subtitle1" component="h6" gutterBottom className={classes.question}>
            {`What is Velocity’s Cloud PBX and why is it a good addition to my organization’s capabilities?`}
          </Typography>
          <Typography variant="subtitle1" component="p" gutterBottom className={classes.text}>
            PBX stands for <span style={{ color: '#4bb1fe' }}>Private Branch Exchange</span>
          </Typography>
          <Typography variant="subtitle1" component="p" gutterBottom className={classes.text}>
            {`This is a private telephone network used within a company or organization. Users of a PBX phone system can communicate internally, within their company, as well as externally with the outside world, using variety of communication channels.`}
          </Typography>
          <Typography variant="subtitle1" component="p" gutterBottom className={classes.text}>
            {`Modern PBX deployment include three major components – the PBX system, Session Border Controller (SBC) and the phones.`}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} className={classes.imageGrid}></Grid>
      </Grid>
    </HeroBlock>
  );
};

export default EnterprisePBXBlock1;
