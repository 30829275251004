export default {
  palette: {
    primary: {
      main: '#4bb1fe',
      contrastText: '#fff',
    },
    secondary: {
      main: '#f15236',
      contrastText: '#fff',
    },
    background: { default: '#fff' },
  },
  typography: {
    fontFamily: [
      '"Source Sans Pro"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  AppBarHeight: 40,
  AppBarHeightSticky: 40,
  AppBarHeightMobile: 40,
  AppBarHeightStickyMobile: 40,
  MainNavHeight: 72,
  MainNavHeightMobile: 64,
};
