import React from 'react';
import { makeStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';
import sectionImg from 'assets/UseCaseMedicalBlock1.png';

const HeroBlock = styled(Box)({
  background: '#fff',
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: theme.breakpoints.values['lg'],
      margin: '0 auto',
      color: '#423f3f',
      padding: theme.spacing(8, 3, 2),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(4, 3, 2),
      },
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
    label: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: 'normal',
      color: '#737373',
    },
    title: {
      fontSize: 34,
      fontWeight: 700,
    },
    text: {
      fontSize: 18,
      display: 'inline-block',
      marginBottom: 16,
    },
    imgGrid: {
      backgroundImage: `url(${sectionImg})`,
      backgroundSize: 'contain',
      backgroundPosition: 'top',
      backgroundRepeat: 'no-repeat',
      backgroundColor: '#fff',
      order: 3,
      [theme.breakpoints.down('sm')]: {
        backgroundSize: 'cover',
        marginBottom: 24,
        minHeight: 220,
        order: 2,
      },
    },
    textGrid: {
      paddingRight: 24,
      order: 2,
      [theme.breakpoints.down('sm')]: {
        paddingRight: 0,
        order: 3,
      },
    },
  })
);

const UseCaseMedicalBlock1: React.FC = () => {
  const classes = useStyles();

  return (
    <HeroBlock component="div" id="VelocitySolutions">
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Typography variant="h5" component="span" className={classes.label} gutterBottom>
            Velocity Use Case
          </Typography>
          <br />

          <Typography variant="h4" component="h2" className={classes.title} gutterBottom>
            Maximizing healthcare efficiency with Velocity Cloud Desktop Premium and IGEL Thin
            Clients
          </Typography>
          <br />
        </Grid>

        <Grid item xs={12} md={6} className={classes.textGrid}>
          <Typography variant="body1" component="span" className={classes.text}>
            {`Give medical staff fast, secure and convenient access to patient data on Velocity Cloud Desktops. Medical data comes in large datasets as there is a host of individual characteristics and regulations required for each type of medical record. As an IT professional in healthcare, you are likely challenged with ensuring that medical staff have a view of private healthcare information in a secure fashion across the patient’s continuum of care.`}
          </Typography>
          <Typography variant="body1" component="span" className={classes.text}>
            {`The shift to digitized medical records and demand for ‘anywhere access’ from a variety of devices ensures clinicians receive more convenient and easy access to patient information from wherever they are; from a lab to a patient’s room. Providing the ability to access applications, share records, issue prescriptions, and review lab results quickly and securely, ultimately improves the quality of patient care.`}
          </Typography>
          <Typography variant="body1" component="span" className={classes.text}>
            {`The adoption of end user computing including desktop virtualization (VDI) in healthcare is steadily increasing. VDI enables desktop access from multiple locations while offering the high availability and critical performance that is demanded by medical applications.`}
          </Typography>
        </Grid>

        <Grid item xs={12} md={6} className={classes.imgGrid}></Grid>
      </Grid>
    </HeroBlock>
  );
};

export default UseCaseMedicalBlock1;
