import React from 'react';
import { makeStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';
import sectionImg from 'assets/velocity-use-case-img3.png';

const HeroBlock = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 0, 8),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(4, 0),
  },
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: theme.breakpoints.values['lg'],
      margin: '0 auto',
      padding: theme.spacing(0, 3),
      color: '#423f3f',
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
    label: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: 'normal',
      color: '#737373',
    },
    title: {
      fontSize: 34,
      fontWeight: 700,
    },
    text: {
      fontSize: 18,
      paddingRight: 16,
      display: 'inline-block',
    },

    imageGrid: {
      backgroundImage: `url(${sectionImg})`,
      backgroundSize: 'contain',
      backgroundPosition: 'right center',
      backgroundRepeat: 'no-repeat',
      [theme.breakpoints.down('sm')]: {
        backgroundImage: 'none',
      },
    },
  })
);

const UseCaseFinancialBlock3: React.FC = () => {
  const classes = useStyles();

  return (
    <HeroBlock component="div">
      <Grid container className={classes.root}>
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle1" component="span" gutterBottom className={classes.text}>
            {`We installed pilot schemes in 3 different locations with the major providers such as Amazon, Google & Microsoft which all fulfilled some of our requirements for remote working, although we discovered our security and compliant telecoms would require separate systems from other providers. We were introduced to Velocity EU by one of our ICT department mid-2020 and decided to open a dialogue around the integrated offering of the Cloud Virtual Desktop with integrated PBX.`}
            <br />
            <br />
            {`Having completed a pilot, we found that utilising our existing Azure infrastructure, integration of the Velocity Cloud Desktop was a relatively simple process. The Velocity Corporate PBX has more functionality than our existing one, which was an unforeseen bonus as were the surprisingly low running costs compared to the competition.`}
            <br />
            <br />
            {`Velocity EU are currently providing services in 5 Countries on our behalf and we look forward to rolling out the desktop across our network.`}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} className={classes.imageGrid}></Grid>
      </Grid>
    </HeroBlock>
  );
};

export default UseCaseFinancialBlock3;
