import React from 'react';
import { makeStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';
import sectionImg1 from 'assets/Velocity-Home_07.jpg';
import sectionImg2 from 'assets/Velocity-Home_09.jpg';
import sectionImg3 from 'assets/Velocity-Home_111.jpg';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';

const HeroBlock = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3, 1, 0),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const CTA = styled(Button)({
  background: '#f15236',
  color: '#fff',
  fontWeight: 600,
  fontSize: 18,
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
  padding: '12px 20px',
  borderRadius: 0,
  position: 'relative',
  marginLeft: '20px',
  '&:hover': {
    background: '#ff0f0f',
    '&::before': { background: '#ff0f0f' },
    '&::after': { background: '#ff0f0f' },
  },
  '&::before': {
    width: 40,
    content: '""',
    display: 'block',
    background: '#f15236',
    position: 'absolute',
    left: '-20px',
    top: '0',
    bottom: '0',
    right: 'auto',
    borderRadius: '50%',
    transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  '&::after': {
    width: 40,
    content: '""',
    display: 'block',
    background: '#f15236',
    position: 'absolute',
    right: '-20px',
    top: '0',
    bottom: '0',
    left: 'auto',
    borderRadius: '50%',
    transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(0, 'auto'),
      maxWidth: theme.breakpoints.values['lg'],
      justifyContent: 'space-between',
    },
    card: {
      maxWidth: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      backgroundColor: '#f8f8f8',
      borderRadius: 0,
      transition: 'background .1s linear',
      margin: theme.spacing(0, 1.5),
      '&:hover': {
        backgroundColor: '#eee',
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(3),
        height: 'auto',
      },
    },
    cardContent: {
      padding: theme.spacing(2),
    },
    cardActions: {
      padding: '8px 16px 24px',
    },
    media: {
      height: 280,
    },
    cardActionArea: {
      '&:hover .MuiCardActionArea-focusHighlight': {
        opacity: 0,
      },
    },
    title: {
      fontSize: 34,
      fontWeight: 700,
      lineHeight: '36px',
      marginBottom: 24,
    },
    text: {
      fontSize: 18,
    },
  })
);

const MediaCardsBB: React.FC<any> = (props: any) => {
  const classes = useStyles();

  const CardComponent = ({
    title,
    text,
    img,
    btnLabel = 'Order Online',
    url,
  }: {
    title: string;
    text: string;
    img: string;
    btnLabel?: string;
    url?: string;
  }) => (
    <Card elevation={0} className={classes.card}>
      <CardActionArea className={classes.cardActionArea}>
        <CardMedia className={classes.media} image={img} title={title} />
        <CardContent className={classes.cardContent}>
          <Typography gutterBottom variant="h5" component="h2" className={classes.title}>
            {title}
          </Typography>
          <Typography variant="subtitle1" component="span" className={classes.text}>
            {text}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions className={classes.cardActions}>
        <CTA href={url} variant="contained" disableElevation>
          {btnLabel}
        </CTA>
      </CardActions>
    </Card>
  );

  return (
    <HeroBlock component="div" {...props}>
      <Grid container className={classes.root}>
        <Grid item xs={12} md={4}>
          <CardComponent
            title="Network Devices"
            text="Reliable Network connectivity is critical for the success of your transition to the cloud. We offer reliable, tested and fully configured range of POE Switches, WiFi Access Controller &amp; WiFi Access Points."
            img={sectionImg1}
            url="https://store.velocity-eu.com/"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CardComponent
            title="PBX Accessories"
            text="Velocity IP3 and Velocity IP5 VoIP phones are fully integrated with our Cloud PBX Platform. Velocity SBC (Session Border Controller) seamlessly resolves most common network related VoIP issues."
            img={sectionImg2}
            url="https://store.velocity-eu.com/"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CardComponent
            title="Hardware Leasing"
            text="Velocity Flexible Leasing Solutions For Businesses in Need of PCs and network infrastructure. Pure-Rental &amp; Flexi-Lease. Protect Your Cash-Flow During Uncertain Times With Safer Leasing Options. Return Retain Renew. Next Day Delivery. Credit
            for StartUps."
            img={sectionImg3}
            btnLabel="Get a Quote"
            url="/#VelocityRequestQuote"
          />
        </Grid>
      </Grid>
    </HeroBlock>
  );
};

export default MediaCardsBB;
