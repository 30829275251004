import React from 'react';
import { makeStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import { Box, Grid, List, ListItem, ListItemText, Paper, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const HeroBlock = styled(Box)(({ theme }) => ({
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  padding: theme.spacing(10, 4, 2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '32px auto',
      maxWidth: theme.breakpoints.values['lg'],
      justifyContent: 'space-between',
    },
    paperBox: {
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
      overflow: 'hidden',
      height: '100%',
    },
    header: { padding: '12px 0 16px', background: '#c4462f', textAlign: 'center' },
    price: {
      padding: '12px 0 16px',
      background: '#fff',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      borderBottom: '1px solid #ddd',
      boxShadow: '0px 1px 2px #ddd',
      zIndex: 1,
      position: 'relative',
    },
    content: {
      padding: '12px 0 16px',
      background: '#fff',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
    },
  })
);

const HelpDeskSupport: React.FC = () => {
  const classes = useStyles();

  const ListItemRow = ({
    primary,
    secondary,
    disabled,
  }: {
    primary: string;
    secondary?: string;
    disabled?: boolean;
  }) => (
    <ListItem component="div" style={{ paddingTop: 0, paddingBottom: 4 }}>
      <ListItemText
        style={{ paddingLeft: 28, position: 'relative' }}
        primary={
          <>
            {!disabled && (
              <CheckCircleIcon
                fontSize="small"
                style={{ color: '#00d07d', position: 'absolute', top: 2, left: 0 }}
              />
            )}
            <Typography
              variant="body1"
              component="span"
              style={{ fontWeight: 600, color: disabled ? '#a3b3ba' : '#423f3f' }}>
              {primary}
            </Typography>
          </>
        }
        secondary={
          secondary && (
            <Typography variant="body2" component="p" style={{ fontWeight: 600, color: '#6d8795' }}>
              {secondary}
            </Typography>
          )
        }
      />
    </ListItem>
  );

  return (
    <HeroBlock component="div" id="help-desk-and-support">
      <Typography
        gutterBottom
        variant="h4"
        component="h4"
        style={{ fontWeight: 600, color: '#423f3f' }}>
        Help Desk and Support
      </Typography>
      <Grid container className={classes.root} spacing={3}>
        <Grid item xs={12} md={4}>
          <Paper elevation={4} className={classes.paperBox}>
            <Box className={classes.header}>
              <Typography variant="h5" component="h5" style={{ fontWeight: 600, color: '#fff' }}>
                Work/Life Balance
              </Typography>
            </Box>
            <Box className={classes.price}>
              <Typography
                variant="h5"
                component="span"
                style={{ fontWeight: 600, color: '#c4462f' }}>
                GBP 10.00
              </Typography>
              <Typography
                variant="body2"
                component="span"
                style={{ fontWeight: 600, color: '#6d8795' }}>
                Per user per month
              </Typography>
            </Box>
            <Box className={classes.content}>
              <List component="div" style={{ paddingTop: 0, paddingBottom: 0, width: '100%' }}>
                <ListItemRow primary={`Support channels: Phone, email & chat`} />
                <ListItemRow primary={`Support hours: Weekdays, 8am-5pm US local time`} />
                <ListItemRow primary={`Self-service admin portal`} />
                <ListItemRow primary={`Severity 1 response & resolution: 2hrs/8hrs`} />
                <ListItemRow primary={`Authorized points of contact: One`} />
              </List>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper elevation={4} className={classes.paperBox}>
            <Box className={classes.header}>
              <Typography variant="h5" component="h5" style={{ fontWeight: 600, color: '#fff' }}>
                Productivity
              </Typography>
            </Box>
            <Box className={classes.price}>
              <Typography
                variant="h5"
                component="span"
                style={{ fontWeight: 600, color: '#c4462f' }}>
                GBP 20.00
              </Typography>
              <Typography
                variant="body2"
                component="span"
                style={{ fontWeight: 600, color: '#678190' }}>
                Per user per month
              </Typography>
            </Box>
            <Box className={classes.content}>
              <List component="div" style={{ paddingTop: 0, paddingBottom: 0, width: '100%' }}>
                <ListItemRow primary={`Support channels: Phone, email & chat`} />
                <ListItemRow primary={`Support hours: Weekdays, 7am-7pm US local time`} />
                <ListItemRow primary={`Self-service admin portal`} />
                <ListItemRow primary={`Severity 1 response & resolution: 1hrs/4hrs`} />
                <ListItemRow primary={`Authorized points of contact: Two`} />
              </List>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper elevation={4} className={classes.paperBox}>
            <Box className={classes.header}>
              <Typography variant="h5" component="h5" style={{ fontWeight: 600, color: '#fff' }}>
                Workaholic
              </Typography>
            </Box>
            <Box className={classes.price}>
              <Typography
                variant="h5"
                component="span"
                style={{ fontWeight: 600, color: '#c4462f' }}>
                GBP 30.00
              </Typography>
              <Typography
                variant="body2"
                component="span"
                style={{ fontWeight: 600, color: '#678190' }}>
                Per user per month
              </Typography>
            </Box>
            <Box className={classes.content}>
              <List component="div" style={{ paddingTop: 0, paddingBottom: 0, width: '100%' }}>
                <ListItemRow primary={`Support channels: Phone, email & chat`} />
                <ListItemRow primary={`Support hours: 24/7/365`} />
                <ListItemRow primary={`Self-service admin portal`} />
                <ListItemRow primary={`Severity 1 response & resolution: 15min/2hrs`} />
                <ListItemRow primary={`Authorized points of contact: Five`} />
              </List>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </HeroBlock>
  );
};

export default HelpDeskSupport;
