import React from 'react';
import AppTopBar from 'components/common/AppTopBar';
import VelocityEndpointHw from 'components/VelocityEndpointHw';
import GetStartedToday from 'components/GetStartedToday';
import MediaCardsBB from 'components/MediaCardsBB';
import Footer from 'components/common/Footer';
import CopyRight from 'components/common/CopyRight';
import AppNavigationBar from 'components/common/AppNavigationBar';

const Pricing: React.FC = () => (
  <React.Fragment>
    <AppTopBar elevation={0} style={{ zIndex: 1201 }} />
    <AppNavigationBar />
    <VelocityEndpointHw />
    <GetStartedToday />
    <MediaCardsBB />
    <Footer />
    <CopyRight />
  </React.Fragment>
);

export default Pricing;
