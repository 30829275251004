import React from 'react';
import { makeStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';

const HeroBlock = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 0),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: theme.breakpoints.values['lg'],
      margin: '16px auto',
      padding: theme.spacing(1, 2),
      flexDirection: 'column',
      justifyContent: 'center',
    },
  })
);

const ThankYou: React.FC = () => {
  const classes = useStyles();

  return (
    <HeroBlock component="div">
      <Grid container className={classes.root}>
        <Typography gutterBottom variant="h2" component="h2">
          Thank you for getting in touch!
        </Typography>
        <Typography gutterBottom variant="body1" component="p">
          We appreciate you contacting us here at Velocity EU.
        </Typography>
        <Typography gutterBottom variant="body1" component="p">
          One of our colleagues will be in touch with you within 24 hours.
        </Typography>
        <Typography gutterBottom variant="h5" component="p">
          Have a great day!
        </Typography>
      </Grid>
    </HeroBlock>
  );
};

export default ThankYou;
