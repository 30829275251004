import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Grid,
  Typography,
  MenuItem,
  MenuList,
  List,
  ListItem,
  ListItemText,
  useTheme,
  Hidden,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useMediaQuery,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import FacebookIcon, { InstagramIcon } from './SvgIcons';
import { scrollToElement } from 'common/helpers';
import logoTransparent from 'assets/Velocity-Logo-white-orange-transparent.png';

const HeroBlock = styled(Box)(({ theme }) => ({
  background: '#1a1d20',
  color: '#fff',
  padding: theme.spacing(2, 0),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2),
  },
}));

const NavLink = styled(Button)(({ theme }) => ({
  textTransform: 'capitalize',
  color: 'inherit',
  borderRadius: 0,
  borderRight: '1px solid #999',
  '&:last-of-type': {
    borderRight: 'none',
  },
  [theme.breakpoints.down('xs')]: {
    border: 'none',
    padding: '0 8px',
    width: '100%',
  },
}));

const FooterLogo = styled(Button)({
  position: 'absolute',
  left: 0,
  top: '50%',
  transform: 'translateY(-50%)',
  padding: 0,
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: theme.breakpoints.values['lg'],
      margin: '0 auto',
      color: '#999',
      borderTop: '1px solid #999',
    },
    copyrightBlock: {
      padding: theme.spacing(2, 0, 0),
      [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
      },
    },
    copyrightText: {
      display: 'inline-flex',
      marginLeft: 'auto',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
        width: '100%',
        paddingTop: theme.spacing(3),
      },
    },
    iconBox: {
      display: 'inline-flex',
      width: '44px',
      height: '44px',
      background: '#fff',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      marginRight: theme.spacing(1.5),
      padding: 0,
      minWidth: 'auto',
      '&:hover': {
        background: '#aaa',
      },
    },
    footerLinks: {
      [theme.breakpoints.down('md')]: {
        marginLeft: 'auto',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        paddingTop: theme.spacing(3),
      },
    },
    menuList: {
      display: 'flex',
      alignItems: 'center',
      marginRight: 'auto',
      flexWrap: 'wrap',
      padding: 0,
      height: '100%',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
    menuItem: {
      padding: '0 8px',
    },
    columnHeading: {
      fontWeight: 600,
      textTransform: 'uppercase',
      color: '#eee',
    },
    columnList: {},
    columnItem: {},
    padding: {
      padding: theme.spacing(4, 0, 2),
    },
    resourceLink: {
      padding: 0,
      textAlign: 'left',
      color: '#999',
      '& .MuiListItemText-primary': {
        fontSize: 15,
      },
    },
    list: {
      paddingTop: 0,
    },
    accordion: {
      marginBottom: 8,
      background: 'none',
      '&.Mui-disabled': {
        background: 'none',
      },
      [theme.breakpoints.down('xs')]: {
        marginBottom: 16,
      },
    },
    accordionSummary: {
      padding: 0,
      minHeight: 'auto',
      [theme.breakpoints.up('sm')]: {
        minHeight: 'auto',
      },
      '&.Mui-disabled': {
        opacity: 1,
      },
      '&.Mui-expanded': {
        minHeight: 'inherit',
      },
      '& .MuiAccordionSummary-content': {
        margin: '0 0 8px',
      },
      '& .MuiAccordionSummary-expandIcon': {
        padding: '0 8px',
      },
    },
    accordionDetails: {
      padding: '0 0 12px',
    },
  })
);

const LogoWrap = styled('div')({
  display: 'flex',
  alignItems: 'center',
  height: '48px',
});

const SectionImg = styled('img')({
  width: 'auto',
  height: '100%',
  maxHeight: '100%',
  maxWidth: '100%',
});

const Footer: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));
  const [expandSolutions, setExpandSolutions] = useState(smUp);
  const [expandStore, setExpandStore] = useState(smUp);
  const [expandCases, setExpandCases] = useState(smUp);
  const [expandResources, setExpandResources] = useState(smUp);
  const [expandHelp, setExpandHelp] = useState(smUp);
  const [expandAccount, setExpandAccount] = useState(smUp);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      if (smUp) {
        setExpandSolutions(true);
        setExpandStore(true);
        setExpandCases(true);
        setExpandResources(true);
        setExpandHelp(true);
        setExpandAccount(true);
      } else {
        setExpandSolutions(false);
        setExpandStore(false);
        setExpandCases(false);
        setExpandResources(false);
        setExpandHelp(false);
        setExpandAccount(false);
      }
    }

    // Don't update the state on an unmounted component
    return function cleanup() {
      mounted = false;
    };
  }, [smUp]);

  return (
    <HeroBlock component="div" id="VelocityFooter">
      <Grid
        container
        className={classes.root}
        style={{
          border: 'none',
          margin: theme.spacing(1, 0, 3),
          position: 'relative',
        }}
        justify="center">
        <Button fullWidth onClick={() => scrollToElement('VelocityRequestQuote')}>
          <Typography variant="h3" component="h2" style={{ fontWeight: 600, color: '#eee' }}>
            Contact Us
          </Typography>
        </Button>
        <Hidden smDown>
          <FooterLogo href="/#top">
            <LogoWrap>
              <SectionImg src={logoTransparent} alt="Velocity Logo" />
            </LogoWrap>
          </FooterLogo>
        </Hidden>
      </Grid>

      <Grid container className={clsx(classes.root, classes.padding)}>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Accordion
            defaultExpanded={smUp}
            expanded={expandSolutions}
            disabled={smUp}
            elevation={0}
            className={classes.accordion}>
            <AccordionSummary
              className={classes.accordionSummary}
              expandIcon={!smUp && <ExpandMore htmlColor="#fff" />}
              onClick={() => setExpandSolutions(!expandSolutions)}>
              <Typography variant="body1" component="p" className={classes.columnHeading}>
                Solutions
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <List dense className={classes.list}>
                <ListItem disableGutters>
                  <NavLink href="/cloud-desktop" className={classes.resourceLink}>
                    <ListItemText primary="Velocity Cloud Desktop" />
                  </NavLink>
                </ListItem>
                <ListItem disableGutters>
                  <NavLink href="/cloud-desktop-premium" className={classes.resourceLink}>
                    <ListItemText primary="Velocity Cloud Desktop Premium" />
                  </NavLink>
                </ListItem>
                <ListItem disableGutters>
                  <NavLink href="/enterprise-pbx" className={classes.resourceLink}>
                    <ListItemText primary="Velocity Enterprise PBX" />
                  </NavLink>
                </ListItem>
                <ListItem disableGutters>
                  <NavLink
                    className={classes.resourceLink}
                    onClick={() => scrollToElement('CloudDesktopPremium')}>
                    <ListItemText primary="Velocity Cloud Connections" />
                  </NavLink>
                </ListItem>
                <ListItem disableGutters>
                  <NavLink href="/deployment-scenarios" className={classes.resourceLink}>
                    <ListItemText primary="Velocity Deployment Scenarios" />
                  </NavLink>
                </ListItem>
              </List>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Accordion
            defaultExpanded={smUp}
            expanded={expandStore}
            disabled={smUp}
            elevation={0}
            className={classes.accordion}>
            <AccordionSummary
              className={classes.accordionSummary}
              expandIcon={!smUp && <ExpandMore htmlColor="#fff" />}
              onClick={() => setExpandStore(!expandStore)}>
              <Typography variant="body1" component="p" className={classes.columnHeading}>
                Store
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <List dense className={classes.list}>
                <ListItem disableGutters>
                  <NavLink className={classes.resourceLink} href="https://store.velocity-eu.com/">
                    <ListItemText primary="Cloud Endpoint Hardware" />
                  </NavLink>
                </ListItem>
                <ListItem disableGutters>
                  <NavLink className={classes.resourceLink} href="https://store.velocity-eu.com/">
                    <ListItemText primary="Network Devices" />
                  </NavLink>
                </ListItem>
                <ListItem disableGutters>
                  <NavLink className={classes.resourceLink} href="https://store.velocity-eu.com/">
                    <ListItemText primary="PBX Accessories" />
                  </NavLink>
                </ListItem>
              </List>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Accordion
            defaultExpanded={smUp}
            expanded={expandCases}
            disabled={smUp}
            elevation={0}
            className={classes.accordion}>
            <AccordionSummary
              className={classes.accordionSummary}
              expandIcon={!smUp && <ExpandMore htmlColor="#fff" />}
              onClick={() => setExpandCases(!expandCases)}>
              <Typography variant="body1" component="p" className={classes.columnHeading}>
                Use Cases
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <List dense className={classes.list}>
                <ListItem disableGutters>
                  <NavLink href="/use-case-еducation" className={classes.resourceLink}>
                    <ListItemText primary="Education" />
                  </NavLink>
                </ListItem>
                <ListItem disableGutters>
                  <NavLink href="/use-case-medical" className={classes.resourceLink}>
                    <ListItemText primary="Medical" />
                  </NavLink>
                </ListItem>
                <ListItem disableGutters>
                  <NavLink href="/use-case-engineering" className={classes.resourceLink}>
                    <ListItemText primary="Engineering" />
                  </NavLink>
                </ListItem>
                <ListItem disableGutters>
                  <NavLink href="/use-case-financial" className={classes.resourceLink}>
                    <ListItemText primary="Financial" />
                  </NavLink>
                </ListItem>
              </List>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Accordion
            defaultExpanded={smUp}
            expanded={expandResources}
            disabled={smUp}
            elevation={0}
            className={classes.accordion}>
            <AccordionSummary
              className={classes.accordionSummary}
              expandIcon={!smUp && <ExpandMore htmlColor="#fff" />}
              onClick={() => setExpandResources(!expandResources)}>
              <Typography variant="body1" component="p" className={classes.columnHeading}>
                Resources (WVD Client)
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <List dense className={classes.list}>
                <ListItem disableGutters>
                  <NavLink className={classes.resourceLink} href="/resources">
                    <ListItemText primary="Desktop" />
                  </NavLink>
                </ListItem>
                <ListItem disableGutters>
                  <NavLink className={classes.resourceLink} href="/resources">
                    <ListItemText primary="Web" />
                  </NavLink>
                </ListItem>
                <ListItem disableGutters>
                  <NavLink className={classes.resourceLink} href="/resources">
                    <ListItemText primary="Mobile" />
                  </NavLink>
                </ListItem>
              </List>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Accordion
            defaultExpanded={smUp}
            expanded={expandHelp}
            disabled={smUp}
            elevation={0}
            className={classes.accordion}>
            <AccordionSummary
              className={classes.accordionSummary}
              expandIcon={!smUp && <ExpandMore htmlColor="#fff" />}
              onClick={() => setExpandHelp(!expandHelp)}>
              <Typography variant="body1" component="p" className={classes.columnHeading}>
                Company information
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <List dense className={classes.list}>
                <ListItem disableGutters>
                  <NavLink className={classes.resourceLink} href="/about-us">
                    <ListItemText primary="About Velocity EU" />
                  </NavLink>
                </ListItem>
                <ListItem disableGutters>
                  <NavLink className={classes.resourceLink} href="/contact-us">
                    <ListItemText primary="Global offices" />
                  </NavLink>
                </ListItem>
                <ListItem disableGutters>
                  <NavLink className={classes.resourceLink} href="https://support.velocity-eu.com/">
                    <ListItemText primary="Get Help" />
                  </NavLink>
                </ListItem>
              </List>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Accordion
            defaultExpanded={smUp}
            expanded={expandAccount}
            disabled={smUp}
            elevation={0}
            className={classes.accordion}>
            <AccordionSummary
              className={classes.accordionSummary}
              expandIcon={!smUp && <ExpandMore htmlColor="#fff" />}
              onClick={() => setExpandAccount(!expandAccount)}>
              <Typography variant="body1" component="p" className={classes.columnHeading}>
                My Account
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <List dense className={classes.list}>
                <ListItem disableGutters>
                  <NavLink
                    className={classes.resourceLink}
                    href="https://books.velocity-eu.com/portal/velocityeu/login">
                    <ListItemText primary="My Orders" />
                  </NavLink>
                </ListItem>
                <ListItem disableGutters>
                  <NavLink
                    className={classes.resourceLink}
                    href="https://store.velocity-eu.com/signin">
                    <ListItemText primary="My Velocity Store" />
                  </NavLink>
                </ListItem>
                <ListItem disableGutters>
                  <NavLink
                    className={classes.resourceLink}
                    href="https://subscriptions.zoho.eu/portal/velocityeu">
                    <ListItemText primary="My Subscriptions" />
                  </NavLink>
                </ListItem>
                <ListItem disableGutters>
                  <NavLink
                    className={classes.resourceLink}
                    href="https://support.velocity-eu.com/portal/en/signin">
                    <ListItemText primary="My Help Desk" />
                  </NavLink>
                </ListItem>
                <ListItem disableGutters>
                  <NavLink className={classes.resourceLink} href="https://vapp.adminportal.pro/">
                    <ListItemText primary="My Control Panel" />
                  </NavLink>
                </ListItem>
              </List>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>

      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Grid container className={classes.copyrightBlock}>
            <Button
              className={classes.iconBox}
              target="_blank"
              href="https://www.facebook.com/velocitywvd">
              <FacebookIcon htmlColor="#f15236" />
            </Button>
            <Button
              className={classes.iconBox}
              target="_blank"
              href="https://www.instagram.com/velocitywvd">
              <InstagramIcon htmlColor="#f15236" />
            </Button>
            <Box className={classes.footerLinks}>
              <MenuList variant="menu" className={classes.menuList}>
                <NavLink className={classes.menuItem} href="/terms">
                  <MenuItem className={classes.menuItem}>Terms and Conditions</MenuItem>
                </NavLink>
                <NavLink className={classes.menuItem} href="/privacy-policy">
                  <MenuItem className={classes.menuItem}>Privacy Policy</MenuItem>
                </NavLink>
                <NavLink className={classes.menuItem} href="/quality-policy">
                  <MenuItem className={classes.menuItem}>Quality Policy</MenuItem>
                </NavLink>
                <NavLink className={classes.menuItem} href="/environmental-policy">
                  <MenuItem className={classes.menuItem}>Environmental Policy</MenuItem>
                </NavLink>
                <NavLink className={classes.menuItem} href="/health-safety-policy">
                  <MenuItem className={classes.menuItem}>Health and Safety Policy</MenuItem>
                </NavLink>
              </MenuList>
            </Box>
            <Box component="span" className={classes.copyrightText}>
              <Typography variant="body1" component="p">
                {`© Velocity EU Inc.`}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </HeroBlock>
  );
};

export default Footer;
