import React from 'react';
import { makeStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import { Box, Grid, Typography, Button } from '@material-ui/core';
import sectionImg from 'assets/Velocity-Home_03.png';

const HeroBlock = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8, 0),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(4, 0),
  },
}));

const FlexBox = styled('div')({
  display: 'flex',
});

const SectionActions = styled(FlexBox)(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

const CTA = styled(Button)({
  background: '#f15236',
  color: '#fff',
  fontWeight: 600,
  fontSize: 18,
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
  padding: '12px 20px',
  borderRadius: 0,
  position: 'relative',
  marginLeft: '20px',
  '&:hover': {
    background: '#ff0f0f',
    '&::before': { background: '#ff0f0f' },
    '&::after': { background: '#ff0f0f' },
  },
  '&::before': {
    width: 40,
    content: '""',
    display: 'block',
    background: '#f15236',
    position: 'absolute',
    left: '-20px',
    top: '0',
    bottom: '0',
    right: 'auto',
    borderRadius: '50%',
    transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  '&::after': {
    width: 40,
    content: '""',
    display: 'block',
    background: '#f15236',
    position: 'absolute',
    right: '-20px',
    top: '0',
    bottom: '0',
    left: 'auto',
    borderRadius: '50%',
    transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: theme.breakpoints.values['lg'],
      margin: '0 auto',
      padding: theme.spacing(0, 3),
      color: '#423f3f',
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
    label: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: 'normal',
      color: '#737373',
    },
    title: {
      fontSize: 34,
      fontWeight: 700,
    },
    text: {
      fontSize: 18,
    },

    imageGrid: {
      backgroundImage: `url(${sectionImg})`,
      backgroundSize: 'contain',
      backgroundPosition: 'right center',
      backgroundRepeat: 'no-repeat',
      [theme.breakpoints.down('sm')]: {
        backgroundImage: 'none',
      },
    },
  })
);

const VelocityEndpointHw: React.FC = () => {
  const classes = useStyles();

  return (
    <HeroBlock component="div">
      <Grid container className={classes.root}>
        <Grid item xs={12} md={6}>
          <Typography variant="h5" component="span" gutterBottom className={classes.label}>
            Velocity
          </Typography>
          <br />

          <Typography variant="h4" component="h2" gutterBottom className={classes.title}>
            Cloud Endpoint Hardware
          </Typography>
          <br />

          <Typography variant="subtitle1" component="span" gutterBottom className={classes.text}>
            {`Velocity’s range of specially selected, fully integrated hardware devices. Suitable for mobile workers, shared office spaces, education, medical and industrial installations`}
            <br />
            <br />
          </Typography>

          <SectionActions>
            <CTA href="https://store.velocity-eu.com/" variant="contained" disableElevation>
              Order Online
            </CTA>
          </SectionActions>
        </Grid>
        <Grid item xs={12} md={6} className={classes.imageGrid}></Grid>
      </Grid>
    </HeroBlock>
  );
};

export default VelocityEndpointHw;
