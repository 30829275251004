import React from 'react';
import AppTopBar from 'components/common/AppTopBar';
import HeroSection from 'components/HeroSection';
import FutureSection from 'components/FutureSection';
import VelocityCloudDesktop from 'components/VelocityCloudDesktop';
import MediaCardsAA from 'components/MediaCardsAA';
import MediaCardsBB from 'components/MediaCardsBB';
import WhyChooseUs from 'components/WhyChooseUs';
import PartnerLogos from 'components/PartnerLogos';
import RequestDemo from 'components/common/RequestDemo';
import VelocityEndpointHw from 'components/VelocityEndpointHw';
import Footer from 'components/common/Footer';
import CopyRight from 'components/common/CopyRight';
import HeadingSection from 'components/HeadingSection';

const Home: React.FC = () => (
  <React.Fragment>
    <AppTopBar elevation={0} style={{ zIndex: 1201 }} />
    <HeroSection />
    <FutureSection />
    <HeadingSection text="Velocity Managed Cloud Services" background="#009be3" />
    <VelocityCloudDesktop />
    <MediaCardsAA />
    <RequestDemo />
    <HeadingSection text="Featured Products" background="#f15236" />
    <VelocityEndpointHw />
    <MediaCardsBB />
    <WhyChooseUs />
    <PartnerLogos />
    <Footer />
    <CopyRight />
  </React.Fragment>
);

export default Home;
