import React from 'react';
import { makeStyles, createStyles, styled } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';
import sectionBg from 'assets/macs.png';

const HeroBlock = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${sectionBg})`,
  margin: theme.spacing(1, 4),
  backgroundSize: 'contain',
  backgroundPosition: '85% center',
  backgroundRepeat: 'no-repeat',
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    backgroundPosition: 'center bottom',
  },
  [theme.breakpoints.down('sm')]: {
    margin: theme.spacing(1),
  },
}));

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      flexGrow: 1,
      margin: '0 auto',
    },
    typography: {
      color: '#423f3f',
      textAlign: 'center',
      textShadow: '-1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff',
    },
    grid: {
      padding: '160px 48px',
      [theme.breakpoints.down('md')]: {
        padding: '40px 48px 280px',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '40px 8px 200px',
      },
    },
  })
);

const CloudPlansPricing: React.FC = () => {
  const classes = useStyles();

  return (
    <>
      <HeroBlock id="CloudPlansPricing" component="div">
        <Grid container className={classes.root}>
          <Grid item xs={12} lg={6} className={classes.grid}>
            <Typography
              variant="h5"
              component="h5"
              gutterBottom
              className={classes.typography}
              style={{
                letterSpacing: '-1px',
                fontWeight: 600,
                marginBottom: '16px',
              }}>
              Velocity
            </Typography>

            <Typography
              variant="h4"
              component="h4"
              gutterBottom
              className={classes.typography}
              style={{
                fontWeight: 800,
              }}>
              {`Cloud Plans & Pricing`}
            </Typography>
          </Grid>
        </Grid>
      </HeroBlock>
    </>
  );
};

export default CloudPlansPricing;
