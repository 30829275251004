import React from 'react';
import AppTopBar from 'components/common/AppTopBar';
import AppNavigationBar from 'components/common/AppNavigationBar';
import CloudDesktopPremium1 from 'components/CloudDesktopPremium/CloudDesktopPremium1';
import CloudDesktopPremium2 from 'components/CloudDesktopPremium/CloudDesktopPremium2';
import CloudDesktopPremium3 from 'components/CloudDesktopPremium/CloudDesktopPremium3';
import Footer from 'components/common/Footer';
import CopyRight from 'components/common/CopyRight';

const CloudDesktop: React.FC = () => (
  <React.Fragment>
    <AppTopBar elevation={0} style={{ zIndex: 1201 }} />
    <AppNavigationBar />
    <CloudDesktopPremium1 />
    <CloudDesktopPremium2 />
    <CloudDesktopPremium3 />
    <Footer />
    <CopyRight />
  </React.Fragment>
);

export default CloudDesktop;
