import React from 'react';
import { makeStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';
import sectionImg1 from 'assets/Velocity-Home_11.jpg';
import sectionImg2 from 'assets/Velocity-Home_13.jpg';
import sectionImg3 from 'assets/Velocity-Home_15.jpg';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';

const HeroBlock = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3, 1, 0),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: '#f4f4f4',
}));

const CTA = styled(Button)({
  background: '#f15236',
  color: '#fff',
  fontWeight: 600,
  fontSize: 18,
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
  padding: '12px 20px',
  borderRadius: 0,
  position: 'relative',
  marginLeft: '20px',
  '&:hover': {
    background: '#ff0f0f',
    '&::before': { background: '#ff0f0f' },
    '&::after': { background: '#ff0f0f' },
  },
  '&::before': {
    width: 40,
    content: '""',
    display: 'block',
    background: '#f15236',
    position: 'absolute',
    left: '-20px',
    top: '0',
    bottom: '0',
    right: 'auto',
    borderRadius: '50%',
    transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  '&::after': {
    width: 40,
    content: '""',
    display: 'block',
    background: '#f15236',
    position: 'absolute',
    right: '-20px',
    top: '0',
    bottom: '0',
    left: 'auto',
    borderRadius: '50%',
    transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(0, 'auto', 3),
      maxWidth: theme.breakpoints.values['lg'],
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        marginBottom: 0,
      },
    },
    card: {
      maxWidth: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      backgroundColor: '#f8f8f8',
      borderRadius: 0,
      transition: 'background .1s linear',
      margin: theme.spacing(0, 1.5),
      color: '#423f3f',
      '&:hover': {
        backgroundColor: '#eee',
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(3),
        height: 'auto',
      },
    },
    cardContent: {
      padding: theme.spacing(2),
    },
    cardActions: {
      padding: '8px 16px 24px',
    },
    media: {
      height: 280,
    },
    cardActionArea: {
      '&:hover .MuiCardActionArea-focusHighlight': {
        opacity: 0,
      },
    },
    label: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: 'normal',
      color: '#737373',
    },
    title: {
      fontSize: 34,
      fontWeight: 700,
      lineHeight: '36px',
      marginBottom: 24,
    },
    text: {
      fontSize: 18,
    },
  })
);

const MediaCardsAA: React.FC = () => {
  const classes = useStyles();

  const CardComponent = ({
    title,
    text,
    img,
    url,
  }: {
    title: string;
    text: string;
    img: string;
    url?: string;
  }) => (
    <Card elevation={0} className={classes.card}>
      <CardActionArea className={classes.cardActionArea}>
        <CardMedia className={classes.media} image={img} title={title} />
        <CardContent className={classes.cardContent}>
          <Typography color="textSecondary" variant="h6" component="span" className={classes.label}>
            Velocity
          </Typography>
          <Typography gutterBottom variant="h4" component="h2" className={classes.title}>
            {title}
          </Typography>
          <Typography variant="subtitle1" component="span" className={classes.text}>
            {text}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions className={classes.cardActions}>
        <CTA href={url} variant="contained" disableElevation>
          Learn More
        </CTA>
      </CardActions>
    </Card>
  );

  return (
    <HeroBlock component="div" id="CloudDesktopPremium">
      <Grid container className={classes.root}>
        <Grid item xs={12} md={4}>
          <CardComponent
            title="Cloud Desktop Premium"
            text="Velocity GPU cloud workstations. Superior performance for demanding industries such as Engineering, Architecture, Medical, Finance and Education."
            img={sectionImg1}
            url="/cloud-desktop-premium"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CardComponent
            title="Enterprise PBX"
            text="A fully featured enterprise class corporate Telephone system fully integrated with the Velocity cloud Virtual Desktop Infrastructure."
            img={sectionImg2}
            url="/enterprise-pbx"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CardComponent
            title="Cloud Connections"
            text="Reliable and uncontended, High performance Internet connections from 500Mbps to 10Gbps with 4/5G failover."
            img={sectionImg3}
          />
        </Grid>
      </Grid>
    </HeroBlock>
  );
};

export default MediaCardsAA;
