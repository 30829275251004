import React from 'react';
import { makeStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { FiberManualRecord } from '@material-ui/icons';
import icon1 from 'assets/deployment/greenfield-icon.png';
import icon2 from 'assets/deployment/landing-zone-only-icon.png';
import icon3 from 'assets/deployment/existing-iaas-icon.png';
import icon4 from 'assets/deployment/existing-wvd-icon.png';

const HeroBlock = styled(Box)(({ theme }) => ({
  padding: theme.spacing(6, 0),
  backgroundColor: '#1a1d20',
  marginBottom: theme.spacing(10),
}));

const IconImage = styled('img')({
  width: 56,
  height: 56,
  verticalAlign: 'top',
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: theme.breakpoints.values['lg'],
      margin: '0 auto',
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
    label: {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: 'normal',
      color: '#f15236',
      marginBottom: 16,
    },
    heading: {
      fontSize: 34,
      fontWeight: 700,
      lineHeight: '36px',
      maxWidth: 1280,
      width: '100%',
      color: '#fff',
      margin: theme.spacing(0, 'auto', 4),
      padding: theme.spacing(0, 3),
    },
    title: {
      fontWeight: 700,
      fontSize: 20,
      lineHeight: 1.4,
      color: '#423f3f',
    },
    text: {
      fontSize: 18,
      lineHeight: 1.4,
      marginBottom: 16,
    },
    question: {
      fontWeight: 600,
      fontSize: 20,
      lineHeight: 1.4,
      marginBottom: 16,
    },
    textGrid: {
      display: 'flex',
      padding: theme.spacing(0, 3),
    },
    blkWrapper: {
      color: '#fff',
      display: 'flex',
      flexDirection: 'column',
      margin: theme.spacing(2, 0, 4),
    },
    blkHeader: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: theme.spacing(2),
    },
    blkIcon: {
      marginRight: theme.spacing(1.5),
    },
    blkHeading: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    blkTitle: {
      fontSize: 20,
      fontWeight: 600,
      lineHeight: 1.4,
    },
    blkSubtitle: {
      fontSize: 16,
      fontWeight: 600,
    },
    blkContent: {},
    blkDescription: {
      fontSize: 16,
      [theme.breakpoints.up('sm')]: {
        minHeight: 66,
      },
      [theme.breakpoints.up('md')]: {
        minHeight: 44,
      },
      [theme.breakpoints.up('lg')]: {
        minHeight: 66,
      },
    },

    blkList: {
      padding: theme.spacing(2, 0),
    },
    blkListItem: {
      paddingTop: 2,
      paddingBottom: 2,
      paddingLeft: 0,
      paddingRight: 0,
      alignItems: 'flex-start',
    },
    listItemIcon: {
      minWidth: 24,
      minHeight: 24,
      alignItems: 'center',
    },
    listItemText: {
      margin: 0,
    },

    blkDepTime: {
      fontSize: 18,
      fontWeight: 700,
    },
  })
);

const GreenfieldList = [
  'New resource group',
  'New network',
  'New Azure AD DS',
  'New Azure Files share',
  'New WVD deployment',
];

const LandingZoneOnlyList = [
  'Use existing identity',
  'Use existing resource group',
  'Use existing network',
  'New Azure Files share',
  'New WVD deployment',
];

const ExistingIAASList = [
  'Use existing identity',
  'Use existing resource group',
  'Use existing network',
  'Use existing file storage',
  'New WVD deployment',
];

const ExistingWVDList = [
  'Use existing identity',
  'Use existing resource group',
  'Use existing network',
  'Use existing file storage',
  'Use existing WVD deployment',
];

const DeploymentBlock = ({ icon, title, subtitle, description, list, depTime, depColor }: any) => {
  const classes = useStyles();

  return (
    <Box className={classes.blkWrapper}>
      <Box className={classes.blkHeader}>
        <Box className={classes.blkIcon}>
          <IconImage src={icon} alt={title} />
        </Box>
        <Box className={classes.blkHeading}>
          <Typography variant="h3" component="h3" className={classes.blkTitle}>
            {title}
          </Typography>
          <Typography variant="h4" component="h4" className={classes.blkSubtitle}>
            {subtitle}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.blkContent}>
        <Typography variant="body2" component="p" className={classes.blkDescription}>
          {description}
        </Typography>

        <List className={classes.blkList}>
          {list.map((item: string, index: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <ListItem className={classes.blkListItem} key={index}>
              <ListItemIcon className={classes.listItemIcon}>
                <FiberManualRecord style={{ fontSize: '.8rem' }} htmlColor="#fff" />
              </ListItemIcon>
              <ListItemText className={classes.listItemText} primary={item} />
            </ListItem>
          ))}
        </List>

        <Typography
          style={{ color: depColor }}
          variant="subtitle1"
          component="p"
          className={classes.blkDepTime}>
          Deployment time: {depTime} minutes
        </Typography>
      </Box>
    </Box>
  );
};

const DeploymentScenariosBlock1: React.FC = () => {
  const classes = useStyles();

  return (
    <HeroBlock component="div">
      <Grid container className={classes.root}>
        <Grid item xs={12} sm={6} lg={3} className={classes.textGrid}>
          <DeploymentBlock
            icon={icon1}
            title="GREENFIELD"
            subtitle="(no Azure footprint)"
            description="Create a new all-PaaS environment of WVD supporting services + WVD hosts"
            list={GreenfieldList}
            depTime="60"
            depColor="#55ad34"
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3} className={classes.textGrid}>
          <DeploymentBlock
            icon={icon2}
            title="Landing Zone Only"
            subtitle="(existing AD or AADDS)"
            description="Create WVD supporting services leveraging existing components + WVD hosts"
            list={LandingZoneOnlyList}
            depTime="30"
            depColor="#d7df28"
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3} className={classes.textGrid}>
          <DeploymentBlock
            icon={icon3}
            title="Existing IAAS"
            subtitle="(existing Azure environment)"
            description="Connect to existing Azure environment and extend with WVD"
            list={ExistingIAASList}
            depTime="10"
            depColor="#1b9cb9"
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3} className={classes.textGrid}>
          <DeploymentBlock
            icon={icon4}
            title="Existing WVD"
            subtitle="(existing WVD deployment)"
            description="Overlay on top of existing WVD deployment to enhance management and auto-scaling"
            list={ExistingWVDList}
            depTime="10"
            depColor="#ff6626"
          />
        </Grid>
      </Grid>
    </HeroBlock>
  );
};

export default DeploymentScenariosBlock1;
