import React, { useState } from 'react';
import clsx from 'clsx';
import {
  Drawer,
  CssBaseline,
  Toolbar,
  Divider,
  IconButton,
  Button,
  List,
  ListItem,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import { makeStyles, useTheme, Theme, createStyles, styled } from '@material-ui/core/styles';

import background from 'assets/mobi-nav/mobi-nav-background.png';
import logo from 'assets/mobi-nav/mobi-nav-logo.png';
import closeIcon from 'assets/mobi-nav/mobi-nav-close-icon.png';
import solutionsIcon from 'assets/mobi-nav/mobi-nav-solutions.png';
import storeIcon from 'assets/mobi-nav/mobi-nav-store.png';
import useCasesIcon from 'assets/mobi-nav/mobi-nav-use-cases.png';
import resourcesIcon from 'assets/mobi-nav/mobi-nav-resources.png';
import getHelpIcon from 'assets/mobi-nav/mobi-nav-get-help.png';
import myAccountIcon from 'assets/mobi-nav/mobi-nav-my-account.png';
import { scrollToElement } from 'common/helpers';

const NavImage = styled('img')({
  height: '100%',
  display: 'inline-block',
});

const NavIcon = styled('img')({
  height: '100%',
  width: '100%',
  display: 'inline-block',
});

const NavLink = styled(Button)({
  textTransform: 'capitalize',
});

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        marginRight: '-24px',
      },
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      padding: 0,
      marginLeft: 8,
      '& .MuiSvgIcon-root': {
        width: 34,
        height: 34,
      },
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      zIndex: ('2147483647 !important' as unknown) as number,
    },
    drawerPaper: {
      width: '100%',
      backgroundImage: `url(${background})`,
      backgroundSize: 'cover',
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(1.5, 2),
      justifyContent: 'space-between',
      backgroundColor: 'rgba(0,0,0,.45)',
      borderBottom: '1px solid #222',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
    navIcon: {
      backgroundColor: '#de5d43',
      color: '#fff',
      padding: theme.spacing(2),
      width: '56px',
      height: '56px',
      marginLeft: theme.spacing(2),
    },
    navList: {
      padding: theme.spacing(2),
      textAlign: 'right',
    },
    navListItem: {
      textAlign: 'right',
    },
    buttonNav: {
      color: '#fff',
      fontSize: theme.spacing(2.25),
      padding: 0,
      textAlign: 'right',
      textTransform: 'capitalize',
    },
    toolbar: {
      [theme.breakpoints.down('sm')]: {
        paddingRight: 16,
      },
      [theme.breakpoints.down(600)]: {
        paddingLeft: theme.spacing(1),
      },
    },
    accordion: {
      background: 'none',
      width: '100%',
    },
    accordionSummary: {
      padding: 0,
      minHeight: 'auto',
      [theme.breakpoints.up('sm')]: {
        minHeight: 'auto',
      },
      '&.Mui-expanded': {
        minHeight: 'inherit',
      },
      '& .MuiAccordionSummary-content': {
        margin: 0,
      },
    },
    accordionDetails: {
      padding: 0,
    },
    list: {
      width: '100%',
      paddingRight: 72,
    },
    resourceLink: {
      padding: 0,
      width: '100%',
      textAlign: 'right',
      color: '#999',
      '& .MuiListItemText-primary': {
        fontSize: 16,
        color: '#aaa',
        marginBottom: 4,
      },
    },
  })
);

const NavDrawer: React.ElementType = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [expandSolutions, setExpandSolutions] = useState(false);
  const [expandStore, setExpandStore] = useState(false);
  const [expandCases, setExpandCases] = useState(false);
  const [expandAccount, setExpandAccount] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Toolbar style={{ minHeight: 'auto' }} className={classes.toolbar}>
        <IconButton
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          style={{ color: '#f5f5f5' }}
          className={clsx(classes.menuButton, open && classes.hide)}>
          <Menu />
        </IconButton>
      </Toolbar>
      <Drawer
        className={classes.drawer}
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}>
        <div className={classes.drawerHeader}>
          <NavImage src={logo} alt="" style={{ maxHeight: 48 }} />
        </div>
        <Divider />
        <List className={classes.navList}>
          <IconButton
            className={classes.navIcon}
            style={{ marginBottom: theme.spacing(5), marginRight: theme.spacing(2) }}
            onClick={handleDrawerClose}>
            <NavIcon src={closeIcon} alt="" />
          </IconButton>

          <ListItem className={classes.navListItem}>
            <Accordion expanded={expandSolutions} elevation={0} className={classes.accordion}>
              <AccordionSummary
                className={classes.accordionSummary}
                onClick={() => setExpandSolutions(!expandSolutions)}>
                <Button fullWidth className={classes.buttonNav}>
                  <ListItemText disableTypography primary="Solutions" />
                  <IconButton className={classes.navIcon}>
                    <NavIcon src={solutionsIcon} alt="" />
                  </IconButton>
                </Button>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                <List dense className={classes.list}>
                  <ListItem disableGutters>
                    <NavLink
                      href="/cloud-desktop"
                      className={classes.resourceLink}
                      onClick={() => handleDrawerClose()}>
                      <ListItemText primary="Velocity Cloud Desktop" />
                    </NavLink>
                  </ListItem>
                  <ListItem disableGutters>
                    <NavLink
                      href="/cloud-desktop-premium"
                      className={classes.resourceLink}
                      onClick={() => handleDrawerClose()}>
                      <ListItemText primary="Velocity Cloud Desktop Premium" />
                    </NavLink>
                  </ListItem>
                  <ListItem disableGutters>
                    <NavLink
                      href="/enterprise-pbx"
                      className={classes.resourceLink}
                      onClick={() => handleDrawerClose()}>
                      <ListItemText primary="Velocity Enterprise PBX" />
                    </NavLink>
                  </ListItem>
                  <ListItem disableGutters>
                    <NavLink
                      className={classes.resourceLink}
                      onClick={() => {
                        handleDrawerClose(), scrollToElement('CloudDesktopPremium');
                      }}>
                      <ListItemText primary="Velocity Cloud Connections" />
                    </NavLink>
                  </ListItem>
                  <ListItem disableGutters>
                    <NavLink
                      href="/deployment-scenarios"
                      className={classes.resourceLink}
                      onClick={() => handleDrawerClose()}>
                      <ListItemText primary="Velocity Deployment Scenarios" />
                    </NavLink>
                  </ListItem>
                </List>
              </AccordionDetails>
            </Accordion>
          </ListItem>

          <ListItem className={classes.navListItem}>
            <Accordion expanded={expandStore} elevation={0} className={classes.accordion}>
              <AccordionSummary
                className={classes.accordionSummary}
                onClick={() => setExpandStore(!expandStore)}>
                <Button fullWidth className={classes.buttonNav}>
                  <ListItemText disableTypography primary="Store" />
                  <IconButton className={classes.navIcon}>
                    <NavIcon src={storeIcon} alt="" />
                  </IconButton>
                </Button>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                <List dense className={classes.list}>
                  <ListItem disableGutters>
                    <NavLink className={classes.resourceLink} href="https://store.velocity-eu.com/">
                      <ListItemText primary="Cloud Endpoint Hardware" />
                    </NavLink>
                  </ListItem>
                  <ListItem disableGutters>
                    <NavLink className={classes.resourceLink} href="https://store.velocity-eu.com/">
                      <ListItemText primary="Network Devices" />
                    </NavLink>
                  </ListItem>
                  <ListItem disableGutters>
                    <NavLink className={classes.resourceLink} href="https://store.velocity-eu.com/">
                      <ListItemText primary="PBX Accessories" />
                    </NavLink>
                  </ListItem>
                </List>
              </AccordionDetails>
            </Accordion>
          </ListItem>

          <ListItem className={classes.navListItem}>
            <Accordion expanded={expandCases} elevation={0} className={classes.accordion}>
              <AccordionSummary
                className={classes.accordionSummary}
                onClick={() => setExpandCases(!expandCases)}>
                <Button fullWidth className={classes.buttonNav}>
                  <ListItemText disableTypography primary="Use Cases" />
                  <IconButton className={classes.navIcon}>
                    <NavIcon src={useCasesIcon} alt="" />
                  </IconButton>
                </Button>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                <List dense className={classes.list}>
                  <ListItem disableGutters>
                    <NavLink
                      href="/use-case-еducation"
                      className={classes.resourceLink}
                      onClick={handleDrawerClose}>
                      <ListItemText primary="Education" />
                    </NavLink>
                  </ListItem>
                  <ListItem disableGutters>
                    <NavLink
                      href="/use-case-medical"
                      className={classes.resourceLink}
                      onClick={handleDrawerClose}>
                      <ListItemText primary="Medical" />
                    </NavLink>
                  </ListItem>
                  <ListItem disableGutters>
                    <NavLink
                      href="/use-case-engineering"
                      className={classes.resourceLink}
                      onClick={handleDrawerClose}>
                      <ListItemText primary="Engineering" />
                    </NavLink>
                  </ListItem>
                  <ListItem disableGutters>
                    <NavLink
                      href="/use-case-financial"
                      className={classes.resourceLink}
                      onClick={handleDrawerClose}>
                      <ListItemText primary="Financial" />
                    </NavLink>
                  </ListItem>
                </List>
              </AccordionDetails>
            </Accordion>
          </ListItem>

          <ListItem className={classes.navListItem}>
            <Button
              fullWidth
              className={classes.buttonNav}
              href="/resources"
              onClick={handleDrawerClose}>
              <ListItemText disableTypography primary="Resources" />
              <IconButton className={classes.navIcon}>
                <NavIcon src={resourcesIcon} alt="" />
              </IconButton>
            </Button>
          </ListItem>

          <ListItem className={classes.navListItem}>
            <Button
              fullWidth
              className={classes.buttonNav}
              href="https://support.velocity-eu.com/"
              onClick={handleDrawerClose}>
              <ListItemText disableTypography primary="Get Help" />
              <IconButton className={classes.navIcon}>
                <NavIcon src={getHelpIcon} alt="" />
              </IconButton>
            </Button>
          </ListItem>

          <ListItem className={classes.navListItem}>
            <Accordion expanded={expandAccount} elevation={0} className={classes.accordion}>
              <AccordionSummary
                className={classes.accordionSummary}
                onClick={() => setExpandAccount(!expandAccount)}>
                <Button fullWidth className={classes.buttonNav}>
                  <ListItemText disableTypography primary="My Account" />
                  <IconButton className={classes.navIcon}>
                    <NavIcon src={myAccountIcon} alt="" />
                  </IconButton>
                </Button>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                <List dense className={classes.list}>
                  <ListItem disableGutters>
                    <NavLink
                      className={classes.resourceLink}
                      href="https://books.velocity-eu.com/portal/velocityeu/login">
                      <ListItemText primary="My Orders" />
                    </NavLink>
                  </ListItem>
                  <ListItem disableGutters>
                    <NavLink
                      className={classes.resourceLink}
                      href="https://store.velocity-eu.com/signin">
                      <ListItemText primary="My Velocity Store" />
                    </NavLink>
                  </ListItem>
                  <ListItem disableGutters>
                    <NavLink
                      className={classes.resourceLink}
                      href="https://subscriptions.zoho.eu/portal/velocityeu">
                      <ListItemText primary="My Subscriptions" />
                    </NavLink>
                  </ListItem>
                  <ListItem disableGutters>
                    <NavLink
                      className={classes.resourceLink}
                      href="https://support.velocity-eu.com/portal/en/signin">
                      <ListItemText primary="My Help Desk" />
                    </NavLink>
                  </ListItem>
                  <ListItem disableGutters>
                    <NavLink className={classes.resourceLink} href="https://vapp.adminportal.pro/">
                      <ListItemText primary="My Control Panel" />
                    </NavLink>
                  </ListItem>
                </List>
              </AccordionDetails>
            </Accordion>
          </ListItem>
        </List>
      </Drawer>
    </div>
  );
};

export default NavDrawer;
