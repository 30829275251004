import React from 'react';
import { makeStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';
import sectionImg1 from 'assets/azure.png';
import sectionImg2 from 'assets/3CX-partner-logo-hd.jpg';
import sectionImg3 from 'assets/ms.png';
import sectionImg4 from 'assets/igel.png';
import sectionImg5 from 'assets/bt.png';
import sectionImg6 from 'assets/fanvil-transparent.png';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';

const HeroBlock = styled(Box)(({ theme }) => ({
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: '#fafafa',
  padding: theme.spacing(3, 0),
  margin: theme.spacing(0, 0, 3),
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: theme.breakpoints.values['lg'],
      padding: theme.spacing(0, 3),
    },
    card: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      backgroundColor: '#fafafa',
      padding: theme.spacing(1.5),
      margin: theme.spacing(1, 0),
      boxSizing: 'border-box',
      borderRadius: 0,
      transition: 'background .1s linear',
      '&:hover': {
        backgroundColor: '#eee',
      },
    },
    cardContent: {
      padding: theme.spacing(4, 2),
      textAlign: 'center',
      justifyContent: 'center',
    },
    media: {
      height: 160,
      backgroundSize: 'contain',
      backgroundPosition: 'center',
    },
    cardActionArea: {
      '&:hover .MuiCardActionArea-focusHighlight': {
        opacity: 0,
      },
    },
  })
);

const PartnerLogos: React.FC = () => {
  const classes = useStyles();

  const CardBlock = ({ image, title }: { image: string; title: string }) => (
    <Card elevation={0} className={classes.card}>
      <CardActionArea className={classes.cardActionArea}>
        <CardMedia className={classes.media} image={image} title={title} />
      </CardActionArea>
    </Card>
  );

  return (
    <HeroBlock component="div">
      <Grid container className={classes.root}>
        <Grid item xs={6} sm={4} md={2}>
          <CardBlock image={sectionImg1} title="Azure" />
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <CardBlock image={sectionImg2} title="3cX" />
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <CardBlock image={sectionImg3} title="Microsoft" />
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <CardBlock image={sectionImg4} title="Igel" />
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <CardBlock image={sectionImg5} title="BT" />
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <CardBlock image={sectionImg6} title="Fanvil" />
        </Grid>
      </Grid>
    </HeroBlock>
  );
};

export default PartnerLogos;
