import React from 'react';
import { makeStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
} from '@material-ui/core';
import { ArrowRight } from '@material-ui/icons';

const HeroBlock = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 0),
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: theme.breakpoints.values['lg'],
      margin: '0 auto',
      padding: theme.spacing(0, 3),
      color: '#423f3f',
    },
    gridLine: {
      borderBottom: '3px solid #4bb1fe',
      paddingBottom: theme.spacing(1),
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
    label: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: 'normal',
      color: '#737373',
    },
    title: {
      fontSize: 28,
      fontWeight: 700,
      marginBottom: 16,
    },
    text: {
      width: '100%',
      fontSize: 18,
      marginBottom: 16,
      display: 'inline-block',
    },
    list: {
      padding: '0 0 16px',
    },
    listItem: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    listItemIcon: {
      minWidth: 32,
    },
    listItemText: {
      margin: 0,
      fontWeight: 600,
    },
    buttonLink: {
      fontWeight: 600,
      color: '#4bb1fe',
      marginLeft: '-8px',
    },
  })
);

const ResourcesBlock2: React.FC = () => {
  const classes = useStyles();

  return (
    <HeroBlock component="div">
      <Grid container className={classes.root}>
        <Grid item xs={12} className={classes.gridLine}>
          <Typography variant="h4" component="h2" className={classes.title}>
            Connect with the Windows Desktop client
          </Typography>

          <Typography variant="subtitle1" component="p" className={classes.text}>
            {`You can access Windows Virtual Desktop resources on devices with Windows 10, Windows 10 IoT Enterprise, and Windows 7 using the Windows Desktop client. The client doesn't support Window 8 or Windows 8.1.`}
          </Typography>
          <Typography variant="subtitle1" component="p" className={classes.text}>
            {`Install the Windows Desktop client`}
          </Typography>
          <Typography variant="subtitle1" component="p" className={classes.text}>
            {`Choose the client that matches your version of Windows:`}
          </Typography>

          <List className={classes.list}>
            <ListItem className={classes.listItem}>
              <ListItemIcon className={classes.listItemIcon}>
                <ArrowRight fontSize="large" htmlColor="#009be3" />
              </ListItemIcon>
              <ListItemText
                className={classes.listItemText}
                primary={
                  <Button
                    href="https://go.microsoft.com/fwlink/?linkid=2068602"
                    variant="text"
                    className={classes.buttonLink}>
                    Windows 64-bit
                  </Button>
                }
              />
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemIcon className={classes.listItemIcon}>
                <ArrowRight fontSize="large" htmlColor="#009be3" />
              </ListItemIcon>
              <ListItemText
                className={classes.listItemText}
                primary={
                  <Button
                    href="https://go.microsoft.com/fwlink/?linkid=2098960"
                    variant="text"
                    className={classes.buttonLink}>
                    Windows 32-bit
                  </Button>
                }
              />
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemIcon className={classes.listItemIcon}>
                <ArrowRight fontSize="large" htmlColor="#009be3" />
              </ListItemIcon>
              <ListItemText
                className={classes.listItemText}
                primary={
                  <Button
                    href="https://go.microsoft.com/fwlink/?linkid=2098961"
                    variant="text"
                    className={classes.buttonLink}>
                    Windows ARM64
                  </Button>
                }
              />
            </ListItem>
          </List>

          <Typography variant="subtitle1" component="p" className={classes.text}>
            {`You can install the client for the current user, which doesn't require admin rights, or your admin can install and configure the client so that all users on the device can access it.`}
          </Typography>
          <Typography variant="subtitle1" component="p" className={classes.text}>
            Once installed, the client can be launched from the Start menu by searching for
            <strong> Remote Desktop.</strong>
          </Typography>
        </Grid>
      </Grid>
    </HeroBlock>
  );
};

export default ResourcesBlock2;
