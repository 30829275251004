import React from 'react';
import AppTopBar from 'components/common/AppTopBar';
import AppNavigationBar from 'components/common/AppNavigationBar';
import CloudDesktop1 from 'components/CloudDesktop/CloudDesktop1';
import CloudDesktop2 from 'components/CloudDesktop/CloudDesktop2';
import CloudDesktop3 from 'components/CloudDesktop/CloudDesktop3';
import CloudDesktop4 from 'components/CloudDesktop/CloudDesktop4';
import CloudDesktop5 from 'components/CloudDesktop/CloudDesktop5';
import Footer from 'components/common/Footer';
import CopyRight from 'components/common/CopyRight';

const CloudDesktop: React.FC = () => (
  <React.Fragment>
    <AppTopBar elevation={0} style={{ zIndex: 1201 }} />
    <AppNavigationBar />
    <CloudDesktop1 />
    <CloudDesktop2 />
    <CloudDesktop3 />
    <CloudDesktop4 />
    <CloudDesktop5 />
    <Footer />
    <CopyRight />
  </React.Fragment>
);

export default CloudDesktop;
