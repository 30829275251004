import React from 'react';
import { makeStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';
import sectionImg from 'assets/contact-us-hero-background.png';

const HeroBlock = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8, 0),
  minHeight: '50vh',
  backgroundImage: `url(${sectionImg})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(4, 0),
    minHeight: '30vh',
  },
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: theme.breakpoints.values['lg'],
      margin: '0 auto',
      padding: theme.spacing(0, 3),
      color: '#fff',
    },
    textGrid: {
      paddingRight: 32,
      [theme.breakpoints.down('sm')]: {
        paddingRight: 0,
      },
    },
    label: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: 'normal',
      color: '#ddd',
    },
    title: {
      fontSize: 56,
      fontWeight: 700,
    },
  })
);

const ContactUsBlock1: React.FC = () => {
  const classes = useStyles();

  return (
    <HeroBlock component="div">
      <Grid container className={classes.root}>
        <Grid item xs={12} className={classes.textGrid}>
          <Typography variant="h5" component="span" gutterBottom className={classes.label}>
            Velocity
          </Typography>
          <Typography variant="h4" component="h2" className={classes.title}>
            Global Offices
          </Typography>
        </Grid>
      </Grid>
    </HeroBlock>
  );
};

export default ContactUsBlock1;
