// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import { SvgIcon } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const FacebookIcon = props => (
  <SvgIcon {...props}>
    <path d="M9 8H6v4h3v12h5V12h3.642L18 8h-4V6.333C14 5.378 14.192 5 15.115 5H18V0h-3.808C10.596 0 9 1.583 9 4.615V8z" />
  </SvgIcon>
);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const InstagramIcon = props => (
  <SvgIcon {...props}>
    <path
      d="M21.231 0H2.769A2.77 2.77 0 000 2.769v18.46A2.77 2.77 0 002.769 24h18.463A2.769 2.769 0 0024 21.229V2.769A2.769 2.769 0 0021.231 0zM12 7.385a4.615 4.615 0 11.002 9.23A4.615 4.615 0 0112 7.385zm9 12.693a.923.923 0 01-.924.922H3.924A.923.923 0 013 20.078V10h1.897a7.56 7.56 0 00-.2.971c-.05.337-.081.679-.081 1.029a7.384 7.384 0 1014.768 0c0-.35-.031-.692-.081-1.028a7.56 7.56 0 00-.2-.971H21v10.077zm0-13.98a.924.924 0 01-.924.923h-2.174a.923.923 0 01-.923-.923V3.923c0-.51.412-.923.923-.923h2.174c.511 0 .924.413.924.923v2.175z"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </SvgIcon>
);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const UserIcon = props => (
  <SvgIcon {...props} viewBox="0 0 512 512">
    <path d="M437.02 330.98c-27.883-27.882-61.071-48.523-97.281-61.018C378.521 243.251 404 198.548 404 148 404 66.393 337.607 0 256 0S108 66.393 108 148c0 50.548 25.479 95.251 64.262 121.962-36.21 12.495-69.398 33.136-97.281 61.018C26.629 379.333 0 443.62 0 512h40c0-119.103 96.897-216 216-216s216 96.897 216 216h40c0-68.38-26.629-132.667-74.98-181.02zM256 256c-59.551 0-108-48.448-108-108S196.449 40 256 40s108 48.448 108 108-48.449 108-108 108z" />
  </SvgIcon>
);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const EmailIcon = props => (
  <SvgIcon {...props} viewBox="0 0 512 512">
    <path d="M10.688 95.156C80.958 154.667 204.26 259.365 240.5 292.01c4.865 4.406 10.083 6.646 15.5 6.646 5.406 0 10.615-2.219 15.469-6.604 36.271-32.677 159.573-137.385 229.844-196.896 4.375-3.698 5.042-10.198 1.5-14.719C494.625 69.99 482.417 64 469.333 64H42.667c-13.083 0-25.292 5.99-33.479 16.438-3.542 4.52-2.875 11.02 1.5 14.718z" />
    <path d="M505.813 127.406a10.618 10.618 0 00-11.375 1.542C416.51 195.01 317.052 279.688 285.76 307.885c-17.563 15.854-41.938 15.854-59.542-.021-33.354-30.052-145.042-125-208.656-178.917a10.674 10.674 0 00-11.375-1.542A10.674 10.674 0 000 137.083v268.25C0 428.865 19.135 448 42.667 448h426.667C492.865 448 512 428.865 512 405.333v-268.25a10.66 10.66 0 00-6.187-9.677z" />
  </SvgIcon>
);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const PhoneIcon = props => (
  <SvgIcon {...props} viewBox="0 0 384 384">
    <path d="M353.188 252.052c-23.51 0-46.594-3.677-68.469-10.906-10.719-3.656-23.896-.302-30.438 6.417l-43.177 32.594c-50.073-26.729-80.917-57.563-107.281-107.26l31.635-42.052c8.219-8.208 11.167-20.198 7.635-31.448-7.26-21.99-10.948-45.063-10.948-68.583C132.146 13.823 118.323 0 101.333 0h-70.52C13.823 0 0 13.823 0 30.813 0 225.563 158.438 384 353.188 384c16.99 0 30.813-13.823 30.813-30.813v-70.323c-.001-16.989-13.824-30.812-30.813-30.812z" />
  </SvgIcon>
);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const ChatIcon = props => (
  <SvgIcon {...props} viewBox="0 0 465.882 465.882">
    <path d="M232.941 0C104.292 0 0 91.254 0 203.824c0 27.129 6.06 53.017 17.059 76.69C43.957 338.406 40.742 406.375 4.841 459.16 1.831 463.586 0 465.882 0 465.882s117.187-21.41 178.37-64.091c17.534 3.689 35.743 5.856 54.571 5.856 128.65 0 232.941-91.256 232.941-203.824C465.882 91.254 361.591 0 232.941 0zM131.029 232.941c-8.04 0-14.559-6.519-14.559-14.559 0-8.042 6.519-14.559 14.559-14.559s14.559 6.517 14.559 14.559c0 8.04-6.519 14.559-14.559 14.559zm101.912 0c-8.04 0-14.559-6.519-14.559-14.559 0-8.042 6.519-14.559 14.559-14.559s14.559 6.517 14.559 14.559c0 8.04-6.519 14.559-14.559 14.559zm101.912 0c-8.04 0-14.559-6.519-14.559-14.559 0-8.042 6.519-14.559 14.559-14.559s14.559 6.517 14.559 14.559c0 8.04-6.519 14.559-14.559 14.559z" />
  </SvgIcon>
);

export default FacebookIcon;
