import React from 'react';
import { makeStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Button,
} from '@material-ui/core';
import sectionImg1 from 'assets/cloud-desktop/ent-orgs.png';
import sectionImg2 from 'assets/cloud-desktop/gov-agencies.png';
import sectionImg3 from 'assets/cloud-desktop/media-conglomerates.png';
import sectionImg4 from 'assets/cloud-desktop/prod-studios.png';
import sectionImg5 from 'assets/cloud-desktop/fin-firms.png';
import sectionImg6 from 'assets/cloud-desktop/design-houses.png';

const HeroBlock = styled(Box)(({ theme }) => ({
  padding: theme.spacing(6, 0),
  backgroundColor: '#4bb0fe',
  marginTop: theme.spacing(2),
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: theme.breakpoints.values['lg'],
      margin: '0 auto',
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
    heading: {
      fontSize: 40,
      fontWeight: 700,
      lineHeight: '36px',
      maxWidth: 1280,
      width: '100%',
      margin: theme.spacing(0, 'auto', 4),
      color: '#fff',
      textAlign: 'center',
    },
    title: {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: '36px',
      textTransform: 'uppercase',
      width: '100%',
      margin: theme.spacing(0, 'auto'),
    },
    subtitle: {
      fontSize: 18,
      marginTop: theme.spacing(3),
      display: 'inline-block',
    },
    cardLink: {
      padding: 0,
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      backgroundColor: 'transparent',
      borderRadius: 0,
      transition: 'background .1s linear',
      '&:hover': {
        backgroundColor: '#4bb0fe66',
      },
    },
    cardContent: {
      padding: theme.spacing(4, 2),
      textAlign: 'center',
      justifyContent: 'center',
      color: '#fff',
    },
    media: {
      height: '128px',
      backgroundSize: '56%',
      backgroundPosition: 'center',
      width: '128px',
      marginTop: 32,
      margin: '0 auto',
      backgroundColor: '#fff',
      borderRadius: '50%',
    },
    cardActionArea: {
      '&:hover .MuiCardActionArea-focusHighlight': {
        opacity: 0,
      },
    },
  })
);

const CardBlock: any = ({
  image,
  title,
  body,
  href,
}: {
  image: string;
  title: string;
  href?: string;
  body?: React.ReactNode;
}) => {
  const classes = useStyles();

  return (
    <Card elevation={0} className={classes.card}>
      <Button variant="text" href={href} className={classes.cardLink}>
        <CardActionArea className={classes.cardActionArea}>
          <CardMedia className={classes.media} image={image} title={title} />
          <CardContent className={classes.cardContent}>
            <Typography gutterBottom variant="h5" component="h2" className={classes.title}>
              {title}
            </Typography>
            {body && (
              <Typography variant="subtitle1" component="span" className={classes.subtitle}>
                {body}
              </Typography>
            )}
          </CardContent>
        </CardActionArea>
      </Button>
    </Card>
  );
};

const CloudDesktopPremium2: React.FC = () => {
  const classes = useStyles();

  return (
    <HeroBlock component="div">
      <Grid container className={classes.root}>
        <Typography gutterBottom variant="h4" component="h2" className={classes.heading}>
          For whom Velocity Cloud Desktop Premium intended?
        </Typography>

        <Grid item xs={12} sm={4} md={4}>
          <CardBlock image={sectionImg1} title="Enterprise organizations" />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <CardBlock image={sectionImg2} title="government agencies" />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <CardBlock image={sectionImg3} title="media conglomerates" />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <CardBlock image={sectionImg4} title="production studios" />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <CardBlock image={sectionImg5} title="financial firms" />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <CardBlock image={sectionImg6} title="design houses" />
        </Grid>
      </Grid>
    </HeroBlock>
  );
};

export default CloudDesktopPremium2;
