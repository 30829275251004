import React from 'react';
import { makeStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { Check } from '@material-ui/icons';

const HeroBlock = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 0),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: theme.breakpoints.values['lg'],
      margin: '16px auto',
      padding: theme.spacing(1, 2),
      fontSize: 18,
      flexDirection: 'column',
    },
    title: {
      fontWeight: 800,
      fontSize: 44,
      color: '#423f3f',
    },
    textBox: {
      color: '#423f3f',
    },
    textIcon: {
      width: 20,
      marginRight: 12,
    },
    list: {
      padding: '16px 0',
    },
    listItem: {
      paddingTop: 4,
      paddingBottom: 4,
    },
    listItemIcon: {
      minWidth: 32,
      marginTop: 2,
    },
    listItemText: {
      margin: 0,
      fontSize: 18,
    },
  })
);

const listItems1 = [
  'to register you for an account with us to use the Hosted Service (Velocity EU Inc Control) or Velocity EU Inc Client Application;',
  'to allow you to apply to become a partner or customer;',
  'to facilitate the provision of software updates and improve your experience on our Site;',
  'to respond to comments and questions and provide customer service; and',
  'to deliver service messages and other services and content you request and to send information related to accounts and services, including confirmations, invoices, technical notices, updates, security alerts, and support and administrative messages.',
];

const listItems2 = [
  'with third party vendors, consultants and other service providers (&ldquo;Service Providers&rdquo;) who are working on our behalf and need access to your information to carry out their work for us;',
  'to (a) comply with laws or respond to lawful requests from public authorities including to meet national security and law enforcement requirements, and legal process, (b) to protect the rights and property of Velocity EU Inc, our agents and others including to enforce our agreements, policies and terms of use, or (c) in the good faith belief that disclosure is needed to respond to an emergency or protect the personal safety of any person;',
  'in connection with any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company. In any such event, we will provide notice via email and a prominent notice on our website if your data is transferred and becomes subject to a different privacy policy; and',
  'to any other third party with your prior consent to do so.',
];

const PrivacyPolicy: React.FC = () => {
  const classes = useStyles();

  return (
    <HeroBlock component="div">
      <Grid container className={classes.root}>
        <Typography gutterBottom variant="h2" component="h2">
          Privacy Policy
        </Typography>
        <p>
          Your privacy is important to Velocity EU Inc, (&ldquo;Velocity EU Inc&rdquo;). This Policy
          explains how your personal information is collected, used, and disclosed by Velocity EU
          Inc.
        </p>
        <p>
          This Privacy Policy (&ldquo;<strong>Privacy Policy</strong>&ldquo;) explains how
          information is collected, used and disclosed by Velocity EU Inc Inc., a Delaware
          corporation (&ldquo;<strong>Velocity EU Inc</strong>&ldquo;, &ldquo;<strong>we</strong>
          &rdquo;, or &ldquo;<strong>us</strong>&rdquo;), and applies to information we collect when
          you use or access our online or mobile websites (www.Velocity EU Inc com), products,
          services or applications (collectively, the &ldquo;
          <strong>Velocity EU Inc Services</strong>&ldquo;), or when you otherwise interact with us.
          We respect the privacy rights of users and recognize the importance of protecting
          information collected about you.
        </p>
        <p>
          <strong>
            Velocity EU Inc, Inc. complies with the EU-U.S. Privacy Shield Framework and the
            Swiss-U.S. Privacy Shield Framework.
            <strong>
              . Velocity EU Inc also complies with EU&rsquo;s General Data Protection Regulations
              (&ldquo;GDPR&rdquo;).
            </strong>
            See the sections entitled EU-U.S. Privacy Shield, Swiss-U.S. Privacy Shield, and
            International Transfer, below, for further information.
          </strong>
        </p>
        <p>
          <strong>WHAT WE COLLECT</strong>
        </p>
        <p>
          We collect information from you in various ways when you use our website or correspond
          with us via communication services, such as email and social media. We may collect
          personal information that you directly enter on our website or send to us via
          communication services. Information we may collect includes name, email address, title,
          and company. In addition, when you visit our website some information may be automatically
          collected. For example, it is standard for your web browser to automatically send
          information to every website you visit including ours. That information includes your
          computer&rsquo;s Internet Protocol (IP) address, access times, browser type and language,
          and referring website addresses. We may also collect information about your
          computer&rsquo;s operating system and information about your usage and activity on our
          website. We use this information, which does not identify individual users, to administer
          the site, to track users&rsquo; movements around the site or hosted service, to improve
          our products and services, and for internal recordkeeping as a whole. To learn more about
          how we automatically collect personal information, see the section entitled Tracking
          Technologies.
        </p>
        <p>
          <strong>HOW WE USE PERSONAL INFORMATION WE COLLECT </strong>
        </p>
        <p>
          The personal information we collect allows us to provide you with our products and
          services, to operate and improve our website, and for other purposes described in this
          Policy or disclosed to you on our website or in connection with our products and services.
          For example, we may use the information we collect from you on our website:
        </p>
        <List className={classes.list}>
          {listItems1.map((item, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <ListItem alignItems="flex-start" className={classes.listItem} key={i}>
              <ListItemIcon className={classes.listItemIcon}>
                <Check htmlColor="#009be3" />
              </ListItemIcon>
              <ListItemText className={classes.listItemText} primary={item} />
            </ListItem>
          ))}
        </List>
        <p>
          Velocity EU Inc may store and process personal information in the United States and other
          countries.
        </p>
        <p>
          <strong>SHARING OF PERSONAL INFORMATION </strong>
        </p>
        <p>
          We use third parties such an email service provider to send out emails on our behalf. When
          you sign up for our products or services, we will share the personal information you
          provide only as necessary for the third party to provide that service. These third parties
          are obligated to protect your information. We do not sell your personal information to
          third parties. If this practice should change in the future we will update this policy and
          provide individuals with opt-out or opt-in choice as appropriate prior to sharing their
          personal data.
        </p>
        <p>
          We do not share your personal information with third parties other than as described in
          this privacy policy above and as follows:
        </p>
        <List className={classes.list}>
          {listItems2.map((item, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <ListItem alignItems="flex-start" className={classes.listItem} key={i}>
              <ListItemIcon className={classes.listItemIcon}>
                <Check htmlColor="#009be3" />
              </ListItemIcon>
              <ListItemText className={classes.listItemText} primary={item} />
            </ListItem>
          ))}
        </List>
        <p>
          <strong>EU-U.S. Privacy Shield and Swiss-U.S. Privacy Shield</strong>
        </p>
        <p>
          Velocity EU Inc complies with the EU-U.S. Privacy Shield Framework and the Swiss-U.S.
          Privacy Shield Framework as set forth by the U.S. Department of Commerce regarding the
          collection, use, and retention of personal information transferred from the European
          Union, United Kingdom, and Switzerland to the United States.
        </p>
        <p>
          Velocity EU Inc has certified to the Department of Commerce that we adhere to the Privacy
          Shield Principles of Notice, Choice, Accountability for Onward Transfer, Security, Data
          Integrity and Purpose Limitation, Access, and Recourse, Enforcement and Liability. If
          there is any conflict between the policies in this privacy policy and the Privacy Shield
          Principles, the Privacy Shield Principles shall govern. To learn more about the Privacy
          Shield program, and to view our certification page, please visit
          <a href="https://www.privacyshield.gov/">https://www.privacyshield.gov/</a>
        </p>
        <p>
          Our participation in the Privacy Shield applies to all personal data that is subject to
          this Privacy Policy and which is received from the EU and the European Union, European
          Economic Area, United Kingdom, and Switzerland. We will comply with the Privacy Shield
          Principles in respect of such personal data.
        </p>
        <p>
          Our accountability for personal data that we receive under the EU-U.S. and Swiss-U.S.
          Privacy Shield Frameworks and subsequently transfer to a third party is described in the
          Privacy Shield Principles. In particular, we remain responsible and liable under the
          Privacy Shield Principles if third-party agents that we engage to process the personal
          data on our behalf do so in a manner inconsistent with the Principles, unless we prove
          that we are not responsible for the event giving rise to the damage. Pursuant to the
          Privacy Shield, Velocity EU Inc remains liable for the transfer of personal data to third
          parties acting as our agents unless we can prove we were not a party to the events giving
          rise to the damages.
        </p>
        <p>
          We acknowledge that pursuant to the EU-U.S. and Swiss-US Privacy Shield Frameworks EU, UK
          and Swiss individuals have the right to access their data that is transferred into the
          United States. Data subjects can also update, amend or change inaccurate information and
          request deletion of data that is found to have been processed in violation of the Privacy
          Shield Principles. Individuals wishing to exercise this right may do so by emailing us at
          support@velocity-eu.com
        </p>
        <p>
          We encourage all European individuals (including Swiss and United Kingdom individuals) to
          contact us should you have a GDPR or Privacy Shield-related (or general privacy-related)
          complaint at support@velocity-eu.com.
        </p>
        <p>
          We have further committed to refer unresolved privacy complaints under the EU-U.S. Privacy
          Shield Principles or the Swiss-U.S. Privacy Shield Principles to BBB EU PRIVACY SHIELD, a
          non-profit alternative dispute resolution provider located in the United States. If you do
          not receive timely acknowledgment of your complaint, or if your complaint is not
          satisfactorily addressed, please visit
          https://bbbprograms.org/programs/all-programs/bbb-eu-privacy-shield-consumers/ProcessForConsumers
          for more information and to file a complaint. Please do not refer GDPR complaints to BBB
          Privacy Shield. If your Privacy Shield complaint cannot be resolved through the above
          channels, under certain conditions, you may invoke binding arbitration for some residual
          claims not resolved by other redress mechanisms. See Privacy Shield Annex 1 here:
          <a href="https://www.privacyshield.gov/article?id=ANNEX-I-introduction">link</a>.
        </p>
        <p>
          Velocity EU Inc is subject to the investigatory and enforcement powers of the U.S. Federal
          Trade Commission (FTC).
        </p>
        <p>
          <strong>INTERNATIONAL TRANSFERS</strong>
        </p>
        <p>
          As part of its GDPR compliance program, Velocity EU Inc now requires a data processing
          agreement with its data controllers containing the EU&rsquo;s Standard Contractual Clauses
          which covers the collection, use, retention and processing of personal information
          transferred from the European Union to the United States. Any questions regarding Velocity
          EU Inc&rsquo;s GDPR compliance should be submitted to support@velocity-eu.com.
        </p>
        <p>
          EU, UK and Swiss individuals with GDPR complaints also have the right to contact their
          local data privacy authority (DPA) or the Swiss Federal Data Protection and Information
          Commissioner (FDPIC). Please follow the links below for more information regarding these
          options:
        </p>
        <p>
          Swiss Individuals &mdash;
          <a href="https://www.edoeb.admin.ch/edoeb/en/home/the-fdpic/links/data-protection---switzerland.html">
            https://www.edoeb.admin.ch/edoeb/en/home/the-fdpic/links/data-protection&mdash;switzerland.html
          </a>
          <br />
          EU Individuals &mdash;
          <a href="https://edpb.europa.eu/about-edpb/board/members_en">
            https://edpb.europa.eu/about-edpb/board/members_en
          </a>
          <br />
          UK Individuals &mdash;
          <a href="https://ico.org.uk/">https://ico.org.uk/</a>
        </p>
        <p>
          <strong>CALIFORNIA CONSUMER PRIVACY ACT</strong>
        </p>
        <p>
          Effective on January 1, 2020, the California Consumer Privacy Act (CCPA) allows California
          residents to obtain certain information collected by the business with whom they have an
          established business relationship. If you are a California resident, you may exercise
          certain rights to access, restrict, or delete your personal information by submitting a
          request to support@velocity-eu.com.
        </p>
        <p>
          <strong>TRACKING TECHNOLOGIES</strong>
        </p>
        <p>
          Tracking technologies such as: cookies, beacons, tags and scripts are used by Velocity EU
          Inc, our website analytics and service partners such as Google Analytics, HubSpot or
          Salesforce. These technologies are used in analysing trends, administering the site,
          tracking users&rsquo; movements around the site and to gather demographic information
          about our user base as a whole. We may receive reports based on the use of these
          technologies by these companies on an individual as well as aggregated basis.
        </p>
        <p>
          We use cookies for saving users&rsquo; settings, improving navigation, and to provide more
          relevant information on our website. Users can control the use of cookies at the
          individual browser level. If you reject cookies, you may still use our site, but your
          ability to use some features or areas of our site may be limited.
        </p>
        <p>
          We gather certain information automatically and store it in log files. This information
          may include Internet Protocol (IP) addresses, browser type, Internet Service Provider
          (ISP), referring/exit pages, operating system, date/time stamp, and/or clickstream data.
          We may combine these log files with other information we collect about you. We do this to
          improve services we offer you, to improve marketing, analytics, or site functionality.
        </p>
        <p>
          We use Local Storage (LS) such as HTML5 to store content information to provide certain
          features on our website. Various browsers may offer their own management tools for
          removing HTML5 LS.
        </p>
        <p>
          We partner with a third party to manage our advertising on other sites. Our third party
          partner may use technologies such as cookies to gather information about your activities
          on this site and other sites in order to provide you advertising based upon your browsing
          activities and interests.
        </p>
        <p>
          <strong>SECURITY OF YOUR PERSONAL INFORMATION</strong>
        </p>
        <p>
          The security of your personal information is important to us and we are committed to
          ensuring that your information is secure. In order to prevent unauthorized access or
          disclosure, we have put in place reasonable suitable administrative, technical, and
          physical measures and procedures to safeguard and secure your personal information against
          loss, theft, and misuse, as well as against unauthorized access, disclosure, alteration,
          and destruction. Please be aware, however, that despite our efforts, no security measures
          are impenetrable. If you have any questions about security on our website, you can contact
          us at support@velocity-eu.com
        </p>
        <p>
          <strong>UPDATING AND ACCESSING YOUR PERSONAL INFORMATION </strong>
        </p>
        <p>
          If your personal information changes, we invite you to correct or update your information
          as soon as possible. You can request changes or access to your information by emailing
          support@velocity-eu.com. If you wish to cancel your account, request that we no longer use
          your information to provide you services or delete your information, contact us at
          support@velocity-eu.com. We will respond to your request to have your personal information
          deleted within thirty (30) days.
        </p>
        <p>
          <strong>DATA RETENTION</strong>
        </p>
        <p>
          We will retain your information for as long as your account is active or as needed to
          provide you services. If you wish to cancel your account or request that we no longer use
          your information to provide you services, contact us at support@velocity-eu.com We will
          retain and use your information as necessary to comply with our legal obligations, resolve
          disputes, and enforce our agreements.
        </p>
        <p>
          <strong>OPT-OUT</strong>
        </p>
        <p>
          If you wish to subscribe to our newsletter(s), we will use your name and email address to
          send the newsletter to you. Out of respect for your privacy, you may choose to stop
          receiving our newsletter or marketing emails by following the unsubscribe instructions
          included in these emails or you can contact us at support@velocity-eu.com
        </p>
        <p>
          <strong>CHILDREN</strong>
        </p>
        <p>
          We do not knowingly collect personal information from children under the age of thirteen
          (13). If we learn that we have collected the personal information of a child under the age
          of 13, we will take steps to delete the information as soon as possible.
        </p>
        <p>
          <strong>LINKS TO OTHER WEBSITES</strong>
        </p>
        <p>
          Our website may contain links to other websites, products and services of interest. In the
          event that our website includes links to other websites or sites whose privacy practices
          may differ from those of Velocity EU Inc, you should note that we do not have any control
          over that other website. Therefore, if you submit personal information to any of those
          sites, your information is governed by their privacy statements and we cannot be
          responsible for the protection and privacy of any information which you provide while
          visiting such sites and such sites are not governed by this privacy statement. We
          encourage you to carefully read the privacy statement of any site you visit.
        </p>
        <p>
          <strong>SOCIAL MEDIA WIDGETS</strong>
        </p>
        <p>
          Our Site includes Social Media Features, such as the Share this button or interactive
          mini-programs that run on our site. These Features may collect your IP address, which page
          you are visiting on our Site, and may set a cookie to enable the Feature to function
          properly. Social Media Features and Widgets are either hosted by a third party or hosted
          directly on our Site. Your interactions with these Features are governed by the privacy
          policy of the company providing it.
        </p>
        <p>
          <strong>PUBLIC FORUMS</strong>
        </p>
        <p>
          Our website offers publicly accessible blogs or community forums. You should be aware that
          any information you provide in these areas may be read, collected, and used by others who
          access them. To request removal of your personal information from our blog or community
          forum, contact us at support@velocity-eu.com In some cases, we may not be able to remove
          your personal information, in which case we will let you know if we are unable to do so
          and why.
        </p>
        <p>
          <strong>FRAMING</strong>
        </p>
        <p>
          Some of our pages utilize framing techniques to serve content to from our partners while
          preserving the look and feel of our website. Please be aware that you are providing your
          personal information to these third parties and not to Velocity EU Inc.
        </p>
        <p>
          <strong>TESTIMONIAL</strong>
        </p>
        <p>
          We display personal testimonials of satisfied customers on our website in addition to
          other endorsements. With your consent we may post your testimonial along with your name.
          If you wish to update or delete your testimonial, you can contact us at
          support@velocity-eu.com
        </p>
        <p>
          <strong>CHANGES TO THIS POLICY </strong>
        </p>
        <p>
          We may change this Policy from time to time by updating this page. If we make any material
          changes, we will notify you by email (sent to the e-mail address specified in your
          account) or by means of a notice on our website prior to the change becoming effective. We
          encourage you to periodically review this page for the latest information on our privacy
          practices.
        </p>
        <p>
          <strong>QUESTIONS ABOUT THIS POLICY </strong>
        </p>
        <p>
          If you have any questions about this Policy, please contact us at support@velocity-eu.com
        </p>
      </Grid>
    </HeroBlock>
  );
};

export default PrivacyPolicy;
