import React from 'react';
import { createStyles, makeStyles, styled } from '@material-ui/core/styles';
import {
  Box,
  AppBar,
  Toolbar,
  MenuItem,
  MenuList,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
  useScrollTrigger,
  Hidden,
} from '@material-ui/core';
import { UserIcon, EmailIcon } from 'components/common/SvgIcons';
import { triggerOpt } from 'common/helpers';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const Flag = require('react-flagpack');

const NavLink = styled(Button)({
  textTransform: 'capitalize',
  color: '#666',
  fontWeight: 600,
  padding: 0,
});

const useStyles = makeStyles(() => {
  const theme: any = useTheme();

  return createStyles({
    root: {
      height: (props: any) => (props.trigger ? theme.AppBarHeightSticky : theme.AppBarHeight),
      [theme.breakpoints.down('sm')]: {
        height: (props: any) =>
          props.trigger ? theme.AppBarHeightStickyMobile : theme.AppBarHeightMobile,
      },
    },
    appBar: {
      transition: 'all .1s linear',
      height: (props: any) => (props.trigger ? theme.AppBarHeightSticky : theme.AppBarHeight),
      [theme.breakpoints.down('sm')]: {
        height: (props: any) =>
          props.trigger ? theme.AppBarHeightStickyMobile : theme.AppBarHeightMobile,
      },
    },
    menuList: {
      display: 'flex',
      padding: 0,
      outline: 'none',
    },
    menuListItem: {
      marginRight: 24,
      [theme.breakpoints.down('sm')]: {
        marginRight: 8,
      },
    },
    menuItemLink: {
      minWidth: 24,
      '&:hover': {
        background: 'none',
      },
    },
    menuItemText: {
      fontWeight: 600,
      textTransform: 'none',
      fontSize: 13,
      marginTop: -1,
      display: 'inline-flex',
      alignItems: 'center',
    },
    menuItemIcon: {
      marginRight: 8,
      fontSize: 18,
    },
    toolbar: {
      justifyContent: 'space-between',
      padding: '0 24px',
      height: (props: any) => (props.trigger ? theme.AppBarHeightSticky : theme.AppBarHeight),
      minHeight: (props: any) => (props.trigger ? theme.AppBarHeightSticky : theme.AppBarHeight),
      [theme.breakpoints.down('sm')]: {
        padding: '0 16px',
        height: (props: any) =>
          props.trigger ? theme.AppBarHeightStickyMobile : theme.AppBarHeightMobile,
        minHeight: (props: any) =>
          props.trigger ? theme.AppBarHeightStickyMobile : theme.AppBarHeightMobile,
      },
      '& .MuiMenuItem-root': {
        minHeight: (props: any) => (props.trigger ? theme.AppBarHeightSticky : theme.AppBarHeight),
        [theme.breakpoints.down('sm')]: {
          minHeight: (props: any) =>
            props.trigger ? theme.AppBarHeightStickyMobile : theme.AppBarHeightMobile,
        },
      },
    },
  });
});

const AppTopBar: React.ElementType = props => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const trigger = useScrollTrigger(triggerOpt);
  const classes = useStyles({ trigger });

  return (
    <Box component="div" className={classes.root} id="AppTopBar">
      <AppBar className={classes.appBar} color="default" {...props}>
        <Toolbar variant="regular" className={classes.toolbar}>
          <MenuList variant="menu" className={classes.menuList}>
            <MenuItem className={classes.menuListItem} disableGutters>
              <NavLink
                disableRipple
                href="mailto: hello@velocity-eu.com"
                className={classes.menuItemLink}>
                <EmailIcon
                  className={classes.menuItemIcon}
                  style={{ fontSize: 22, ...(smDown && { marginRight: 0 }) }}
                />
                <Hidden smDown>
                  <Typography className={classes.menuItemText}>hello@velocity-eu.com</Typography>
                </Hidden>
              </NavLink>
            </MenuItem>

            <MenuItem className={classes.menuListItem} disableGutters>
              <NavLink disableRipple href="tel: +13312261818" className={classes.menuItemLink}>
                <Flag code="US" size="m" className={classes.menuItemIcon} />
                <Typography className={classes.menuItemText}>+1 (331) 2261818</Typography>
              </NavLink>
            </MenuItem>

            <MenuItem disableGutters>
              <NavLink disableRipple href="tel: +442033895556" className={classes.menuItemLink}>
                <Flag code="GB-UKM" size="m" className={classes.menuItemIcon} />
                <Typography className={classes.menuItemText}>+44 (20) 33895556</Typography>
              </NavLink>
            </MenuItem>
          </MenuList>

          <MenuList variant="menu" className={classes.menuList}>
            <MenuItem disableGutters>
              <NavLink
                disableRipple
                href="https://vapp.adminportal.pro/"
                className={classes.menuItemLink}>
                <UserIcon className={classes.menuItemIcon} />
                <Hidden smDown>
                  <Typography className={classes.menuItemText}>Control Panel</Typography>
                </Hidden>
              </NavLink>
            </MenuItem>
          </MenuList>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default AppTopBar;
