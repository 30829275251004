import React from 'react';
import { makeStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';
import sectionImg from 'assets/velocity-use-case-img3.png';

const HeroBlock = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4, 0, 8),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2, 0, 4),
  },
}));

const Highlight = styled('span')({
  color: '#f15236',
  fontWeight: 600,
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: theme.breakpoints.values['lg'],
      margin: '0 auto',
      padding: theme.spacing(0, 3),
      color: '#423f3f',
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
    label: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: 'normal',
      color: '#737373',
    },
    title: {
      fontSize: 34,
      fontWeight: 700,
    },
    text: {
      fontSize: 18,
      marginBottom: 16,
      display: 'inline-block',
    },
    imageGrid: {
      backgroundImage: `url(${sectionImg})`,
      backgroundSize: 'contain',
      backgroundPosition: 'center top',
      backgroundRepeat: 'no-repeat',
      [theme.breakpoints.down('sm')]: {
        backgroundSize: 'cover',
        marginBottom: 24,
        minHeight: 220,
      },
    },
    textGrid: {
      paddingLeft: 24,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
      },
    },
    list: {
      padding: '16px 0',
    },
    listItem: {
      paddingTop: 4,
      paddingBottom: 4,
    },
    listItemIcon: {
      minWidth: 32,
    },
    listItemText: {
      margin: 0,
      fontWeight: 600,
    },
  })
);

const UseCaseMedicalBlock2: React.FC = () => {
  const classes = useStyles();

  return (
    <HeroBlock component="div">
      <Grid container className={classes.root}>
        <Grid item xs={12} md={6} className={classes.imageGrid}></Grid>
        <Grid item xs={12} md={6} className={classes.textGrid}>
          <Typography variant="subtitle1" component="span" className={classes.text}>
            <Highlight>Velocity Cloud Desktop Premium</Highlight>
            {` delivers virtual desktops to provide streamlined access to clinical applications. IGEL Clients are fully compatible with Velocity Cloud Desktop. IGEL Thin clients increase clinician mobility while maintaining tight security parameters.`}
          </Typography>
          <Typography variant="subtitle1" component="span" className={classes.text}>
            {`With `}
            <Highlight>Velocity Cloud Desktop Premium</Highlight>
            {` and IGEL Terminals, healthcare professionals receive quick access to their desktops from anywhere within the building with a simple tap of their access card or ID badge, while maintaining enterprise-grade security. Medical staff can enjoy mobility with tap-in and tap-out badge access to patient records including high-fidelity imaging. And IT benefits from an easy to manage desktop environment that reduces management time required, lowers costs to provision desktops, and facilitates compliance with industry security requirements.`}
          </Typography>
        </Grid>
      </Grid>
    </HeroBlock>
  );
};

export default UseCaseMedicalBlock2;
