import React from 'react';
import AppTopBar from 'components/common/AppTopBar';
import AppNavigationBar from 'components/common/AppNavigationBar';
import EnvironmentalPolicy from 'components/EnvironmentalPolicy';
import Footer from 'components/common/Footer';
import CopyRight from 'components/common/CopyRight';

const EnvironmentalPolicyPage: React.FC = () => (
  <React.Fragment>
    <AppTopBar elevation={0} style={{ zIndex: 1201 }} />
    <AppNavigationBar />
    <EnvironmentalPolicy />
    <Footer />
    <CopyRight />
  </React.Fragment>
);

export default EnvironmentalPolicyPage;
