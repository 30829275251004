import React from 'react';
import { makeStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import { Box, Button, Grid, Hidden, Typography } from '@material-ui/core';
import sectionImg from 'assets/customer-deployment-scenarios-v2.png';

const HeroBlock = styled(Box)(({ theme }) => ({
  background: '#fff',
  padding: theme.spacing(8, 0),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(4, 0),
  },
}));

const SectionImg = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  objectPosition: 'left',
  display: 'block',
});

const NavLink = styled(Button)({
  textTransform: 'capitalize',
  color: '#fff',
  fontWeight: 600,
  padding: 0,
  flexShrink: 0,
  height: '100%',
  '& .MuiButton-label': {
    height: '100%',
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: theme.breakpoints.values['lg'],
      margin: '0 auto',
      padding: theme.spacing(0, 3),
      color: '#423f3f',
    },
    imgGrid: {
      padding: theme.spacing(0, 3, 0, 0),
      maxHeight: 310,
    },
    title: {
      fontWeight: 700,
      fontSize: 34,
    },
    subtitle: {
      fontSize: 18,
      lineHeight: '28px',
    },
  })
);

const FutureSection: React.FC = () => {
  const classes = useStyles();

  return (
    <HeroBlock component="div">
      <Grid container className={classes.root} justify="space-between">
        <Hidden smDown>
          <Grid item xs={12} md={6} lg={7} className={classes.imgGrid}>
            <NavLink href="/deployment-scenarios">
              <SectionImg src={sectionImg} alt="Velocity Section Image" />
            </NavLink>
          </Grid>
        </Hidden>
        <Grid item xs={12} md={6} lg={5}>
          <Typography variant="h3" component="h2" gutterBottom className={classes.title}>
            {`Keep your Business on track with Velocity's secure Cloud Virtual Desktop Infrastructure (VDI)`}
          </Typography>
          <br />
          <Typography
            variant="subtitle1"
            component="span"
            gutterBottom
            className={classes.subtitle}>
            {`Welcome to the future of IT using the very best in breed solutions to create a professional fully integrated data and communications workspace.`}
            <br />
            <br />
            {`Velocity seamlessly integrates a full corporate phone system (PBX) with your corporate desktop and provides your business with the ability to meet all legislative compliance, whilst giving you fully flexible business continuity.`}
          </Typography>
        </Grid>
      </Grid>
    </HeroBlock>
  );
};

export default FutureSection;
