import React from 'react';
import { useScrollTrigger } from '@material-ui/core';

/**
 * getHelp function to open chat bubble
 */
export const getHelp = (): void => {
  const element = document.documentElement.querySelector('.zsiq_floatmain');
  const event = new Event('click');
  if (element) {
    element.dispatchEvent(event);
  }
};

/**
 * trigger options
 */
export const triggerOpt = {
  disableHysteresis: true,
  threshold: 0,
};

/**
 * ColorOnScroll function component
 */
type Props = {
  children: React.ReactElement;
};

export const ColorOnScroll = (props: Props): React.ReactElement => {
  const { children } = props;
  const trigger = useScrollTrigger(triggerOpt);
  const location: any = window.location;

  return React.cloneElement(children, {
    ...(location?.pathname === '/' && { color: trigger ? 'primary' : 'transparent' }),
    position: trigger ? 'fixed' : 'absolute',
    elevation: trigger ? 3 : 0,
  });
};

export const scrollToElement = (selector: string): void => {
  const element = document.getElementById(selector);
  if (element !== null) {
    const headerOffset = 115;
    const elementPosition = element.offsetTop;
    const offsetPosition = elementPosition - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }
};
