import React from 'react';
import { makeStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';
import icoVirtualHost from 'assets/ico-virtual-host.svg';
import icoAzure from 'assets/ico-azure-cloud.svg';
import icoOffice from 'assets/ico-ms-office.svg';
import icoClock from 'assets/ico-clock.svg';
import icoServices from 'assets/ico-services.svg';
import { scrollToElement } from 'common/helpers';

const HeroBlock = styled(Box)(({ theme }) => ({
  background: '#fff',
  padding: theme.spacing(6, 0, 2),
}));

const Icon = styled('img')({
  maxWidth: '150px',
  objectFit: 'contain',
  objectPosition: 'center',
  marginBottom: '10px',
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: theme.breakpoints.values['lg'],
      margin: '0 auto',
      width: '100%',
    },
    text: {
      display: 'inline-block',
      padding: theme.spacing(0, 20),
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(0, 3),
      },
    },
    boxWrapper: {
      justifyContent: 'space-between',
      padding: theme.spacing(4),

      [theme.breakpoints.down('sm')]: {
        maxWidth: '70vw',
        margin: '0 auto',
      },
    },
    box: {
      position: 'relative',
      margin: '0 17px',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      boxShadow: '0px 0px 8px #ddd',
      borderRadius: '8px',
      padding: '40px 15px',
      color: '#f15b3f',
      textAlign: 'center',
      transition: 'box-shadow .3s',

      '&:hover': {
        boxShadow: '0px 0px 25px #ddd',
      },

      [theme.breakpoints.down('md')]: {
        flex: '0 0 calc(50% - 34px)',
        maxWidth: 'calc(50% - 34px)',
        marginBottom: '34px',
      },

      [theme.breakpoints.down('sm')]: {
        flex: '0 0 100%',
        maxWidth: '100%',
        margin: '0 0 34px',
      },
    },
    boxLink: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      cursor: 'pointer',

      '&:focus': {
        outline: 'none',
        boxShadow: '0px 0px 25px #ddd',
      },
    },
  })
);

const CloudFeatures: React.FC = () => {
  const classes = useStyles();

  return (
    <HeroBlock component="div">
      <Grid container className={classes.root} spacing={5} justify="space-between">
        <Grid
          item
          xs={12}
          style={{
            padding: '16px',
            textAlign: 'center',
          }}>
          <Typography variant="subtitle1" component="span" className={classes.text} gutterBottom>
            We partner with Microsoft Azure and Office 365, industry-leading cloud IT platforms, to
            deliver you a comprehensive and seamlessly-integrated IT environment. Select a plan from
            the 5 categories below to create a custom cloud IT system best suited for your
            organization.
          </Typography>
        </Grid>

        <Grid container className={classes.boxWrapper}>
          <Box className={classes.box}>
            <a
              onClick={() => scrollToElement('managed-virtual-desktop')}
              className={classes.boxLink}></a>
            <Icon src={icoVirtualHost} alt="Managed Virtual Desktop" />
            <Typography>Managed Virtual Desktop</Typography>
          </Box>

          <Box className={classes.box}>
            <a onClick={() => scrollToElement('microsoft-office')} className={classes.boxLink}></a>
            <Icon src={icoOffice} alt="Microsoft Office 365" />
            <Typography>Microsoft Office 365</Typography>
          </Box>

          <Box className={classes.box}>
            <a
              onClick={() => scrollToElement('azure-infrastructure')}
              className={classes.boxLink}></a>
            <Icon src={icoAzure} alt="Azure Infrastructure" />
            <Typography>Azure Infrastructure</Typography>
          </Box>

          <Box className={classes.box}>
            <a
              onClick={() => scrollToElement('help-desk-and-support')}
              className={classes.boxLink}></a>
            <Icon src={icoClock} alt="Help Desk and Support" />
            <Typography>Help Desk and Support</Typography>
          </Box>

          <Box className={classes.box}>
            <a
              onClick={() => scrollToElement('additional-services-and-add-ons')}
              className={classes.boxLink}></a>
            <Icon src={icoServices} alt="Additional Services and Add-ons" />
            <Typography>Additional Services and Add-ons</Typography>
          </Box>
        </Grid>
      </Grid>
    </HeroBlock>
  );
};

export default CloudFeatures;
