import React from 'react';
import AppTopBar from 'components/common/AppTopBar';
import EnterprisePBXBlock1 from 'components/EnterprisePBX/EnterprisePBXBlock1';
import EnterprisePBXBlock2 from 'components/EnterprisePBX/EnterprisePBXBlock2';
import EnterprisePBXBlock3 from 'components/EnterprisePBX/EnterprisePBXBlock3';
import RequestDemo from 'components/common/RequestDemo';
import MediaCardsBB from 'components/MediaCardsBB';
import Footer from 'components/common/Footer';
import CopyRight from 'components/common/CopyRight';
import AppNavigationBar from 'components/common/AppNavigationBar';

const EnterprisePBX: React.FC = () => (
  <React.Fragment>
    <AppTopBar elevation={0} style={{ zIndex: 1201 }} />
    <AppNavigationBar />
    <EnterprisePBXBlock1 />
    <EnterprisePBXBlock2 />
    <EnterprisePBXBlock3 />
    <RequestDemo />
    <MediaCardsBB style={{ marginBottom: 24 }} />
    <Footer />
    <CopyRight />
  </React.Fragment>
);

export default EnterprisePBX;
