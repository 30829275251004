import React from 'react';
import { makeStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';

const HeroBlock = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 0),
  [theme.breakpoints.down('sm')]: {
    padding: 0,
  },
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: theme.breakpoints.values['lg'],
      margin: '16px auto',
      padding: theme.spacing(0, 2),
      justifyContent: 'center',
    },
    heading: {
      textAlign: 'center',
      fontWeight: 600,
      fontSize: 34,
    },
  })
);
type Props = {
  text: string;
  background?: string;
  color?: string;
};
const HeadingSection: any = ({
  text = 'Example Heading',
  background = '#222',
  color = '#fff',
}: Props) => {
  const classes = useStyles();

  return (
    <HeroBlock component="div" style={{ background }}>
      <Grid container className={classes.root}>
        <Typography variant="h4" component="h2" className={classes.heading} style={{ color }}>
          {text}
        </Typography>
      </Grid>
    </HeroBlock>
  );
};

export default HeadingSection;
