import React from 'react';
import { makeStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';

const HeroBlock = styled(Box)(({ theme }) => ({
  padding: theme.spacing(6, 0),
  backgroundColor: '#fff',
  marginTop: theme.spacing(2),
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: theme.breakpoints.values['lg'],
      margin: '0 auto',
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
    label: {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: 'normal',
      color: '#f15236',
      marginBottom: 16,
    },
    heading: {
      fontSize: 34,
      fontWeight: 700,
      lineHeight: '36px',
      maxWidth: 1280,
      width: '100%',
      margin: theme.spacing(0, 'auto', 4),
      padding: theme.spacing(0, 3),
    },
    title: {
      fontWeight: 700,
      fontSize: 22,
      lineHeight: 1.4,
      color: '#423f3f',
    },
    text: {
      width: '100%',
      fontSize: 18,
      marginBottom: 16,
      display: 'inline-block',
    },
    question: {
      fontWeight: 600,
      fontSize: 20,
      lineHeight: 1.4,
      marginBottom: 16,
    },
    textGrid: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(0, 3),
    },
    shieldHeading: {
      color: '#f15236',
      fontSize: 30,
      fontWeight: 800,
      display: 'inline-flex',
      alignItems: 'center',
      alignSelf: 'flex-start',
    },
  })
);

const CloudDesktop5: React.FC = () => {
  const classes = useStyles();

  return (
    <HeroBlock component="div">
      <Grid container className={classes.root}>
        <Grid item xs={12} className={classes.textGrid}>
          <Typography variant="h4" component="h2" gutterBottom className={classes.title}>
            Can Velocity Cloud Desktop for WVD help me migrate my legacy VDI environments (Citrix,
            VMWare, Amazon WorkSpaces) to Microsoft native WVD?
          </Typography>

          <Typography variant="subtitle1" component="p" className={classes.text}>
            {`Yes, Velocity Cloud Desktop for WVD can help you deploy a brand new WVD environment and import your legacy VDI desktop image VMs.  These desktop images can then be used to provision WVD host pools and session hosts.`}
          </Typography>

          <br />

          <Typography variant="h4" component="h2" gutterBottom className={classes.title}>
            How does Velocity Cloud Desktop for WVD accelerate Microsoft WVD deployment in my
            organization?
          </Typography>

          <Typography variant="subtitle1" component="p" className={classes.text}>
            {`WVD deployment can be performed without any scripting by following a wizard driven process, which includes AD and file storage integration.`}
          </Typography>

          <Typography variant="subtitle1" component="p" className={classes.text}>
            {`Desktop Images can be created based on Azure Marketplace images or imported from existing VMs.`}
          </Typography>

          <Typography variant="subtitle1" component="p" className={classes.text}>
            {`Once a Desktop Image is created, it can be deployed as a static or dynamic host pool with session-based desktops, personal/persistent desktops, non-persistent single-user desktops or RemoteApps.`}
          </Typography>
        </Grid>
      </Grid>
    </HeroBlock>
  );
};

export default CloudDesktop5;
