import React from 'react';
import { makeStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';
import sectionImg from 'assets/velocity-use-case-img1.png';

const HeroBlock = styled(Box)({
  background: '#fff',
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: theme.breakpoints.values['lg'],
      margin: '0 auto',
      color: '#423f3f',
      padding: theme.spacing(8, 3),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(4, 3),
      },
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
    label: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: 'normal',
      color: '#737373',
    },
    title: {
      fontSize: 34,
      fontWeight: 700,
    },
    text: {
      fontSize: 18,
      paddingRight: 16,
      display: 'inline-block',
    },
    imgGrid: {
      backgroundImage: `url(${sectionImg})`,
      backgroundSize: 'contain',
      backgroundPosition: 'top',
      backgroundRepeat: 'no-repeat',
      backgroundColor: '#fff',
      [theme.breakpoints.down('sm')]: {
        backgroundImage: 'none',
      },
    },
  })
);

const UseCaseFinancialBlock1: React.FC = () => {
  const classes = useStyles();

  return (
    <HeroBlock component="div" id="VelocitySolutions">
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Typography variant="h5" component="span" className={classes.label} gutterBottom>
            Velocity Use Case
          </Typography>
          <br />

          <Typography variant="h4" component="h2" className={classes.title} gutterBottom>
            London UK, Global Financial Institution
          </Typography>
          <br />
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="subtitle1" component="span" gutterBottom className={classes.text}>
            {`Throughout the turmoil of 2020, like so many organisations, we have had to piece together a solution which provides our global distributed workforce with a full complement of secure IT and Telecoms connectivity, in order to allow them to work efficiently via remote working. Legislation dictated many of our locations could not be manned and to provide business continuity, staff were required to work from home.`}
            <br />
            <br />
          </Typography>
        </Grid>

        <Grid item xs={12} md={6} className={classes.imgGrid}></Grid>
      </Grid>
    </HeroBlock>
  );
};

export default UseCaseFinancialBlock1;
