import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Grid,
  Hidden,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const HeroBlock = styled(Box)(({ theme }) => ({
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  padding: theme.spacing(10, 4, 2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '16px auto 32px',
      maxWidth: theme.breakpoints.values['xl'],
      justifyContent: 'space-between',
    },
    paperBox: {
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
      overflow: 'hidden',
      height: '100%',
    },
    header: { padding: '12px 0 16px', background: '#c4462f', textAlign: 'center' },
    price: {
      padding: '12px 0 16px',
      background: '#fff',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      borderBottom: '1px solid #ddd',
      boxShadow: '0px 1px 2px #ddd',
      zIndex: 1,
      position: 'relative',
      minHeight: 100,
      boxSizing: 'border-box',
    },
    content: {
      padding: '12px 0 16px',
      background: '#fff',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
    },
    accordion: {
      border: 'none',
      boxShadow: 'none',
      '&:before': {
        display: 'none',
      },
      '&.Mui-expanded': {
        margin: 0,
      },
      '& .MuiAccordionSummary-root.Mui-expanded': {
        minHeight: 'auto',
      },
      '& .MuiAccordionSummary-content.Mui-expanded': {
        margin: 0,
      },
    },
    accordionDetails: {
      flexDirection: 'column',
      padding: 0,
    },
  })
);

const AzureInfrastructure: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const [expandP5, setExpandP5] = useState(false);
  const [expandP15, setExpandP15] = useState(false);
  const [expandP25, setExpandP25] = useState(false);
  const [expandEnt, setExpandEnt] = useState(false);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      expandOnResize(matches);
    }

    // Don't update the state on an unmounted component
    return function cleanup() {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matches]);

  const expandOnResize = (matches: boolean) => {
    if (!matches) {
      setExpandP5(true);
      setExpandP15(true);
      setExpandP25(true);
      setExpandEnt(true);
    } else {
      setExpandP5(false);
      setExpandP15(false);
      setExpandP25(false);
      setExpandEnt(false);
    }
  };

  const ListItemRow = ({
    primary,
    secondary,
    disabled,
  }: {
    primary: string;
    secondary?: string;
    disabled?: boolean;
  }) => (
    <ListItem component="div" style={{ paddingTop: 0, paddingBottom: 4 }}>
      <ListItemText
        style={{ paddingLeft: 28, position: 'relative' }}
        primary={
          <>
            {!disabled && (
              <CheckCircleIcon
                fontSize="small"
                style={{ color: '#00d07d', position: 'absolute', top: 2, left: 0 }}
              />
            )}
            <Typography
              variant="body1"
              component="span"
              style={{ fontWeight: 600, color: disabled ? '#a3b3ba' : '#423f3f' }}>
              {primary}
            </Typography>
          </>
        }
        secondary={
          secondary && (
            <Typography variant="body2" component="p" style={{ fontWeight: 600, color: '#6d8795' }}>
              {secondary}
            </Typography>
          )
        }
      />
    </ListItem>
  );

  return (
    <HeroBlock component="div" id="azure-infrastructure">
      <Typography
        gutterBottom
        variant="h4"
        component="h4"
        style={{ fontWeight: 600, color: '#423f3f' }}>
        Azure Infrastructure
      </Typography>
      <Grid container className={classes.root} spacing={3}>
        <Grid item xs={12} lg={3} md={6}>
          <Paper elevation={4} className={classes.paperBox}>
            <Box className={classes.header}>
              <Typography variant="h5" component="h5" style={{ fontWeight: 600, color: '#fff' }}>
                P5 (up to 5 desktops)
              </Typography>
            </Box>
            <Box className={classes.price}>
              <Typography
                variant="h5"
                component="span"
                style={{ fontWeight: 600, color: '#c4462f' }}>
                GBP 350.00
              </Typography>
              <Typography
                variant="body2"
                component="span"
                style={{ fontWeight: 600, color: '#6d8795' }}>
                Per month
              </Typography>
            </Box>
            <Box className={classes.content}>
              <List component="div" style={{ paddingTop: 0, paddingBottom: 0, width: '100%' }}>
                <ListItemRow primary="Daas enabled Azure subscription" />
                <ListItemRow primary="Azure uptime SLA: Up to 99.99%" />
                <ListItemRow primary="Shared storage: Starting at 128G SSD" />
                <ListItemRow primary="Self-service admin portal" />
                <ListItemRow primary="In-region backup" />
                <Accordion className={classes.accordion} expanded={expandP5}>
                  <Hidden mdUp>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      onClick={() => setExpandP5(!expandP5)}>
                      <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
                        {!expandP5 ? `Show more...` : `Show less...`}
                      </Typography>
                    </AccordionSummary>
                  </Hidden>
                  <AccordionDetails className={classes.accordionDetails}>
                    <ListItemRow
                      primary="Site-to-site VPN"
                      secondary="Up to 10 IPSec VPN tunnels"
                      disabled
                    />
                    <ListItemRow
                      primary="Intelligent Auto-scaling"
                      secondary="Optimize Azure VM spend based on demand"
                      disabled
                    />
                    <ListItemRow
                      primary="RDS collections with Azure Scale Sets"
                      secondary="Dynamically growing and shrinking RDS collections"
                      disabled
                    />
                    <ListItemRow
                      primary="VDI desktops"
                      secondary="Dedicated desktops VMs for individual users"
                      disabled
                    />
                    <ListItemRow
                      primary="Multi-region deployment capability"
                      secondary="Desktop resources closer to users"
                      disabled
                    />
                    <ListItemRow
                      primary="Hybrid Active Directory"
                      secondary="Extend existing AD to Azure"
                      disabled
                    />
                    <ListItemRow
                      primary="Active Directory Federation Services"
                      secondary="Enable single-sign on with Office 365"
                      disabled
                    />
                    <ListItemRow
                      primary="Out-of-region DR capability"
                      secondary="Protection against regional disasters"
                      disabled
                    />
                    <ListItemRow
                      primary="Firewall management tools"
                      secondary="Manage network access with Azure NSGs"
                      disabled
                    />
                    <ListItemRow
                      primary="Azure Hybrid Usage support"
                      secondary="Bring your own Windows Server licenses with SA"
                      disabled
                    />
                  </AccordionDetails>
                </Accordion>
              </List>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={3} md={6}>
          <Paper elevation={4} className={classes.paperBox}>
            <Box className={classes.header}>
              <Typography variant="h5" component="h5" style={{ fontWeight: 600, color: '#fff' }}>
                P15 (up to 15 desktops)
              </Typography>
            </Box>
            <Box className={classes.price}>
              <Typography
                variant="h5"
                component="span"
                style={{ fontWeight: 600, color: '#c4462f' }}>
                GBP 600.00
              </Typography>
              <Typography
                variant="body2"
                component="span"
                style={{ fontWeight: 600, color: '#678190' }}>
                Per month
              </Typography>
            </Box>
            <Box className={classes.content}>
              <List component="div" style={{ paddingTop: 0, paddingBottom: 0, width: '100%' }}>
                <ListItemRow primary="Daas enabled Azure subscription" />
                <ListItemRow primary="Azure uptime SLA: Up to 99.99%" />
                <ListItemRow primary="Shared storage: Starting at 128G SSD" />
                <ListItemRow primary="Self-service admin portal" />
                <ListItemRow primary="In-region backup" />
                <Accordion className={classes.accordion} expanded={expandP15}>
                  <Hidden mdUp>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      onClick={() => setExpandP15(!expandP15)}>
                      <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
                        {!expandP15 ? `Show more...` : `Show less...`}
                      </Typography>
                    </AccordionSummary>
                  </Hidden>
                  <AccordionDetails className={classes.accordionDetails}>
                    <ListItemRow
                      primary="Site-to-site VPN"
                      secondary="Up to 10 IPSec VPN tunnels"
                    />
                    <ListItemRow
                      primary="Intelligent Auto-scaling"
                      secondary="Optimize Azure VM spend based on demand"
                      disabled
                    />
                    <ListItemRow
                      primary="RDS collections with Azure Scale Sets"
                      secondary="Dynamically growing and shrinking RDS collections"
                      disabled
                    />
                    <ListItemRow
                      primary="VDI desktops"
                      secondary="Dedicated desktops VMs for individual users"
                      disabled
                    />
                    <ListItemRow
                      primary="Multi-region deployment capability"
                      secondary="Desktop resources closer to users"
                      disabled
                    />
                    <ListItemRow
                      primary="Hybrid Active Directory"
                      secondary="Extend existing AD to Azure"
                      disabled
                    />
                    <ListItemRow
                      primary="Active Directory Federation Services"
                      secondary="Enable single-sign on with Office 365"
                      disabled
                    />
                    <ListItemRow
                      primary="Out-of-region DR capability"
                      secondary="Protection against regional disasters"
                      disabled
                    />
                    <ListItemRow
                      primary="Firewall management tools"
                      secondary="Manage network access with Azure NSGs"
                      disabled
                    />
                    <ListItemRow
                      primary="Azure Hybrid Usage support"
                      secondary="Bring your own Windows Server licenses with SA"
                      disabled
                    />
                  </AccordionDetails>
                </Accordion>
              </List>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={3} md={6}>
          <Paper elevation={4} className={classes.paperBox}>
            <Box className={classes.header}>
              <Typography variant="h5" component="h5" style={{ fontWeight: 600, color: '#fff' }}>
                P25 (up to 25 desktops)
              </Typography>
            </Box>
            <Box className={classes.price}>
              <Typography
                variant="h5"
                component="span"
                style={{ fontWeight: 600, color: '#c4462f' }}>
                GBP 950.00
              </Typography>
              <Typography
                variant="body2"
                component="span"
                style={{ fontWeight: 600, color: '#678190' }}>
                Per month
              </Typography>
            </Box>
            <Box className={classes.content}>
              <List component="div" style={{ paddingTop: 0, paddingBottom: 0, width: '100%' }}>
                <ListItemRow primary="Daas enabled Azure subscription" />
                <ListItemRow primary="Azure uptime SLA: Up to 99.99%" />
                <ListItemRow primary="Shared storage: Starting at 128G SSD" />
                <ListItemRow primary="Self-service admin portal" />
                <ListItemRow primary="In-region backup" />
                <Accordion className={classes.accordion} expanded={expandP25}>
                  <Hidden mdUp>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      onClick={() => setExpandP25(!expandP25)}>
                      <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
                        {!expandP25 ? `Show more...` : `Show less...`}
                      </Typography>
                    </AccordionSummary>
                  </Hidden>
                  <AccordionDetails className={classes.accordionDetails}>
                    <ListItemRow
                      primary="Site-to-site VPN"
                      secondary="Up to 10 IPSec VPN tunnels"
                    />
                    <ListItemRow
                      primary="Intelligent Auto-scaling"
                      secondary="Optimize Azure VM spend based on demand"
                      disabled
                    />
                    <ListItemRow
                      primary="RDS collections with Azure Scale Sets"
                      secondary="Dynamically growing and shrinking RDS collections"
                      disabled
                    />
                    <ListItemRow
                      primary="VDI desktops"
                      secondary="Dedicated desktops VMs for individual users"
                      disabled
                    />
                    <ListItemRow
                      primary="Multi-region deployment capability"
                      secondary="Desktop resources closer to users"
                      disabled
                    />
                    <ListItemRow
                      primary="Hybrid Active Directory"
                      secondary="Extend existing AD to Azure"
                      disabled
                    />
                    <ListItemRow
                      primary="Active Directory Federation Services"
                      secondary="Enable single-sign on with Office 365"
                      disabled
                    />
                    <ListItemRow
                      primary="Out-of-region DR capability"
                      secondary="Protection against regional disasters"
                      disabled
                    />
                    <ListItemRow
                      primary="Firewall management tools"
                      secondary="Manage network access with Azure NSGs"
                      disabled
                    />
                    <ListItemRow
                      primary="Azure Hybrid Usage support"
                      secondary="Bring your own Windows Server licenses with SA"
                      disabled
                    />
                  </AccordionDetails>
                </Accordion>
              </List>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={3} md={6}>
          <Paper elevation={4} className={classes.paperBox}>
            <Box className={classes.header}>
              <Typography variant="h5" component="h5" style={{ fontWeight: 600, color: '#fff' }}>
                Enterprise
              </Typography>
            </Box>
            <Box className={classes.price} style={{ flexDirection: 'column', paddingBottom: 12 }}>
              <Typography variant="h5" component="p" style={{ fontWeight: 600, color: '#c4462f' }}>
                Starting at GBP 650.00
              </Typography>
              <Button
                style={{
                  border: '1px solid #26b6d8',
                  color: '#26b6d8',
                  marginTop: 4,
                  fontWeight: 600,
                  padding: '6px 16px',
                }}>
                Estimate Price
              </Button>
            </Box>
            <Box className={classes.content}>
              <List component="div" style={{ paddingTop: 0, paddingBottom: 0, width: '100%' }}>
                <ListItemRow primary="Daas enabled Azure subscription" />
                <ListItemRow primary="Azure uptime SLA: Up to 99.99%" />
                <ListItemRow primary="Shared storage: Starting at 128G SSD" />
                <ListItemRow primary="Self-service admin portal" />
                <ListItemRow primary="In-region backup" />
                <Accordion className={classes.accordion} expanded={expandEnt}>
                  <Hidden mdUp>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      onClick={() => setExpandEnt(!expandEnt)}>
                      <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
                        {!expandEnt ? `Show more...` : `Show less...`}
                      </Typography>
                    </AccordionSummary>
                  </Hidden>
                  <AccordionDetails className={classes.accordionDetails}>
                    <ListItemRow
                      primary="Site-to-site VPN"
                      secondary="Up to 10 IPSec VPN tunnels"
                    />
                    <ListItemRow
                      primary="Intelligent Auto-scaling"
                      secondary="Optimize Azure VM spend based on demand"
                    />
                    <ListItemRow
                      primary="RDS collections with Azure Scale Sets"
                      secondary="Dynamically growing and shrinking RDS collections"
                    />
                    <ListItemRow
                      primary="VDI desktops"
                      secondary="Dedicated desktops VMs for individual users"
                    />
                    <ListItemRow
                      primary="Multi-region deployment capability"
                      secondary="Desktop resources closer to users"
                    />
                    <ListItemRow
                      primary="Hybrid Active Directory"
                      secondary="Extend existing AD to Azure"
                    />
                    <ListItemRow
                      primary="Active Directory Federation Services"
                      secondary="Enable single-sign on with Office 365"
                    />
                    <ListItemRow
                      primary="Out-of-region DR capability"
                      secondary="Protection against regional disasters"
                    />
                    <ListItemRow
                      primary="Firewall management tools"
                      secondary="Manage network access with Azure NSGs"
                    />
                    <ListItemRow
                      primary="Azure Hybrid Usage support"
                      secondary="Bring your own Windows Server licenses with SA"
                    />
                  </AccordionDetails>
                </Accordion>
              </List>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </HeroBlock>
  );
};

export default AzureInfrastructure;
