import React from 'react';
import AppTopBar from 'components/common/AppTopBar';
import ResourcesBlock1 from 'components/Resources/ResourcesBlock1';
import ResourcesBlock2 from 'components/Resources/ResourcesBlock2';
import ResourcesBlock3 from 'components/Resources/ResourcesBlock3';
import ResourcesBlock4 from 'components/Resources/ResourcesBlock4';
import ResourcesBlock5 from 'components/Resources/ResourcesBlock5';
import ResourcesBlock6 from 'components/Resources/ResourcesBlock6';
import ResourcesBlock7 from 'components/Resources/ResourcesBlock7';
import ResourcesBlock8 from 'components/Resources/ResourcesBlock8';
import Footer from 'components/common/Footer';
import CopyRight from 'components/common/CopyRight';
import AppNavigationBar from 'components/common/AppNavigationBar';
import { styled } from '@material-ui/core/styles';

const ResourcesPage: React.FC = () => (
  <React.Fragment>
    <AppTopBar elevation={0} style={{ zIndex: 1201 }} />
    <AppNavigationBar />
    <ResourcesBlock1 />
    <ResourcesBlock2 />
    <ResourcesBlock3 />
    <ResourcesBlock4 />
    <ResourcesBlock5 />
    <ResourcesBlock6 />
    <ResourcesBlock7 />
    <ResourcesBlock8 />
    <Footer />
    <CopyRight />
  </React.Fragment>
);
const Resources = styled(ResourcesPage)({
  backgroundColor: '#fff',
});

export default Resources;
