import React from 'react';
import { makeStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import sectionImg from 'assets/pbx-shield.png';
import { Check } from '@material-ui/icons';

const HeroBlock = styled(Box)(({ theme }) => ({
  padding: theme.spacing(6, 0),
  backgroundColor: '#f8f8f8',
  marginTop: theme.spacing(2),
}));

const ShieldImg = styled('img')(({ theme }) => ({
  marginRight: 24,
  height: 80,
  [theme.breakpoints.down('sm')]: {
    height: 56,
  },
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: theme.breakpoints.values['lg'],
      margin: '0 auto',
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
    label: {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: 'normal',
      color: '#f15236',
      marginBottom: 16,
    },
    heading: {
      fontSize: 34,
      fontWeight: 700,
      lineHeight: '36px',
      maxWidth: 1280,
      width: '100%',
      margin: theme.spacing(0, 'auto', 4),
      padding: theme.spacing(0, 3),
    },
    title: {
      fontWeight: 700,
      fontSize: 20,
      lineHeight: 1.4,
      color: '#423f3f',
    },
    text: {
      fontSize: 18,
      lineHeight: 1.4,
      marginBottom: 16,
    },
    question: {
      fontWeight: 600,
      fontSize: 20,
      lineHeight: 1.4,
      marginBottom: 16,
    },
    textGrid: {
      display: 'flex',
      padding: theme.spacing(0, 3),
    },
    shieldHeading: {
      color: '#f15236',
      fontSize: 28,
      fontWeight: 800,
      display: 'inline-flex',
      alignItems: 'center',
      alignSelf: 'flex-start',
    },
    list: {
      padding: '24px 0 16px',
      [theme.breakpoints.down('sm')]: {
        padding: '8px 0 32px',
      },
    },
    listItem: {
      paddingTop: 4,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      alignItems: 'flex-start',
    },
    listItemIcon: {
      minWidth: 32,
      minHeight: 27,
      alignItems: 'flex-end',
    },
    listItemText: {
      margin: 0,
    },
  })
);

const automateItems = [
  '2-Hour Deployment & Classic to ARM Migration',
  '3-Click Complete UI',
  'User Session Management',
  'FSLogix Storage Deployment & Management',
  'Image Creation & Deployment',
  'Scripted Actions',
  'MSIX Packaging & AppAttach Delivery',
  'REST API for ITSM Integration',
];

const optimizeItems = [
  'Robust Auto-Scale',
  'Auto-Provisioning & Storage Scaling',
  'Save up to 75% on Compute & Storage Costs',
  'Customize & Optimize Thousands of VMs',
  'Auto-heal',
  'Measure & Report Costs & Utilization',
  'Cost & Performance Optimization Advice',
  'Performance Monitoring & Alerting',
];

const protectItems = [
  'No 3rd Party Access',
  'Data Residency Controls',
  'RBAC Admin Roles',
  'End-user Self-service portal',
  'Resilience with Availability Zones',
  'Multi-tenant & Multi-Region Deployments',
  'Desktop Image Versioning',
  'PaaS App',
];

const CloudDesktop3: React.FC = () => {
  const classes = useStyles();

  return (
    <HeroBlock component="div">
      <Grid container className={classes.root}>
        <Typography gutterBottom variant="h4" component="h2" className={classes.heading}>
          Velocity Cloud Desktop features in addition to Microsoft Native WVD
        </Typography>
        <Grid item xs={12} md={6} className={classes.textGrid}>
          <Typography
            variant="subtitle1"
            component="h3"
            gutterBottom
            className={classes.shieldHeading}>
            <ShieldImg src={sectionImg} alt="" />
            Simplify &amp; Automate
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} className={classes.textGrid}>
          <List className={classes.list}>
            {automateItems.map((item, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <ListItem className={classes.listItem} key={i}>
                <ListItemIcon className={classes.listItemIcon}>
                  <Check htmlColor="#009be3" />
                </ListItemIcon>
                <ListItemText
                  className={classes.listItemText}
                  primary={<span style={{ fontWeight: 600, fontSize: 18 }}>{item}</span>}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={12} md={6} className={classes.textGrid}>
          <Typography
            variant="subtitle1"
            component="h3"
            gutterBottom
            className={classes.shieldHeading}>
            <ShieldImg src={sectionImg} alt="" />
            Scale &amp; Optimize
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} className={classes.textGrid}>
          <List className={classes.list}>
            {optimizeItems.map((item, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <ListItem className={classes.listItem} key={i}>
                <ListItemIcon className={classes.listItemIcon}>
                  <Check htmlColor="#009be3" />
                </ListItemIcon>
                <ListItemText
                  className={classes.listItemText}
                  primary={<span style={{ fontWeight: 600, fontSize: 18 }}>{item}</span>}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={12} md={6} className={classes.textGrid}>
          <Typography
            variant="subtitle1"
            component="h3"
            gutterBottom
            className={classes.shieldHeading}>
            <ShieldImg src={sectionImg} alt="" />
            Secure &amp; Protect
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} className={classes.textGrid}>
          <List className={classes.list}>
            {protectItems.map((item, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <ListItem className={classes.listItem} key={i}>
                <ListItemIcon className={classes.listItemIcon}>
                  <Check htmlColor="#009be3" />
                </ListItemIcon>
                <ListItemText
                  className={classes.listItemText}
                  primary={<span style={{ fontWeight: 600, fontSize: 18 }}>{item}</span>}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </HeroBlock>
  );
};

export default CloudDesktop3;
