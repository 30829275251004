import React from 'react';
import { makeStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';

const HeroBlock = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8, 0),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(4, 0),
  },
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: theme.breakpoints.values['lg'],
      margin: '0 auto',
      padding: theme.spacing(0, 3),
      color: '#423f3f',
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
    label: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: 'normal',
      color: '#737373',
    },
    title: {
      fontSize: 34,
      fontWeight: 700,
      marginBottom: 32,
    },
    text: {
      fontSize: 20,
      marginBottom: 24,
    },
    quote: {
      fontSize: 20,
      fontWeight: 600,
      fontStyle: 'italic',
      marginBottom: 24,
    },
  })
);

const AboutUsBlock1: React.FC = () => {
  const classes = useStyles();

  return (
    <HeroBlock component="div">
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Typography variant="h5" component="span" className={classes.label}>
            Velocity
          </Typography>

          <Typography variant="h4" component="h2" className={classes.title}>
            About Us
          </Typography>

          <Typography variant="body1" component="p" className={classes.quote}>
            {`“It was emotional”`}
            <Typography variant="body1" component="span" className={classes.text}>
              {` - Velocity’s CEO`}
            </Typography>
          </Typography>

          <Typography variant="body1" component="p" className={classes.text}>
            {`Our founders started this exiting journey 20 Years ago from a small but vibrant office in the newly redeveloped area of London “Docklands”`}
          </Typography>
          <Typography variant="body1" component="p" className={classes.text}>
            <strong>{`This is our journey from Terminal Server to Velocity Cloud Desktop`}</strong>
          </Typography>
        </Grid>
      </Grid>
    </HeroBlock>
  );
};

export default AboutUsBlock1;
