import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  FormControl,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  darken,
  FormHelperText,
} from '@material-ui/core';
import { CheckBox } from '@material-ui/icons';
import { ChatIcon } from 'components/common/SvgIcons';
import { getHelp } from 'common/helpers';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const Flag = require('react-flagpack');

const HeroBlock = styled(Box)(({ theme }) => ({
  background: '#f8f8f8',
  padding: theme.spacing(8, 0),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(4, 0),
  },
}));

const CTAButton: any = styled(Button)((props: any) => ({
  width: props.fullWidth ? 'calc(100% - 40px)' : 'auto',
  color: props.textColor ? props.textColor : '#fff',
  padding: props.buttonPadding ? props.buttonPadding : '8px 0',
  marginTop: 20,
  marginLeft: 20,
  marginRight: props.marginRight ? props.marginRight : 20,
  background: props.background ? props.background : '#ccc',
  fontWeight: 600,
  fontSize: '18px',
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
  borderRadius: 0,
  position: 'relative',
  '&.Mui-disabled': {
    background: props.background ? darken(props.background, 0.2) : darken('#bbb', 0.2),
    '&::before, &::after': {
      background: props.background ? darken(props.background, 0.2) : darken('#bbb', 0.2),
    },
  },
  '&:hover': {
    background: props.backgroundHover ? props.backgroundHover : '#bbb',
    '&::before': { background: props.backgroundHover ? props.backgroundHover : '#bbb' },
    '&::after': { background: props.backgroundHover ? props.backgroundHover : '#bbb' },
  },
  '&::before': {
    width: 40,
    content: '""',
    display: 'block',
    background: props.background ? props.background : '#ccc',
    position: 'absolute',
    left: '-20px',
    top: '0',
    bottom: '0',
    right: 'auto',
    borderRadius: '50%',
    transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  '&::after': {
    width: 40,
    content: '""',
    display: 'block',
    background: props.background ? props.background : '#ccc',
    position: 'absolute',
    right: '-20px',
    top: '0',
    bottom: '0',
    left: 'auto',
    borderRadius: '50%',
    transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  '&>.MuiButton-label': {
    position: 'relative',
    zIndex: 1,
  },
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: theme.breakpoints.values['lg'],
      margin: '0 auto',
      padding: theme.spacing(0, 3),
      '& .MuiInput-underline:before': {
        borderColor: '#bbb',
      },
      '& .MuiTypography-body1': {
        fontSize: 18,
      },
    },
    grid: {
      paddingRight: '32px',
      [theme.breakpoints.down('sm')]: {
        marginBottom: 24,
      },
    },
    grid2: {
      padding: 32,
      backgroundColor: '#1a1d20',
    },
    margin: {
      margin: theme.spacing(0, 0, 1),
      '& .MuiInputLabel-formControl': {
        color: '#bbb',
      },
      '& .MuiInputBase-input': {
        color: '#fff',
      },
    },
    title: {
      fontWeight: 800,
      fontSize: 44,
      color: '#423f3f',
    },
    textBox: {
      color: '#423f3f',
    },
    formTitle: {
      fontWeight: 600,
      color: '#fff',
    },
    textIcon: {
      width: 20,
      marginRight: 10,
    },
    list: {
      padding: '16px 0',
    },
    listItem: {
      paddingTop: 4,
      paddingBottom: 4,
      display: 'flex',
      alignItems: 'flex-start',
    },
    listItemIcon: {
      minWidth: 32,
    },
    listItemText: {
      margin: 0,
    },
    checkboxError: {
      fontSize: 14,
      marginTop: 12,
    },
  })
);

const listItems: any = [
  {
    name: 'guidance',
    text: 'Guidance on building a proof of concept.',
  },
  {
    name: 'portal',
    text:
      'One-on-one demos to help you get started in the Velocity portal, create, and deploy your first Cloud Desktop Workspace.',
  },
  {
    name: 'pbx',
    text: 'Enable your corporate PBX.',
  },
  {
    name: 'assistance',
    text:
      'Assistance for migrating applications and infrastructure to the cloud and optimizing your environment.',
  },
  {
    name: 'info',
    text: 'Information on pricing and cost-optimisation.',
  },
  {
    name: 'conversation',
    text:
      'To start a conversation, fill out the form. We will be in touch within one business day.',
  },
];

const RequestDemo: React.FC = () => {
  const classes = useStyles();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState(false);
  const errorMsg = 'Enter a value for this field.';
  const formAction =
    'https://forms.zohopublic.eu/velocityeuinc/form/ContactForm/formperma/qEJUZsAlJCreHdok-sR3FWBMC5bzUgKmQP7mTUy5kWM/htmlRecords/submit';
  const redirectUrl = `${window.location.origin}/thank-you`;
  const [helpFor, setHelpFor] = useState('');
  const [checkboxes, setCheckboxes] = useState<any>({
    guidance: true,
    portal: false,
    pbx: false,
    assistance: false,
    info: false,
    conversation: false,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckboxes({ ...checkboxes, [event.target.name]: event.target.checked });
  };

  useEffect(() => {
    const help: any = [];
    for (const [key, value] of Object.entries(checkboxes)) {
      if (value) {
        listItems.forEach((element: any) => {
          if (element.name === key) {
            help.push(element.text);
          }
        });
      }
    }
    setHelpFor(help.join('; '));
  }, [checkboxes]);

  return (
    <HeroBlock component="div" id="VelocityRequestQuote">
      <Grid container className={classes.root} alignItems="center">
        <Grid item xs={12} md={7} className={classes.grid}>
          <Typography variant="h4" component="h2" gutterBottom className={classes.title}>
            Contact the Velocity Team
          </Typography>
          <Box className={classes.textBox}>
            <Typography variant="body1">
              Get a personalised introduction to the Velocity Cloud Workspace.
            </Typography>
            <Typography variant="body1">
              Guidance and answers to questions you may have whether you are evaluating the cloud
              virtual desktop infrastructure or migrating from legacy VDI (Virtual Desktop
              Infrastructure).
            </Typography>
            <CTAButton
              variant="contained"
              disableElevation
              type="button"
              href="tel: +13312261818"
              marginRight="44px"
              textColor="#fff"
              background="#009be3"
              backgroundHover="#007be3">
              <Flag code="US" size="m" className={classes.textIcon} />
              {`Call +1 (331) 2261818`}
            </CTAButton>
            <CTAButton
              variant="contained"
              disableElevation
              type="button"
              href="tel: +442033895556"
              marginRight="44px"
              textColor="#fff"
              background="#009be3"
              backgroundHover="#007be3">
              <Flag code="GB-UKM" size="m" className={classes.textIcon} />
              {`Call +44 (20) 33895556`}
            </CTAButton>
            <CTAButton
              variant="contained"
              disableElevation
              type="button"
              onClick={getHelp}
              textColor="#fff"
              background="#009be3"
              backgroundHover="#007be3">
              <ChatIcon className={classes.textIcon} />
              {`Chat with Us`}
            </CTAButton>
            <br />
            <br />
            <Typography variant="body1">
              Velocity specialists provide (choose at least one):
            </Typography>
            <List className={classes.list}>
              {listItems.map((item: any, i: number) => (
                // eslint-disable-next-line react/no-array-index-key
                <ListItem className={classes.listItem} key={i}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <Checkbox
                      checked={checkboxes[item.name]}
                      style={{ padding: '2px 0' }}
                      icon={<CheckBox color="disabled" />}
                      checkedIcon={<CheckBox style={{ color: '#009be3' }} />}
                      inputProps={{ name: item.name }}
                      onChange={handleChange}
                    />
                  </ListItemIcon>
                  <ListItemText className={classes.listItemText} primary={item.text} />
                </ListItem>
              ))}
            </List>
          </Box>
        </Grid>
        <Grid item xs={12} md={5} className={classes.grid2}>
          <form
            action={formAction}
            name="form"
            id="form"
            method="POST"
            acceptCharset="UTF-8"
            encType="multipart/form-data">
            <Typography variant="h4" component="h2" gutterBottom className={classes.formTitle}>
              {`Yes, I’d like a Velocity specialist to contact me`}
            </Typography>
            {/* To Track referrals , place the referrer name within the " " in the above hidden input field */}
            <TextField type="hidden" name="zf_referrer_name" value="" />
            {/* To redirect to a specific page after record submission , place the respective url within the " " in the above hidden input field */}
            <TextField type="hidden" name="zf_redirect_url" value={redirectUrl} />
            <TextField type="hidden" name="zc_gad" value="">
              {/* If GCLID is enabled in Zoho CRM Integration, click details of AdWords Ads will be pushed to Zoho CRM */}
            </TextField>
            <TextField type="hidden" name="SubjectOfTheRequest" value={helpFor} />
            <FormControl fullWidth className={classes.margin}>
              <TextField
                required
                fullWidth
                helperText={error ? errorMsg : ''}
                label="First Name"
                name="Name_First"
                defaultValue=""
                style={{ padding: '8px 0' }}
              />
            </FormControl>
            <FormControl fullWidth className={classes.margin}>
              <TextField
                required
                fullWidth
                helperText={error ? errorMsg : ''}
                label="Last Name"
                name="Name_Last"
                defaultValue=""
                style={{ padding: '8px 0' }}
              />
            </FormControl>
            <FormControl fullWidth className={classes.margin}>
              <TextField
                required
                fullWidth
                helperText={error ? errorMsg : ''}
                label="Company Name"
                name="SingleLine"
                defaultValue=""
                style={{ padding: '8px 0' }}
              />
            </FormControl>
            <FormControl fullWidth className={classes.margin}>
              <TextField
                required
                fullWidth
                helperText={error ? errorMsg : ''}
                label="Email"
                name="Email"
                defaultValue=""
                style={{ padding: '8px 0' }}
              />
            </FormControl>
            <FormControl fullWidth className={classes.margin}>
              <TextField
                label="Telephone Number"
                name="PhoneNumber_countrycode"
                defaultValue=""
                style={{ padding: '8px 0' }}
              />
            </FormControl>
            <CTAButton
              fullWidth
              variant="contained"
              disableElevation
              type="submit"
              buttonPadding="12px 20px"
              textColor="#fff"
              background="#f15236"
              backgroundHover="#ff0f0f"
              disabled={!helpFor ? true : false}
              style={{ textTransform: 'uppercase' }}>
              Request a Call
            </CTAButton>
            {!helpFor && (
              <FormHelperText error className={classes.checkboxError}>
                Please, check the necessary options that our specialists can help you with.
              </FormHelperText>
            )}
          </form>
        </Grid>
      </Grid>
    </HeroBlock>
  );
};

export default RequestDemo;
