import React from 'react';
import { makeStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { Check } from '@material-ui/icons';

const HeroBlock = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 0),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: theme.breakpoints.values['lg'],
      margin: '16px auto',
      padding: theme.spacing(1, 2),
      fontSize: 18,
      flexDirection: 'column',
    },
    title: {
      fontWeight: 800,
      fontSize: 44,
      color: '#423f3f',
    },
    textBox: {
      color: '#423f3f',
    },
    textIcon: {
      width: 20,
      marginRight: 12,
    },
    list: {
      padding: '16px 0',
    },
    listItem: {
      paddingTop: 4,
      paddingBottom: 4,
    },
    listItemIcon: {
      minWidth: 32,
      marginTop: 2,
    },
    listItemText: {
      margin: 0,
      fontSize: 18,
    },
  })
);

const listItems1 = [
  'Recycling waste material in the most appropriate ways',
  'Wherever possible ensuring working practices involve reducing wastage such as turning lights off where not required',
  'Making sure taps are not left running therefore not wasting water',
  'Using safe and careful driving practices to reduce emissions This will give you some idea of the requirements although the list is not exhaustive',
];

const EnvironmentalPolicy: React.FC = () => {
  const classes = useStyles();

  return (
    <HeroBlock component="div">
      <Grid container className={classes.root}>
        <Typography gutterBottom variant="h2" component="h2">
          Environmental Policy
        </Typography>
        <p>
          Velocity EU Inc will at all times achieve the highest level of environmental control. It
          will also ensure that it adheres to its own Environmental Management Systems, Process
          Controls and Procedures and strive to improve them continuously. The Company is committed
          to applying Good Professional Practice throughout its organisation and to fulfilling a
          high standard of performance and the expectations of its customers.
        </p>
        <p>
          All staff must familiarize themselves and regularly update themselves on the
          Company&rsquo;s standards of environmental management and are required to take a positive
          attitude to minimising the company impact on the environment.
        </p>
        <p>
          The Company is totally committed to comply with BS EN ISO14001 Environmental Management
          Systems &ndash; Requirements, (and all associated legislation and codes of practice,) and
          will at all times ensure that its own systems comply with all the requirement of that
          standard.
        </p>
        <p>
          The company operates a system that regularly evaluates its processes and customer needs
          and has set quantifiable objectives with plans in place to ensure that they are reviewed
          year on year for improvement. The company is committed to continually improving the
          effectiveness of the environmental management systems, and to prevent pollution within the
          company working environment, and to the wider external environment.
        </p>
        <p>
          It is Everyone&rsquo;s Job to Protect the Environment, Protect the land, the air, and the
          water by preventing or reducing pollution It is your job as employees of this company to
          ensure you make every effort to support the aims of the company in its endeavours to
          comply with ISO 14001.
        </p>
        <p>This can be achieved through your continual efforts with regards to;</p>
        <List className={classes.list}>
          {listItems1.map((item, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <ListItem alignItems="flex-start" className={classes.listItem} key={i}>
              <ListItemIcon className={classes.listItemIcon}>
                <Check htmlColor="#009be3" />
              </ListItemIcon>
              <ListItemText className={classes.listItemText} primary={item} />
            </ListItem>
          ))}
        </List>
        <p>
          Organizations are becoming increasingly concerned in achieving &amp; demonstrating sound
          environmental performance, often delivering cost savings for the company. After
          implementation of the system, our Management team will conduct management review to ensure
          continued effectiveness of the system along with internal audits to ensure the company is
          complying with its obligations under 14001
        </p>
      </Grid>
    </HeroBlock>
  );
};

export default EnvironmentalPolicy;
