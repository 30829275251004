import React from 'react';
import { makeStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import { Box, Grid, Hidden, Typography } from '@material-ui/core';
import sectionImg from 'assets/cloud-desktop/wvd-logo.png';

const HeroBlock = styled(Box)(({ theme }) => ({
  background: '#1a1d20',
  padding: theme.spacing(8, 0),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(4, 0),
  },
}));

const SectionImg = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  objectPosition: 'left',
  display: 'block',
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: theme.breakpoints.values['lg'],
      margin: '0 auto',
      padding: theme.spacing(0, 3),
      color: '#423f3f',
    },
    imgGrid: {
      padding: theme.spacing(0, 5, 0, 0),
      maxHeight: 310,
    },
    title: {
      fontWeight: 700,
      fontSize: 34,
      color: '#fff',
    },
    subtitle: {
      fontSize: 18,
      lineHeight: '28px',
      color: '#fff',
    },
  })
);

const CloudDesktop2: React.FC = () => {
  const classes = useStyles();

  return (
    <HeroBlock component="div">
      <Grid container className={classes.root} justify="space-between">
        <Hidden smDown>
          <Grid item xs={12} md={6} className={classes.imgGrid}>
            <SectionImg src={sectionImg} alt="Velocity Section Image" />
          </Grid>
        </Hidden>
        <Grid item xs={12} md={6}>
          <Typography variant="h3" component="h2" gutterBottom className={classes.title}>
            {`Why Velocity Cloud Desktop for Microsoft WVD?`}
          </Typography>
          <br />
          <Typography
            variant="subtitle1"
            component="span"
            gutterBottom
            className={classes.subtitle}>
            {`Velocity Cloud Desktop extends the native admin experience capabilities of Windows Virtual Desktop, while allowing customers to leverage the significant investments Microsoft is making into the WVD service. Unlike other virtual desktop solutions, Velocity Cloud Desktop does not replace Microsoft’s native components but enhances them with best-in-class enterprise capabilities.`}
          </Typography>
        </Grid>
      </Grid>
    </HeroBlock>
  );
};

export default CloudDesktop2;
