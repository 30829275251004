import React from 'react';
import AppTopBar from 'components/common/AppTopBar';
import UseCaseEngineeringBlock1 from 'components/UseCase/UseCaseEngineeringBlock1';
import UseCaseEngineeringBlock2 from 'components/UseCase/UseCaseEngineeringBlock2';
import Footer from 'components/common/Footer';
import CopyRight from 'components/common/CopyRight';
import AppNavigationBar from 'components/common/AppNavigationBar';

const UseCaseEngineering: React.FC = () => (
  <React.Fragment>
    <AppTopBar elevation={0} style={{ zIndex: 1201 }} />
    <AppNavigationBar />
    <UseCaseEngineeringBlock1 />
    <UseCaseEngineeringBlock2 />
    <Footer />
    <CopyRight />
  </React.Fragment>
);

export default UseCaseEngineering;
