import React from 'react';
import { makeStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';

const HeroBlock = styled(Box)({
  background: '#f15236',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: theme.breakpoints.values['lg'],
      margin: '16px auto',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: 48,
    },
  })
);

const CopyRight: React.FC = () => {
  const classes = useStyles();

  return (
    <HeroBlock component="div">
      <Grid container className={classes.root}></Grid>
    </HeroBlock>
  );
};

export default CopyRight;
