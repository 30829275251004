import React from 'react';
import { makeStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';
import sectionImg from 'assets/pbx-shield.png';

const HeroBlock = styled(Box)(({ theme }) => ({
  padding: theme.spacing(6, 0, 3),
  backgroundColor: '#f8f8f8',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    paddingBottom: theme.spacing(2),
  },
}));

const ShieldImg = styled('img')(({ theme }) => ({
  marginRight: 24,
  height: 80,
  [theme.breakpoints.down('sm')]: {
    height: 56,
  },
}));

const Highlight = styled('span')(({ theme }) => ({
  color: '#f15236',
  fontWeight: 600,
  marginTop: 24,
  display: 'inline-block',
  [theme.breakpoints.down('sm')]: {
    marginTop: 0,
  },
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: theme.breakpoints.values['lg'],
      margin: '0 auto',
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
    label: {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: 'normal',
      color: '#f15236',
      marginBottom: 16,
    },
    heading: {
      fontSize: 34,
      fontWeight: 700,
      lineHeight: '36px',
      maxWidth: 1280,
      width: '100%',
      margin: theme.spacing(0, 'auto', 4),
      padding: theme.spacing(0, 3),
    },
    title: {
      fontWeight: 700,
      fontSize: 20,
      lineHeight: 1.4,
      color: '#423f3f',
    },
    text: {
      width: '100%',
      fontSize: 18,
      marginBottom: 16,
      [theme.breakpoints.down('sm')]: {
        marginBottom: 32,
      },
    },
    question: {
      fontWeight: 600,
      fontSize: 20,
      lineHeight: 1.4,
      marginBottom: 16,
    },
    textGrid: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(0, 3),
    },
    shieldHeading: {
      color: '#f15236',
      fontSize: 28,
      fontWeight: 800,
      display: 'inline-flex',
      alignItems: 'center',
      alignSelf: 'flex-start',
    },
  })
);

const CloudDesktopPremium3: React.FC = () => {
  const classes = useStyles();

  return (
    <HeroBlock component="div">
      <Grid container className={classes.root}>
        <Grid item xs={12} md={6} className={classes.textGrid}>
          <Typography
            variant="subtitle1"
            component="h3"
            gutterBottom
            className={classes.shieldHeading}>
            <ShieldImg src={sectionImg} alt="" />
            Large Deployments
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} className={classes.textGrid}>
          <Typography variant="subtitle1" component="span" className={classes.text}>
            <Highlight>Velocity Cloud Desktop Premium</Highlight>
            {` for WVD empowers IT Pros to operationalize large Microsoft WVD deployments with powerful and intuitive UI used by engineering and help desk staff to deploy the environment and provide ongoing user management. Capabilities like desktop image management, performance monitoring, and user session control eliminate the need for complex scripting and speed up response to end-users.`}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} className={classes.textGrid}>
          <Typography
            variant="subtitle1"
            component="h3"
            gutterBottom
            className={classes.shieldHeading}>
            <ShieldImg src={sectionImg} alt="" />
            Reduce Costs
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} className={classes.textGrid}>
          <Typography variant="subtitle1" component="span" className={classes.text}>
            {`With `}
            <Highlight>Velocity Cloud Desktop Premium</Highlight>
            {` for WVD helps reinforce existing security policies and compliance.  It addresses data residency concerns being deployed as a secure Azure application inside customer’s own Azure subscription in a geographic location of their choice.`}
          </Typography>
        </Grid>
      </Grid>
    </HeroBlock>
  );
};

export default CloudDesktopPremium3;
