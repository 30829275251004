import React from 'react';
import { makeStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';
import sectionImg from 'assets/pbx-shield.png';

const HeroBlock = styled(Box)(({ theme }) => ({
  padding: theme.spacing(6, 0, 4),
  backgroundColor: '#f8f8f8',
  marginBottom: 24,
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(4, 0),
  },
}));

const ShieldImg = styled('img')({
  marginRight: 24,
  height: 80,
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: theme.breakpoints.values['lg'],
      margin: '0 auto',
      padding: theme.spacing(0, 3),
      color: '#423f3f',
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
    label: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: 'normal',
      color: '#737373',
    },
    title: {
      fontSize: 34,
      fontWeight: 700,
    },
    text: {
      fontSize: 18,
      paddingRight: 32,
      display: 'inline-block',
      marginBottom: 16,
      [theme.breakpoints.down('sm')]: {
        paddingRight: 0,
      },
    },
    shieldHeading: {
      color: '#f15236',
      fontSize: 30,
      fontWeight: 800,
      display: 'inline-flex',
      alignItems: 'center',
      marginBottom: 24,
    },
    imageGrid: {
      paddingLeft: 16,
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        marginTop: 16,
      },
    },
  })
);

const EnterprisePBXBlock3: React.FC = () => {
  const classes = useStyles();

  return (
    <HeroBlock component="div">
      <Grid container className={classes.root}>
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle1" component="p" gutterBottom className={classes.text}>
            {`Velocity’s IP phones offering includes both our proprietary models as well as generic IP phones. This means that should the customer require, they can either take advantage of the free phone included for all users in the subscription or utilize any other IP phone that meets our security standards. Relatively complex deployments such as the traditional PBX also carry a relatively high TCO for the customer.`}
          </Typography>
          <Typography variant="subtitle1" component="p" gutterBottom className={classes.text}>
            <span style={{ color: '#4bb1fe', fontWeight: 700 }}>
              Well, not with Velocity’s Cloud PBX!
            </span>
            - we give PBX deployments for free to every customer. Traditional costly maintenance,
            setup, initial provisioning and other are also included for free with our offerings.
            Subject to carrier restrictions, we can even onboard the customer’s existing phone
            numbers to their Velocity Cloud PBX.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} className={classes.imageGrid}>
          <Typography
            variant="subtitle1"
            component="h3"
            gutterBottom
            className={classes.shieldHeading}>
            <ShieldImg src={sectionImg} alt="" />
            Free PBX deployments
          </Typography>
          <Typography
            variant="subtitle1"
            component="h3"
            gutterBottom
            className={classes.shieldHeading}>
            <ShieldImg src={sectionImg} alt="" />
            Maintenance & setup included
          </Typography>
          <Typography
            variant="subtitle1"
            component="h3"
            gutterBottom
            className={classes.shieldHeading}>
            <ShieldImg src={sectionImg} alt="" />
            Keep your original phone number
          </Typography>
        </Grid>
      </Grid>
    </HeroBlock>
  );
};

export default EnterprisePBXBlock3;
