/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { makeStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';

const HeroBlock = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 0),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: theme.breakpoints.values['lg'],
      margin: '16px auto',
      padding: theme.spacing(1, 2),
      fontSize: 18,
      flexDirection: 'column',
    },
    title: {
      fontWeight: 800,
      fontSize: 44,
      color: '#423f3f',
    },
    textBox: {
      color: '#423f3f',
    },
    textIcon: {
      width: 20,
      marginRight: 12,
    },
    list: {
      padding: '16px 0',
    },
    listItem: {
      paddingTop: 4,
      paddingBottom: 4,
    },
    listItemIcon: {
      minWidth: 32,
    },
    listItemText: {
      margin: 0,
    },
  })
);

const HealthSafetyPolicy: React.FC = () => {
  const classes = useStyles();

  return (
    <HeroBlock component="div">
      <Grid container className={classes.root}>
        <Typography gutterBottom variant="h2" component="h2">
          Health and Safety Policy
        </Typography>
        <p>
          SAFETY MANAGEMENT SYSTEM VELOCITY EU INC Page 1 of 1 SECTION 1 &ndash; GENERAL STATEMENT
          OF HEALTH AND SAFETY POLICY
        </p>
        <p>
          Velocity EU Inc is a professional and safety conscious organisation, which values the
          effective management of health, safety and welfare throughout all stages of a project. The
          health, safety and welfare of Velocity EU Inc&rsquo;s employees is of paramount
          importance. The clear objective is to minimise harm to persons and property by adopting a
          proactive approach to effective risk and safety management. All work will be carried out
          in accordance with best practice, to the relevant statutory provisions with all reasonably
          practicable measures being taken to avoid risk to employees or others that may be
          affected.
        </p>
        <p>
          Velocity EU Inc are committed to the principle that business objectives should never
          compromise health and safety. Management and supervisory staff have the responsibility for
          implementing the policy throughout the company and must ensure that health and safety
          considerations are always given priority in planning and day-to-day supervision of all
          work.
        </p>
        <p>
          Velocity EU Inc will fully comply with the duties placed upon it within the requirements
          of Statutory Legislation, whilst always complying with, as a matter of best practice, the
          requirements and duties set out within Approved Codes of Practice and Guidance as issued
          by the Health and Safety Executive. All employees and sub-contractors are expected to
          co-operate and assist in the implementation of this policy, whilst ensuring that their own
          works, so far as is reasonably practicable, are carried out without risk to themselves,
          others or the environment. This includes co-operating with management on any health,
          safety or environment related matter.
        </p>
        <p>
          Velocity EU Inc will take all practical steps to ensure that potential hazards and risks
          are identified, and that suitable and effective preventative and control measures are
          implemented. The correct safety equipment and personal protective equipment will be
          provided to all employees. Furthermore, the necessary financial resources will be made
          available by the company in order for Velocity EU Inc to comply with its statutory duties,
          the requirements of this policy and the continuous improvement of the health and safety
          management system. All employees will be provided with the necessary instruction and
          training in safe methods of work, and the safe and efficient operation and maintenance of
          tools and equipment.
        </p>
        <p>
          Velocity EU Inc recognises the importance of employer / employee consultation on matters
          of health and safety and the value of individual consultation relevant to specific health
          and safety issues and functions. The company recognises its responsibilities for health
          and safety of other persons who may be affected by its activities. The CEO and Managing
          Director have overall responsibility for all Health, Safety, Welfare and Environmental
          matters. The operation of this policy and the associated procedures will be monitored and
          reviewed on a regular basis to ensure that they remain current and applicable to the
          company's activities.
        </p>
      </Grid>
    </HeroBlock>
  );
};

export default HealthSafetyPolicy;
