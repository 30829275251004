import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  Hidden,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const HeroBlock = styled(Box)(({ theme }) => ({
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  padding: theme.spacing(10, 4, 2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '32px auto',
      maxWidth: theme.breakpoints.values['lg'],
      justifyContent: 'space-between',
    },
    paperBox: { borderTopLeftRadius: '8px', borderTopRightRadius: '8px', overflow: 'hidden' },
    header: { padding: '12px 0 16px', background: '#c4462f', textAlign: 'center' },
    price: {
      padding: '12px 0 16px',
      background: '#fff',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      borderBottom: '1px solid #ddd',
      boxShadow: '0px 1px 2px #ddd',
      zIndex: 1,
      position: 'relative',
    },
    content: {
      padding: '12px 0 16px',
      background: '#fff',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
    },
    accordion: {
      border: 'none',
      boxShadow: 'none',
      '&:before': {
        display: 'none',
      },
      '&.Mui-expanded': {
        margin: 0,
      },
      '& .MuiAccordionSummary-root.Mui-expanded': {
        minHeight: 'auto',
      },
      '& .MuiAccordionSummary-content.Mui-expanded': {
        margin: 0,
      },
    },
    accordionDetails: {
      flexDirection: 'column',
      padding: 0,
    },
  })
);

const ManagedVirtualDesktop: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const [expandS, setExpandS] = useState(false);
  const [expandG, setExpandG] = useState(false);
  const [expandP, setExpandP] = useState(false);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      expandOnResize(matches);
    }

    // Don't update the state on an unmounted component
    return function cleanup() {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matches]);

  const expandOnResize = (matches: boolean) => {
    if (!matches) {
      setExpandS(true);
      setExpandG(true);
      setExpandP(true);
    } else {
      setExpandS(false);
      setExpandG(false);
      setExpandP(false);
    }
  };

  const ListItemRow = ({
    primary,
    secondary,
    disabled,
  }: {
    primary: string;
    secondary?: string;
    disabled?: boolean;
  }) => (
    <ListItem component="div" style={{ paddingTop: 0, paddingBottom: 4 }}>
      <ListItemText
        style={{ paddingLeft: 28, position: 'relative' }}
        primary={
          <>
            {!disabled && (
              <CheckCircleIcon
                fontSize="small"
                style={{ color: '#00d07d', position: 'absolute', top: 2, left: 0 }}
              />
            )}
            <Typography
              variant="body1"
              component="span"
              style={{ fontWeight: 600, color: disabled ? '#a3b3ba' : '#423f3f' }}>
              {primary}
            </Typography>
          </>
        }
        secondary={
          secondary && (
            <Typography variant="body2" component="p" style={{ fontWeight: 600, color: '#6d8795' }}>
              {secondary}
            </Typography>
          )
        }
      />
    </ListItem>
  );

  return (
    <HeroBlock component="div" id="managed-virtual-desktop">
      <Typography
        gutterBottom
        variant="h4"
        component="h4"
        style={{ fontWeight: 600, color: '#423f3f' }}>
        Managed Virtual Desktop
      </Typography>
      <Grid container className={classes.root} spacing={3}>
        <Grid item xs={12} md={4}>
          <Paper elevation={4} className={classes.paperBox}>
            <Box className={classes.header}>
              <Typography variant="h5" component="h5" style={{ fontWeight: 600, color: '#fff' }}>
                Silver
              </Typography>
            </Box>
            <Box className={classes.price}>
              <Typography
                variant="h5"
                component="span"
                style={{ fontWeight: 600, color: '#c4462f' }}>
                GBP 35.00
              </Typography>
              <Typography
                variant="body2"
                component="span"
                style={{ fontWeight: 600, color: '#6d8795' }}>
                Per user per month
              </Typography>
            </Box>
            <Box className={classes.content}>
              <List component="div" style={{ paddingTop: 0, paddingBottom: 0, width: '100%' }}>
                <ListItemRow primary="Windows 10 experience" />
                <ListItemRow primary="Multi-monitor support" secondary="Up to four monitors" />
                <ListItemRow primary="Streaming desktop" secondary="Using RDP v10 with UDP" />
                <ListItemRow primary="Install your own software" />
                <ListItemRow primary="Persistent desktop settings" />
                <Accordion className={classes.accordion} expanded={expandS}>
                  <Hidden mdUp>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      onClick={() => setExpandS(!expandS)}>
                      <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
                        {!expandS ? `Show more...` : `Show less...`}
                      </Typography>
                    </AccordionSummary>
                  </Hidden>
                  <AccordionDetails className={classes.accordionDetails}>
                    <ListItemRow primary="1000mbps internet connection" />
                    <ListItemRow primary="Microsoft RDS subscriber access license" />
                    <ListItemRow primary="DNS-based web content filter" disabled />
                    <ListItemRow primary="Security patch management" disabled />
                    <ListItemRow primary="Webroot Antivirus" disabled />
                    <ListItemRow primary="Two factor authentication" disabled />
                    <ListItemRow primary="Performance monitoring" disabled />
                    <ListItemRow primary="24x7 monitoring and remediation" disabled />
                  </AccordionDetails>
                </Accordion>
              </List>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper elevation={4} className={classes.paperBox}>
            <Box className={classes.header}>
              <Typography variant="h5" component="h5" style={{ fontWeight: 600, color: '#fff' }}>
                Gold
              </Typography>
            </Box>
            <Box className={classes.price}>
              <Typography
                variant="h5"
                component="span"
                style={{ fontWeight: 600, color: '#c4462f' }}>
                GBP 50.00
              </Typography>
              <Typography
                variant="body2"
                component="span"
                style={{ fontWeight: 600, color: '#678190' }}>
                Per user per month
              </Typography>
            </Box>
            <Box className={classes.content}>
              <List component="div" style={{ paddingTop: 0, paddingBottom: 0, width: '100%' }}>
                <ListItemRow primary="Windows 10 experience" />
                <ListItemRow primary="Multi-monitor support" secondary="Up to four monitors" />
                <ListItemRow primary="Streaming desktop" secondary="Using RDP v10 with UDP" />
                <ListItemRow primary="Install your own software" />
                <ListItemRow primary="Persistent desktop settings" />
                <Accordion className={classes.accordion} expanded={expandG}>
                  <Hidden mdUp>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      onClick={() => setExpandG(!expandG)}>
                      <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
                        {!expandG ? `Show more...` : `Show less...`}
                      </Typography>
                    </AccordionSummary>
                  </Hidden>
                  <AccordionDetails className={classes.accordionDetails}>
                    <ListItemRow primary="1000mbps internet connection" />
                    <ListItemRow primary="Microsoft RDS subscriber access license" />
                    <ListItemRow primary="DNS-based web content filter" />
                    <ListItemRow primary="Security patch management" />
                    <ListItemRow primary="Webroot Antivirus" />
                    <ListItemRow primary="Two factor authentication" />
                    <ListItemRow primary="Performance monitoring" disabled />
                    <ListItemRow primary="24x7 monitoring and remediation" disabled />
                  </AccordionDetails>
                </Accordion>
              </List>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper elevation={4} className={classes.paperBox}>
            <Box className={classes.header}>
              <Typography variant="h5" component="h5" style={{ fontWeight: 600, color: '#fff' }}>
                Platinum
              </Typography>
            </Box>
            <Box className={classes.price}>
              <Typography
                variant="h5"
                component="span"
                style={{ fontWeight: 600, color: '#c4462f' }}>
                GBP 65.00
              </Typography>
              <Typography
                variant="body2"
                component="span"
                style={{ fontWeight: 600, color: '#678190' }}>
                Per user per month
              </Typography>
            </Box>
            <Box className={classes.content}>
              <List component="div" style={{ paddingTop: 0, paddingBottom: 0, width: '100%' }}>
                <ListItemRow primary="Windows 10 experience" />
                <ListItemRow primary="Multi-monitor support" secondary="Up to four monitors" />
                <ListItemRow primary="Streaming desktop" secondary="Using RDP v10 with UDP" />
                <ListItemRow primary="Install your own software" />
                <ListItemRow primary="Persistent desktop settings" />
                <Accordion className={classes.accordion} expanded={expandP}>
                  <Hidden mdUp>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      onClick={() => setExpandP(!expandP)}>
                      <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
                        {!expandP ? `Show more...` : `Show less...`}
                      </Typography>
                    </AccordionSummary>
                  </Hidden>
                  <AccordionDetails className={classes.accordionDetails}>
                    <ListItemRow primary="1000mbps internet connection" />
                    <ListItemRow primary="Microsoft RDS subscriber access license" />
                    <ListItemRow primary="DNS-based web content filter" />
                    <ListItemRow primary="Security patch management" />
                    <ListItemRow primary="Webroot Antivirus" />
                    <ListItemRow primary="Two factor authentication" />
                    <ListItemRow primary="Performance monitoring" />
                    <ListItemRow primary="24x7 monitoring and remediation" />
                  </AccordionDetails>
                </Accordion>
              </List>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </HeroBlock>
  );
};

export default ManagedVirtualDesktop;
