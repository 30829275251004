import React from 'react';
import { makeStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Grid,
  Typography,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
} from '@material-ui/core';
import sectionImg1 from 'assets/journey.png';
import sectionImg2 from 'assets/global.png';
import sectionImg3 from 'assets/support.png';
import sectionImg4 from 'assets/business.png';
import sectionImg5 from 'assets/anywhere.png';
import sectionImg6 from 'assets/agile.png';

const HeroBlock = styled(Box)(({ theme }) => ({
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  display: 'flex',
  flexDirection: 'column',
  background: '#fafafa',
  color: '#423f3f',
  padding: theme.spacing(3, 0),
  margin: theme.spacing(3, 0),
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '32px auto 0',
      maxWidth: theme.breakpoints.values['lg'],
      justifyContent: 'space-between',
    },
    title: {
      fontSize: 34,
      fontWeight: 700,
      lineHeight: '36px',
      maxWidth: 1280,
      width: '100%',
      margin: theme.spacing(0, 'auto'),
      padding: theme.spacing(0, 3),
    },
    heading: {
      fontWeight: 600,
      fontSize: 28,
      color: '#f15236',
    },
    subtitle: {
      fontSize: 18,
    },
    cardLink: {
      padding: 0,
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      backgroundColor: '#fafafa',
      borderRadius: 0,
      transition: 'background .1s linear',
      '&:hover': {
        backgroundColor: '#eee',
      },
    },
    cardContent: {
      padding: theme.spacing(4, 2, 3),
      textAlign: 'center',
      justifyContent: 'center',
    },
    media: {
      height: 160,
      backgroundSize: 'auto',
      backgroundPosition: 'bottom',
    },
    cardActionArea: {
      '&:hover .MuiCardActionArea-focusHighlight': {
        opacity: 0,
      },
    },
  })
);

const CardBlock: any = ({
  image,
  title,
  body,
  href,
}: {
  image: string;
  title: string;
  href?: string;
  body?: React.ReactNode;
}) => {
  const classes = useStyles();

  return (
    <Card elevation={0} className={classes.card}>
      <Button variant="text" href={href} className={classes.cardLink}>
        <CardActionArea className={classes.cardActionArea}>
          <CardMedia className={classes.media} image={image} title={title} />
          <CardContent className={classes.cardContent}>
            <Typography gutterBottom variant="h5" component="h2" className={classes.heading}>
              {title}
            </Typography>
            <br />
            <Typography variant="subtitle1" component="span" className={classes.subtitle}>
              {body}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Button>
    </Card>
  );
};

const WhyChooseUs: React.FC = () => {
  const classes = useStyles();

  return (
    <HeroBlock component="div">
      <Typography gutterBottom variant="h4" component="h2" className={classes.title}>
        Why Choose Us
      </Typography>
      <Grid container className={classes.root}>
        <Grid item xs={12} sm={4} md={4}>
          <CardBlock
            image={sectionImg1}
            title="A 20 Year Journey"
            body="From Terminal Server to Windows Virtual Desktop (WVD)."
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <CardBlock
            image={sectionImg2}
            title="Global Coverage"
            body="Velocity Cloud Workspace is available in over 60 geographical regions."
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <CardBlock
            image={sectionImg3}
            title="Online Support"
            href="https://support.velocity-eu.com/portal/en/home"
            body="Velocity operates a comprehensive and fully integrated monitoring and support system."
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <CardBlock
            image={sectionImg4}
            title="Business Advantages"
            body="Futureproof, Low Total Cost of ownership, Disaster Recovery, Information, and data Security."
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <CardBlock
            image={sectionImg5}
            title="Work from Anywhere"
            body="Work from any device, any operating system in any geographical region."
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <CardBlock
            image={sectionImg6}
            title="Agile Architecture"
            body="Eliminates complex VPN Infrastructure, Instant provisioning and deployment, Integrated Voice and data, centralised management."
          />
        </Grid>
      </Grid>
    </HeroBlock>
  );
};

export default WhyChooseUs;
