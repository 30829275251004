import React from 'react';
import { makeStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import { Box, Button, Grid, Paper, Typography } from '@material-ui/core';

const HeroBlock = styled(Box)(({ theme }) => ({
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  padding: theme.spacing(10, 4, 6),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '16px auto 32px',
      maxWidth: theme.breakpoints.values['xl'],
      justifyContent: 'space-between',
    },
    paperBox: {
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
      overflow: 'hidden',
      height: '100%',
    },
    header: { padding: '12px 8px 16px', background: '#c4462f', textAlign: 'center' },
    price: {
      padding: '12px 16px 16px',
      background: '#fff',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      zIndex: 1,
      position: 'relative',
      minHeight: 100,
      boxSizing: 'border-box',
    },
    content: {
      padding: '12px 0 16px',
      background: '#fff',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
    },
  })
);

const AdditionalServices: React.FC = () => {
  const classes = useStyles();

  return (
    <HeroBlock component="div" id="additional-services-and-add-ons">
      <Typography
        gutterBottom
        variant="h4"
        component="h4"
        style={{ fontWeight: 600, color: '#423f3f' }}>
        Additional Services and Add-ons
      </Typography>
      <Grid container className={classes.root} spacing={3}>
        <Grid item xs={12} sm={6} lg={3}>
          <Paper elevation={4} className={classes.paperBox}>
            <Box className={classes.header}>
              <Typography variant="h5" component="h5" style={{ fontWeight: 600, color: '#fff' }}>
                Onboarding & migration
              </Typography>
            </Box>
            <Box className={classes.price}>
              <Button
                style={{
                  border: '1px solid #c4462f',
                  color: '#c4462f',
                  marginTop: 4,
                  fontWeight: 600,
                  padding: '6px 16px',
                  marginRight: '8px',
                  flexShrink: 0,
                }}>
                Contact us
              </Button>
              <Typography
                variant="body2"
                component="span"
                style={{ fontWeight: 600, color: '#6d8795' }}>
                One-time project to get your new system up and running
              </Typography>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Paper elevation={4} className={classes.paperBox}>
            <Box className={classes.header}>
              <Typography variant="h5" component="h5" style={{ fontWeight: 600, color: '#fff' }}>
                Enterprise
              </Typography>
            </Box>
            <Box className={classes.price} style={{ flexDirection: 'row', paddingBottom: 12 }}>
              <Button
                style={{
                  border: '1px solid #c4462f',
                  color: '#c4462f',
                  marginTop: 4,
                  fontWeight: 600,
                  padding: '6px 16px',
                  marginRight: '8px',
                  flexShrink: 0,
                }}>
                Contact us
              </Button>
              <Typography
                variant="body2"
                component="span"
                style={{ fontWeight: 600, color: '#6d8795' }}>
                IT strategy
              </Typography>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Paper elevation={4} className={classes.paperBox}>
            <Box className={classes.header}>
              <Typography variant="h5" component="h5" style={{ fontWeight: 600, color: '#fff' }}>
                Microsoft SQL Server
              </Typography>
            </Box>
            <Box className={classes.price}>
              <Typography
                variant="h5"
                component="span"
                style={{
                  fontWeight: 600,
                  color: '#c4462f',
                  whiteSpace: 'nowrap',
                  marginRight: '8px',
                }}>
                GBP 150.00
              </Typography>
              <Typography
                variant="body2"
                component="span"
                style={{ fontWeight: 600, color: '#678190' }}>
                Per 2 CPU cores per month (min: 4 cores)
              </Typography>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Paper elevation={4} className={classes.paperBox}>
            <Box className={classes.header}>
              <Typography variant="h5" component="h5" style={{ fontWeight: 600, color: '#fff' }}>
                DLP and Activity Monitoring
              </Typography>
            </Box>
            <Box className={classes.price}>
              <Typography
                variant="h5"
                component="span"
                style={{
                  fontWeight: 600,
                  color: '#c4462f',
                  whiteSpace: 'nowrap',
                  marginRight: '8px',
                }}>
                GBP 10.00
              </Typography>
              <Typography
                variant="body2"
                component="span"
                style={{ fontWeight: 600, color: '#678190' }}>
                Per user per month
              </Typography>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </HeroBlock>
  );
};

export default AdditionalServices;
