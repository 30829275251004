import React from 'react';
import { makeStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';
import sectionImg from 'assets/cloud-desktop/cloud-desktop-block1.png';

const HeroBlock = styled(Box)({
  background: '#fff',
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: theme.breakpoints.values['lg'],
      margin: '0 auto',
      backgroundImage: `url(${sectionImg})`,
      backgroundSize: '58%',
      backgroundPosition: 'right',
      backgroundRepeat: 'no-repeat',
      backgroundColor: '#fff',
      color: '#423f3f',
      padding: theme.spacing(8, 3),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(4, 3),
        backgroundImage: 'none',
      },
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
    label: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: 'normal',
      color: '#737373',
    },
    title: {
      fontSize: 34,
      fontWeight: 700,
    },
    text: {
      fontSize: 18,
    },
  })
);

const CloudDesktop1: React.FC = () => {
  const classes = useStyles();

  return (
    <HeroBlock component="div" id="VelocitySolutions">
      <Grid container className={classes.root}>
        <Grid item xs={12} md={6}>
          <Typography variant="h5" component="span" className={classes.label} gutterBottom>
            Velocity
          </Typography>
          <br />

          <Typography variant="h4" component="h2" className={classes.title} gutterBottom>
            Cloud Desktop
          </Typography>
          <br />

          <Typography variant="subtitle1" component="span" gutterBottom className={classes.text}>
            <strong>{`What is Velocity Cloud Desktop for Microsoft WVD?`}</strong>
          </Typography>
          <br />
          <Typography variant="body1" component="span" gutterBottom className={classes.text}>
            {`Automatically provision a complete WVD environment in under an hour, connect to an existing deployment in minutes, manage all your tenants in a single pane of glass admin portal, and optimize Microsoft WVD environment with powerful autoscaling.`}
          </Typography>
          <br />
          <br />
          <Typography variant="subtitle1" component="span" gutterBottom className={classes.text}>
            <strong>{`Significant Savings Achievable`}</strong>
          </Typography>
          <br />
          <Typography variant="body1" component="span" gutterBottom className={classes.text}>
            {`Velocity Cloud Desktop can be connected to any existing Microsoft WVD deployment and delivers savings of up to 75% on Azure compute and storage costs. Velocity’s experts will guide you on your journey to building a successful cloud VDI in Microsoft Azure.`}
          </Typography>
        </Grid>
      </Grid>
    </HeroBlock>
  );
};

export default CloudDesktop1;
