import React from 'react';
import { makeStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';

const HeroBlock = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 0, 8),
}));

const Highlight = styled('a')({
  color: '#f15236',
  fontWeight: 600,
  wordBreak: 'break-word',
  textDecoration: 'none',
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: theme.breakpoints.values['lg'],
      margin: '0 auto',
      padding: theme.spacing(0, 3),
      color: '#423f3f',
    },
    gridLine: {
      borderBottom: '3px solid #4bb1fe',
      paddingBottom: theme.spacing(1),
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
    label: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: 'normal',
      color: '#737373',
    },
    title: {
      fontSize: 28,
      fontWeight: 700,
      marginBottom: 16,
    },
    text: {
      width: '100%',
      fontSize: 18,
      marginBottom: 16,
      display: 'inline-block',
    },
    list: {
      padding: '0 0 16px',
    },
    listItem: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    listItemIcon: {
      minWidth: 32,
    },
    listItemText: {
      margin: 0,
    },
  })
);

const ResourcesBlock8: React.FC = () => {
  const classes = useStyles();

  return (
    <HeroBlock component="div">
      <Grid container className={classes.root}>
        <Grid item xs={12} className={classes.gridLine}>
          <Typography variant="h4" component="h2" className={classes.title}>
            Connect with thin clients
          </Typography>

          <Typography variant="subtitle1" component="p" className={classes.text}>
            {`IGEL OS is the ideal edge OS for endpoint devices accessing Windows Virtual Desktop services.  A lightweight Linux operating system designed specifically for secure, high-performance access to cloud workspaces, IGEL OS is the first Linux-based OS validated and certified with Microsoft for WVD device access.`}
          </Typography>

          <Typography variant="subtitle1" component="p" className={classes.text}>
            Order IGEL Terminal from{` `}
            <Highlight href="https://store.velocity-eu.com">
              https://store.velocity-eu.com
            </Highlight>
          </Typography>
        </Grid>
      </Grid>
    </HeroBlock>
  );
};

export default ResourcesBlock8;
