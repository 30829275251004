import React from 'react';
import { makeStyles, createStyles, Theme, styled } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import sectionImg from 'assets/velocity-use-case-img2.png';
import { Check } from '@material-ui/icons';

const HeroBlock = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8, 0, 0),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(4, 0),
  },
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: theme.breakpoints.values['lg'],
      margin: '0 auto',
      padding: theme.spacing(0, 3),
      color: '#423f3f',
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
    label: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: 'normal',
      color: '#737373',
    },
    title: {
      fontSize: 34,
      fontWeight: 700,
    },
    text: {
      fontSize: 18,
      paddingRight: 16,
      display: 'inline-block',
    },
    imageGrid: {
      backgroundImage: `url(${sectionImg})`,
      backgroundSize: 'contain',
      backgroundPosition: 'left top',
      backgroundRepeat: 'no-repeat',
      [theme.breakpoints.down('sm')]: {
        backgroundImage: 'none',
      },
    },
    list: {
      padding: '16px 0',
    },
    listItem: {
      paddingTop: 4,
      paddingBottom: 4,
    },
    listItemIcon: {
      minWidth: 32,
    },
    listItemText: {
      margin: 0,
      fontWeight: 600,
    },
  })
);

const listItems = [
  'Speed of deployment.',
  'Geographic operability.',
  'Equipment compatibility.',
  'Security.',
  'Ease of use.',
  'Costs both in Capital and Revenue.',
  'IT Management & Support.',
  'Scalability.',
];

const UseCaseFinancialBlock2: React.FC = () => {
  const classes = useStyles();

  return (
    <HeroBlock component="div">
      <Grid container className={classes.root}>
        <Grid item xs={12} md={6} className={classes.imageGrid}></Grid>
        <Grid item xs={12} md={6} style={{ paddingLeft: 24 }}>
          <Typography variant="subtitle1" component="span" gutterBottom className={classes.text}>
            {`Having explored many potential solutions internally with our ITC department and having relationships with multiple global vendors, we requested tenders for possible solutions with a guideline remit of:`}
            <br />
            <List className={classes.list}>
              {listItems.map((item, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <ListItem className={classes.listItem} key={i}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <Check htmlColor="#f05236" />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.listItemText}
                    primary={<span style={{ fontWeight: 700, fontSize: 20 }}>{item}</span>}
                  />
                </ListItem>
              ))}
            </List>
          </Typography>
        </Grid>
      </Grid>
    </HeroBlock>
  );
};

export default UseCaseFinancialBlock2;
